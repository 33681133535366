import { Avatar, Divider, Pagination, Stack, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Form, Modal, Button, Image, Row, Col, Alert, Accordion, Offcanvas, InputGroup, Spinner } from 'react-bootstrap'
import { Media, Table } from 'reactstrap'
import { RiImageAddFill } from "react-icons/ri";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../../context/ContextProvider';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { IoSearchSharp } from "react-icons/io5";
import { FaFilter } from "react-icons/fa";
import axiosClient, { link } from '../../../../axios-client';
import axios from 'axios';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

const styles = `
.custom-modal-size {
  max-width: 600px; 
}
`
function Advertisers() {
    const { user } = useStateContext()
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showCanvas, setShowCanvas] = useState(false);
    const [loading, setLoading] = useState()

    const navigate = useNavigate()
    const handleShow = () => {
        setShow(true)
    };
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);

    const handleCloseCanvas = () => setShowCanvas(false);
    const toggleShowCanvas = () => setShowCanvas(true);


    const { t } = useTranslation()
    const [paginationData, setPaginationData] = useState()
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [id, setId] = useState(null);
    const [idAgency, setIdAgency] = useState(null);
    const [data, setData] = useState([])


    ////////////////////////////////the size of the table//////////////////////////////
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        setLoading(true)
        axiosClient.get(`/get_advertisers/?page=${page + 1}&page_size=${pageSize}&user=${user.id}&etat=${selectedEtat}&search_term=${searchTerm}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                setTotalPages(Math.ceil(response.data.count / pageSize));
                setData(filteredData);
                setLoading(false)
            })
            .catch((error) => {
                console.error(error);
                setLoading(false)
            });

    }, [user, page, pageSize, selectedEtat, searchTerm])

    function update() {
        setLoading(true)
        axiosClient.get(`/get_advertisers/?page=${page + 1}&page_size=${pageSize}&user=${user.id}&etat=${selectedEtat}&search_term=${searchTerm}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                setTotalPages(Math.ceil(response.data.count / pageSize));
                setData(filteredData);
                setLoading(false)
            })
            .catch((error) => {
                console.error(error);
                setLoading(false)
            });
    }

    const handleEtatClick = (id, etat) => {
        const formData = new FormData();
        formData.append("etat", !etat);

        axiosClient.put(`/update_isActive_Advertiser/${id}/`, formData)
            .then((response) => {
                setData(rows => rows.map(row => {
                    if (row.idAgency === id) {
                        return {
                            ...row,
                            etat: !etat,
                        };
                    }
                    return row;
                }));
            })
            .catch((error) => console.error(error));
        if (!etat) {

            toast.success(t("le compte est activé"), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
        else {

            toast.error(t("le compte est désactivé"), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const handleDelete = async (id, idAgency) => {
        try {
            // First, delete the user from Kevel
            const kevelResponse = await axiosClient.put(`delete_advertiser/${id}/`);

            // If the deletion from Kevel is successful, delete the user from your backend
            await axiosClient.delete(`/agences/${idAgency}/`);

            // Show success notification
            toast.success(t("L'utilisateur a été supprimé avec succès"), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });

            // Optionally refresh the data after deletion
            update();

        } catch (error) {
            console.error("Error deleting user:", error);
            toast.error(t("Une erreur s'est produite lors de la suppression de l'utilisateur"), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        } finally {
            // Close the delete confirmation modal, regardless of success or error
            handleCloseDelete();
        }
    };


    return (
        <>
            {!loading ?
                <div>
                    <ToastContainer />
                    <style>{styles}</style>
                    <div className="row d-flex justify-content-between align-items-center my-3 mb-4">
                        <h3 className=' col-12 col-sm-6'>{t("Liste d'agences")}</h3>
                        <div className="col-12 col-sm-6 d-flex justify-content-sm-end justify-content-end mt-2 mt-sm-0">

                            <Button variant="primary" size='md' style={{ fontSize: "16px" }} onClick={toggleShowCanvas} className="me-2">
                                <FaFilter />
                            </Button>
                            {/* <div></div> */}
                            <Button style={{ fontSize: "16px" }} onClick={() => navigate("/add-advertiser")} size='md' variant="secondary  ">{t("Ajouter une agence")}</Button>

                        </div>
                    </div>
                    <Table style={{ borderColor: "lightgray" }} responsive>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>{t("Activité")}</th>
                                <th>{t("Nom complet")}</th>
                                <th>Id</th>
                                <th>email</th>
                                <th>{t("Téléphone")}</th>
                                <th>{t("Date de création")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody >
                            {(paginationData?.length !== 0) &&
                                data.map((user) => (
                                    <tr key={user.id} className="align-middle">
                                        <th scope="row">
                                            <Avatar style={{ objectFit: "cover", borderRadius: "10%" }} variant='square' width={45} height={45} src={user.image} />
                                        </th>
                                        <th  >
                                            <Form>
                                                <Form.Check
                                                    checked={user.etat}
                                                    type="switch"
                                                    id="custom-switch"
                                                    label=""
                                                    onChange={() => handleEtatClick(user.idAgency,user.etat)}

                                                />

                                            </Form>
                                        </th>

                                        <td>
                                            <Link to={`/advertiser-campaigns/${user.Id}/${user.idAgency}`} className=" waves-effect" onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                                <span>{user.Title}</span>
                                            </Link>

                                        </td>
                                        <td>{user.Id}</td>
                                        <td>{user.email}</td>
                                        <td>{user.tel}</td>
                                        <td>{new Date(user.date_creation).toLocaleDateString(t("fr-FR"))}</td>
                                        <td>
                                            <div className='d-flex justify-content-between gap-2'>
                                                <span onClick={() => navigate(`/advertiser-clients`, {
                                                    state: {
                                                        idAdvertiser: user.idAgency,
                                                        name: user.Title,
                                                    }
                                                })} class='mdi mdi-account-multiple' style={{ fontSize: 23, cursor: "pointer" }}></span>
                                                <span onClick={() => navigate(`/stats-advertiser`, {
                                                    state: {
                                                        idAdvertiser: user.idAgency,
                                                        name: user.Title,
                                                    }
                                                })} class='mdi mdi-chart-line' style={{ fontSize: 23, cursor: "pointer" }}></span>

                                                <span onClick={() => navigate(`/edit-advertiser`, {
                                                    state: {
                                                        idAdvertiser: user.idAgency
                                                    }
                                                })} class='mdi mdi-pencil' style={{ fontSize: 23, cursor: "pointer" }}></span>

                                                <span onClick={() => {

                                                    handleShowDelete()
                                                    setId(user.Id)
                                                    setIdAgency(user.idAgency)
                                                }}
                                                    class='mdi mdi-close' style={{ fontSize: 23, cursor: "pointer" }}></span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}


                            {(((searchTerm || selectedEtat !== null) && data?.length === 0)) && (
                                <tr>
                                    <td colSpan={9}>{t("Aucune ligne correspondante n'a été trouvée.")}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {paginationData?.length !== 0 &&
                        <Row>
                            <Col lg={12} className="d-flex justify-content-end">

                                <TablePagination
                                    component="div"
                                    count={totalPages * pageSize} // Total number of items
                                    page={page}
                                    onPageChange={handlePageChange}
                                    rowsPerPage={pageSize}
                                    onRowsPerPageChange={handlePageSizeChange}

                                    rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                                    showFirstButton={true}
                                    showLastButton={true}
                                    sx={{
                                        ".MuiTablePagination-displayedRows": {
                                            margin: 0
                                        },
                                        ".MuiTablePagination-selectLabel": {
                                            display: 'none',
                                            margin: 0
                                        },
                                        ".MuiSelect-select": {
                                            backgroundColor: "#ededed"
                                        }
                                    }}
                                />
                            </Col>
                        </Row>

                    }



                    <Modal show={showDelete} onHide={handleCloseDelete}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Supprimer")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseDelete}>
                                {t("Annuler")}
                            </Button>
                            <Button variant='danger' onClick={() => handleDelete(id, idAgency)}>
                                {t("Supprimer")}
                            </Button>
                        </Modal.Footer>
                    </Modal>


                </div>
                :
                <>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 10
                    }}>
                        <span>{t("Chargement")}</span>
                        <Spinner animation="grow" size='sm' />
                    </div>
                </>}
            <Offcanvas style={{ width: "250px", backgroundColor: '#232939' }} scroll={true} backdrop={false} show={showCanvas} onHide={handleCloseCanvas}>
                <Offcanvas.Header style={{ color: 'white' }} closeButton>
                    <Offcanvas.Title style={{ fontSize: "20px" }} >{t("Filtres")}</Offcanvas.Title>
                    <style>
                        {`
      .btn-close {
        filter: invert(1);
      }
    `}
                    </style>
                </Offcanvas.Header>
                <Offcanvas.Body className='py-3'>

                    <div className='d-flex' style={{ flexDirection: 'column', gap: 15 }}>
                        <InputGroup >
                            <InputGroup.Text style={{ background: "white", width: 'fit-content', borderRight: "none" }} >
                                <IoSearchSharp style={{ padding: 0, margin: 0 }} />
                            </InputGroup.Text>

                            <Form.Control
                                value={searchTerm}
                                onChange={(e) => {
                                    setPage(0)
                                    setSearchTerm(e.target.value)
                                }}
                                style={{ paddingLeft: 0, marginLeft: 0, background: "white", borderLeft: "none" }}
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                placeholder={t('Recherche')}
                            />
                        </InputGroup>
                        <span style={{ color: 'white' }}>{t("Activité")} :</span>
                        <Form.Select value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')} aria-label="Default select example">
                            <option value='' >{t("Sélectionnez l'activité")}</option>
                            <option value="true">{t("Activé")}</option>
                            <option value="false">{t("Désactivé")}</option>
                        </Form.Select>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>
        </>

    )
}

export default Advertisers