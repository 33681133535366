import axios from "axios";
import CryptoJS from "crypto-js";

// export const link = process.env.REACT_APP_API_LINK;
// export const link = "http://127.0.0.1:8000";
export const link = 'https://api-ads.digi-screen.com'


const axiosClient = axios.create({
  // baseURL: process.env.REACT_APP_API_LINK,
  // baseURL: "http://127.0.0.1:8000",
  // baseURL: "http://192.168.100.183:8000/",
  baseURL: "https://api-ads.digi-screen.com",


});


export const idTemplate = 9298;

export const idSiteDAB = 1295839;

export const networkId = 11596

export const pagination_size = 10


// Utility function to decrypt the token
// const decryptToken = (encryptedToken) => {
//   try {
//     const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
//     return bytes.toString(CryptoJS.enc.Utf8);
//   } catch (error) {
//     console.error("Error decrypting token:", error);
//     return null;
//   }
// };
const secretKeyEnv = process.env.REACT_APP_SECRET_KEY;

const getEncryptionKey = () => {
  const deviceInfo = navigator.userAgent; // Get device-specific info (like browser)
  const secretKey = secretKeyEnv;

  return secretKey + deviceInfo;
};

const decryptToken = (encryptedToken) => {
  try {
    const key = getEncryptionKey();
    const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Error decrypting token:", error);
    return null;
  }
};
export const percentageDifferenceRevenueBudget = 80

axiosClient.interceptors.request.use((config) => {
  const encryptedToken = localStorage.getItem("U_T");
  const token = encryptedToken ? decryptToken(encryptedToken) : null;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


export default axiosClient;