import React, { useEffect, useState } from 'react';
import { Client } from '@adzerk/decision-sdk';
import { Button, CloseButton, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../axios-client';

const AdComponent = () => {
    const [adContent, setAdContent] = useState(null);
    const [adContentVideo, setAdContentVideo] = useState(null);
    const [isVisible, setIsVisible] = useState(true);
    const [types, setTypes] = useState([]);
    const [zones, setZones] = useState([]);
    const [ad, setAd] = useState();
    const [adType, setAdType] = useState(null);  // Default ad type
    const [keyword, setKeyword] = useState(""); 
    const [campaign, setCampaign] = useState(""); 
    const [zoneId, setZoneId] = useState(""); // Add state for zoneId
    const [loading, setLoading] = useState(false); 
    const [adId, setAdId] = useState(); 

    const { t } = useTranslation()

    const getDataAdTypes = async () => {
        try {
            const res = await axiosClient.get(`get-adTypes/`);
            // console.log('DATATATATA :', res.data.items);
            setTypes(res.data.items)
            return res.data.items.map(item => item.Id)
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    }
    const getZones = async () => {
        try {
            const res = await axiosClient.get(`get-zones/`);
            // console.log('DATATATATA :', res.data.items);
            setZones(res.data.filter(e=>e.SiteId===1285093))
            return res.data.filter(e=>e.SiteId===1285093)
        } catch (error) {
            console.log('ERROR :', error);
            throw error; 
        }
    }
    const getUserIp = async () => {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
    };
    
    useEffect(() => {
        const fetchAdDecision = async () => {
            setLoading(true)
            try {
                const userIp = await getUserIp();
                const data = await getDataAdTypes();
                const zones = await getZones()
                const client = new Client({ networkId: 11596, siteId: 1285093 });
                
                const request = {
                    placements: [{ 
                        adTypes: adType ? adType : data ,
                        zoneIds: zoneId ? [parseInt(zoneId)] : zones.map(val=>val.Id),
                        campaignId:campaign,
                    }],
                    ...(keyword && { keywords: [keyword] }), 
                    ip:userIp
    
                };

                const response = await client.decisions.get(request);
                console.log("response..........: ", response);
                setAdId(response.decisions.div0[0].adId)
                setAd(response.decisions.div0[0]);
                if (response.decisions && response.decisions[Object.keys(response.decisions)[0]]) {
                    const ad = response.decisions.div0[0].contents[0];
                    const impression = response.decisions.div0[0].impressionUrl;
                    setAdContent(ad);
                    setAdContentVideo(ad.data);
                    console.log("videoo", ad);

                    console.log("impression in useEffect", impression);

                    // Fire the impression pixel when the ad is viewed
                    if (impression) {
                        client.pixels.fire({ url: impression });
                    }
                }
                setLoading(false)
            } catch (error) {
                console.error('Error fetching ad decision:', error);
                setLoading(false)
            }
        };

        fetchAdDecision();
    }, [adType, keyword,zoneId,campaign]);  

    async function update() {
        
        setIsVisible(true);;
        setLoading(true)
        try {
            const userIp = await getUserIp();
            const data = await getDataAdTypes();
            const zones = await getZones()
            const client = new Client({ networkId: 11596, siteId: 1285093 });
            console.log("adsss adType data default",data);
            console.log("adsss adType",adType);
            console.log("adsss zones default ",zones.map(val=>val.Id));
            console.log("adsss zoneId ",zoneId);
            console.log("adsss campaign",campaign);
            console.log("adsss userIp",userIp);
            console.log("adsss keyword",keyword);
            
            const request = {
                placements: [{ 
                    adTypes: adType ? adType : data ,
                    zoneIds: zoneId ? [parseInt(zoneId)] : zones.map(val=>val.Id),
                    campaignId:campaign,
                }],
                ...(keyword && { keywords: [keyword] }),
                ip:userIp

            };

            const response = await client.decisions.get(request);
            console.log("response..........: ", response);
            setAdId(response.decisions.div0[0].adId)
            setAd(response.decisions.div0[0]);
            if (response.decisions && response.decisions[Object.keys(response.decisions)[0]]) {
                const ad = response.decisions.div0[0].contents[0];
                const impression = response.decisions.div0[0].impressionUrl;
                setAdContent(ad);
                setAdContentVideo(ad.data);
                console.log("videoo", ad);

                console.log("impression in useEffect", impression);

                // Fire the impression pixel when the ad is viewed
                if (impression) {
                    client.pixels.fire({ url: impression });
                }
            }
            setLoading(false)
        } catch (error) {
            console.error('Error fetching ad decision:', error);
            setLoading(false)
        }
    }
    const handleAdClick = () => {
        if (ad && ad.clickUrl) {
            console.log('ad.clickUrl', ad.clickUrl);

            const client = new Client({ networkId: 11596, siteId: 1285093 });
            client.pixels.fire({ url: ad?.clickUrl }).then(() => {
                window.open(ad.clickUrl, '_blank');
            });
        }
    };

    const handleManualImpression = () => {
        if (ad && ad.impressionUrl) {
            const client = new Client({ networkId: 11596, siteId: 1285093 });
            client.pixels.fire({ url: ad?.impressionUrl }).then((r) => {
                console.log(`Impression fired, status: ${r.status}`);
            });
        }
    };

    const handleCloseAd = () => {
        setIsVisible(false);
    };

    return (
        <div style={{ position: 'relative', padding: '10px', width: 'fit-content', margin: '10px auto', textAlign: 'center' }}>
            <div className=' mb-3 d-flex gap-2 w-100 ' style={{ alignItems: "end", justifyContent: "center" }}>
            <Form.Group>
                    <Form.Label>{t("Campagne")}</Form.Label>
                    <Form.Control
                        type="text"
                        value={campaign}
                        onChange={(e) => setCampaign(parseInt(e.target.value))}
                        placeholder={t("Entrez l'id de campagne")}
                    />
                </Form.Group>
                <Form.Group >
                    <Form.Label>{t("Type d'annonce")} {t("(facultatif)")} </Form.Label>
                   
                    <Form.Select
                        value={adType}
                        onChange={(e) => setAdType(e.target.value)}
                    >
                        <option value="">{t("Choisissez le type d'annonce")}</option>
                        {types.map((val, key) => {
                            return (
                                <option key={key} value={val.Id} title={val.Name}>{val.Name}</option>
                            )
                        })}
                    </Form.Select>
                </Form.Group>


                <Form.Group>
                    <Form.Label>{t("Mot-clé")}</Form.Label>
                    <Form.Control
                        type="text"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder={t("Entrez un mot-clé")}
                    />
                </Form.Group>
                <Form.Group>
                <Form.Label>{t("Support")} {t("(facultatif)")}</Form.Label>
                {/* <Form.Select
                    value={zoneId}
                    onChange={(e) => setZoneId(e.target.value)}
                >
                    <option value="">{t("Choisissez une zone")}</option>
                    <option value="322038">DAB+</option>
                    <option value="322039">Radio Karaoke</option>
                </Form.Select> */}
                <Form.Select
                        value={zoneId}
                        onChange={(e) => setZoneId(e.target.value)}
                    >
                        <option value="">{t("Choisissez un support")}</option>
                        {zones.map((val, key) => {
                            return (
                                <option key={key} value={val.Id} title={val.Name}>{val.Name}</option>
                            )
                        })}
                    </Form.Select>
            </Form.Group>
                <Button onClick={() => update()}>
                    {t("Chercher")}
                </Button>

            </div>
            <div>
                Ad id : {adId}
            </div>



            {isVisible && adContent && (
                !loading ?
                <div style={{ position: 'relative', border: '1px solid #ccc', padding: '10px', textAlign: 'center' }}>
                    {/* <button onClick={handleCloseAd} style={{ position: 'absolute', top: '0px', right: '0px', background: 'transparent', border: 'none', cursor: 'pointer'  }}>
                        ✖
                    </button> */}
                    <div style={{ display: 'flex', alignItems: "end", justifyContent: "end" }}>
                        <CloseButton onClick={handleCloseAd} />

                    </div>
                    {/* <button onClick={update} style={{ position: 'absolute', top: '0px', right: '0px', background: 'transparent', border: 'none', cursor: 'pointer' }}>
                        update
                    </button> */}
                    {/* Ad content */}
                    <div style={{ padding: 5 }} dangerouslySetInnerHTML={{ __html: adContent.body }} onClick={handleAdClick} />
                    {adContentVideo.ctPlayback_URL &&
                        <video
                            onClick={handleAdClick}
                            src={adContentVideo.ctPlayback_URL}
                            // controls
                            autoPlay
                            style={{ width: "100%", height: "auto" }}
                        />}


                    {/* Click and Impression Buttons */}
                    <div style={{ marginTop: '10px' }}>
                        <Button onClick={handleAdClick} style={{ marginRight: '10px' }}>
                            {t("Clic")}
                        </Button>
                        <Button style={{ marginRight: '10px' }} onClick={() => handleManualImpression()}>
                            Impression
                        </Button>

                    </div>
                </div>:
                "loading.."
            )}
        </div>
    );
};

export default AdComponent;
