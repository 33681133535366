import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Form, Card, CardBody, Image, Alert, Accordion, Modal, Spinner } from "react-bootstrap";
import { useStateContext } from "../../../context/ContextProvider";
import axiosClient, { link } from "../../../axios-client";
import { Media } from "reactstrap";
import { FaPencilAlt } from "react-icons/fa";
import Resizer from "react-image-file-resizer";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Avatar } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
// import '@adzerk/management-sdk'

const styles = `
.custom-modal-size {
  max-width: 600px; 
}
`

const Profile = () => {
  const { t } = useTranslation()
  const { user, updateUser } = useStateContext()
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState()
  const [isLoadingMdp, setIsLoadingMdp] = useState()
  const [isLoadingConfig, setIsLoadingConfig] = useState()
  const [selectedImage, setSelectedImage] = useState()
  const [selectedImg, setSelectedImg] = useState()
  const [image, setImage] = useState()

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordColor, setPasswordColor] = useState('');
  const [message, setMessage] = useState('');
  const [messageEdit, setMessageEdit] = useState('');

  const [editAdvertiserModal, setEditAdvertiserModal] = useState(false);

  const [name, setName] = useState('')
  const [checkDailyCapAmount, setCheckDailyCapAmount] = useState(false)
  const [dailyCapAmount, setDailyCapAmount] = useState()
  const [checkLifetimeCapAmount, setCheckLifetimeCapAmount] = useState(false)
  const [lifetimeCapAmount, setLifetimeCapAmount] = useState()
  const [checkLimitPlacementsPerRequest, setCheckLimitPlacementsPerRequest] = useState(false)
  const [placementLimit, setPlacementLimit] = useState()
  const [checkEnableFrequencyCapping, setCheckEnableFrequencyCapping] = useState(false)
  const [freqCap, setFreqCap] = useState()
  const [freqCapDuration, setFreqCapDuration] = useState()
  const [freqCapType, setFreqCapType] = useState(1)

  const [selectedAdvertiserId, setSelectedAdvertiserId] = useState()

  const [dataAdvertiserId, setDataAdvertiserId] = useState()
  const [errors, setErrors] = useState({
    Name: '',
    DailyCapAmount: '',
    LifetimeCapAmount: '',
    PlacementLimit: '',
    FreqCap: '',
    FreqCapDuration: '',
  })

  const nameRef = useRef(null);
  const dailyCapAmountRef = useRef(null);
  const lifetimeCapAmountRef = useRef(null);
  const placementLimitRef = useRef(null);
  const freqCapRef = useRef(null);
  const freqCapDurationRef = useRef(null);

  const handleCloseEditAdvertiserModal = () => {
    setSelectedAdvertiserId()
    setName('')
    setCheckDailyCapAmount(false)
    setDailyCapAmount()
    setCheckLifetimeCapAmount(false)
    setLifetimeCapAmount()
    setCheckLimitPlacementsPerRequest(false)
    setPlacementLimit()
    setCheckEnableFrequencyCapping(false)
    setFreqCap()
    setFreqCapDuration()
    setFreqCapType(1)
    setErrors({
      Name: '',
      DailyCapAmount: '',
      LifetimeCapAmount: '',
      PlacementLimit: '',
      FreqCap: '',
      FreqCapDuration: '',
    })
    setEditAdvertiserModal(false)
  };

  const handleShowEditAdvertiserModal = () => setEditAdvertiserModal(true);

  const getAdvertiserWithId = async (id) => {
    try {
      await axiosClient.get(`get-advertiser-with-id/${id}/`).then(res => {
        console.log('RES DATA : ', res.data);
        setDataAdvertiserId(res.data)
        if (res.data.Title) {
          setName(res.data.Title)
        }
        if (res.data.DailyCapAmount) {
          setCheckDailyCapAmount(true)
          setDailyCapAmount(res.data.DailyCapAmount)
        } else {
          setCheckDailyCapAmount(false)
          setDailyCapAmount()
        }
        if (res.data.LifetimeCapAmount) {
          setCheckLifetimeCapAmount(true)
          setLifetimeCapAmount(res.data.LifetimeCapAmount)
        } else {
          setCheckLifetimeCapAmount(false)
          setLifetimeCapAmount()
        }
        if (res.data.PlacementLimit) {
          setCheckLimitPlacementsPerRequest(true)
          setPlacementLimit(res.data.PlacementLimit)
        } else {
          setCheckLimitPlacementsPerRequest(false)
          setPlacementLimit()
        }

        if (res.data.FreqCap) {
          setCheckEnableFrequencyCapping(true)
          setFreqCap(res.data.FreqCap)
          setFreqCapDuration(res.data.FreqCapDuration)
          setFreqCapType(res.data.FreqCapType)
        } else {
          setCheckEnableFrequencyCapping(false)
          setFreqCap()
          setFreqCapDuration()
          setFreqCapType(1)
        }
        handleShowEditAdvertiserModal()
        return res.data
      })
    } catch (error) {
      console.error('Failed to fetch advertiser data:', error);
      toast.error(t("Impossible de récupérer les données de l'annonceur. Veuillez réessayer."), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }

  }

  const updateAdvertiser = async (id) => {
    let newErrors = { ...errors };
    // Validate form fields
    let firstErrorRef = null;
    const formData = new FormData()

    if (name) {
      formData.append('Title', name)
      newErrors.Name = ''
    } else {
      newErrors.Name = t('Ce champ est obligatoire *')
      if (!firstErrorRef) firstErrorRef = nameRef;
    }
    if (checkDailyCapAmount) {
      if (dailyCapAmount) {
        formData.append('DailyCapAmount', dailyCapAmount)
        newErrors.DailyCapAmount = ''
      } else {
        newErrors.DailyCapAmount = t('Ce champ est obligatoire *')
        if (!firstErrorRef) firstErrorRef = dailyCapAmountRef;
      }
    }
    else {
      formData.append('DailyCapAmount', 0)
      newErrors.DailyCapAmount = ''
    }
    if (checkLifetimeCapAmount) {
      if (lifetimeCapAmount) {

        formData.append('LifetimeCapAmount', lifetimeCapAmount)
        newErrors.LifetimeCapAmount = ''
      } else {
        newErrors.LifetimeCapAmount = t('Ce champ est obligatoire *')
        if (!firstErrorRef) firstErrorRef = lifetimeCapAmountRef;
      }
    }
    else {
      formData.append('LifetimeCapAmount', 0)
      newErrors.LifetimeCapAmount = ''
    }
    if (checkLimitPlacementsPerRequest) {
      if (placementLimit) {

        formData.append('PlacementLimit', placementLimit)
        newErrors.PlacementLimit = ''
      } else {
        newErrors.PlacementLimit = t('Ce champ est obligatoire *')
        if (!firstErrorRef) firstErrorRef = placementLimitRef;
      }
    }
    else {
      formData.append('PlacementLimit', 0)
      newErrors.PlacementLimit = ''
    }
    if (checkEnableFrequencyCapping) {
      if (freqCap) {
        formData.append('FreqCap', freqCap)
        newErrors.FreqCap = ''
      } else {
        newErrors.FreqCap = t('Ce champ est obligatoire *')
        if (!firstErrorRef) firstErrorRef = freqCapRef;
      }
      if (freqCapDuration) {
        formData.append('FreqCapDuration', freqCapDuration)
        newErrors.FreqCapDuration = ''
      } else {
        newErrors.FreqCapDuration = t('Ce champ est obligatoire *')
        if (!firstErrorRef) firstErrorRef = freqCapDurationRef;
      }
      if (freqCapType) {
        formData.append('FreqCapType', freqCapType)
      }
    }
    else {
      formData.append('FreqCap', 0)
      formData.append('FreqCapDuration', 0)
      formData.append('FreqCapType', 0)
      newErrors.FreqCap = ''
      newErrors.FreqCapDuration = ''
    }
    setErrors(newErrors);
    console.log('ERROOOOOORS / ', newErrors);

    // Scroll to the first error if any
    if (firstErrorRef) {
      firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    formData.append('IsActive', true)

    const formDataTitle = new FormData();
    if (name) formDataTitle.append("Advertiser_title", name);

    if (!firstErrorRef) {
      try {
        const response_title = await axiosClient.put(`/update_agency_name/${user.id}/`, formDataTitle)

        const response = await axiosClient.put(`update-advertiser/${id}/`, formData);
        if (response.status === 200 && response_title.data.message) {

          handleCloseEditAdvertiserModal()
          toast.success(t('la configuration est mise à jour'), {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        return
      }
    }
  };
  const resizeFile = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });
  };
  const handleChangeImage = async (e) => {
    try {
      const file = e.target.files[0];
      setSelectedImg(file)
      const blobImage = await resizeFile(file);

      const fileName = file.name; // Use the original filename
      const imageFile = new File([blobImage], fileName, { type: blobImage.type });

      setSelectedImage(imageFile);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (selectedImg) {
      setImage(URL.createObjectURL(selectedImg))
    }
    else {
      setSelectedImg()
    }

  }, [selectedImage, user]);

  const handleSubmit = async (e) => {
    console.log('handleSubmit called');
    e.preventDefault();
    setIsLoading(true)
    if (email) {
      const check = await axiosClient.get(`/email_exists/${email}/`);
      if (check.data.exists) {
        setIsLoading(false);
        setMessageEdit(t("Cet email existe déja"));
        return;
      }
    }
    const formData = new FormData();
    if (firstName) { formData.append("first_name", firstName); }
    if (lastName) { formData.append("last_name", lastName); }
    if (email) { formData.append("email", email); }
    if (tel) { formData.append("tel", tel); }
    if (selectedImage) formData.append("image", selectedImage);

    try {
      let response
      if (user.role === "Agency") {
        response = await axiosClient.put(`/update_agency/${user.id}/`, formData)
      }
      else {
        response = await axiosClient.put(`/update_client/${user.id}/`, formData)
      }

      if (response.data.message) {
        toast.success(t('votre profil est modifié avec succès'), {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        updateUser();
        window.scrollTo(0, 0)
        setIsLoading(false)
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false)
    }

  };

  const checkPasswordStrength = () => {
    const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
    const minLengthRegex = /^.{8,}$/;
    const startLength = /^.{2,}$/;
    const digitRegex = /\d/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;

    let missingRequirements = [];

    if (!specialchar.test(newPassword)) {
      missingRequirements.push(t("caractère spécial"));
    } else if (!lowercaseRegex.test(newPassword)) {
      missingRequirements.push(t("minuscule"));
    } else if (!uppercaseRegex.test(newPassword)) {
      missingRequirements.push(t("majuscule"));
    } else if (!digitRegex.test(newPassword)) {
      missingRequirements.push(t("chiffres"));
    } else if (!minLengthRegex.test(newPassword)) {
      missingRequirements.push(t("longueur minimale de 8 caractères"));
    }

    if (missingRequirements.length > 0) {
      const missingText = `${t("Vous avez besoin de")} ${missingRequirements.join(", ")} ${t("dans votre mot de passe.")}`;
      setPasswordStrength(missingText);
      setPasswordColor('#ff7070');
    } else {
      setPasswordStrength(t('Le mot de passe est correct!'));
      setPasswordColor('green');
    }
  }

  useEffect(() => {
    checkPasswordStrength();
  }, [newPassword]);


  // check the password complexity
  const isPasswordValid = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
    const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
    const minLengthRegex = /^.{8,}$/;
    const digitRegex = /\d/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;

    let errors = [];

    if (!minLengthRegex.test(password)) {
      errors.push(t('Le mot de passe doit comporter au moins 8 caractères.'));

    }

    if (!digitRegex.test(password)) {
      errors.push(t('Le mot de passe doit contenir au moins un chiffre.'));
    }

    if (!lowercaseRegex.test(password)) {
      errors.push(t('Le mot de passe doit contenir au moins une lettre minuscule.'));
    }

    if (!uppercaseRegex.test(password)) {
      errors.push(t('Le mot de passe doit contenir au moins une lettre majuscule.'));
    }
    if (!specialchar.test(password)) {
      errors.push(t('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).'));
    }
    if (password.length > 20) {
      errors.push(t('Le mot de passe ne doit pas dépasser 20 caractères.'));
    }

    if (errors.length > 0) {
      setMessage(errors[0]);
      setIsLoadingMdp(false)
      return false;
    }

    return passwordRegex.test(password);
  };

  const handleFormSubmit = async (e) => {
    setIsLoadingMdp(true)
    e.preventDefault();
    if (!newPassword || !oldPassword || !confirmPassword) {
      setMessage(t("veuillez remplir tous les champs"))
      setIsLoadingMdp(false)
      return
    }
    if (!isPasswordValid(newPassword)) {
      return;
    }
    try {
      const response = await axiosClient.put(`/change_password/${user.id}/`, {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
        useradd: user.first_name + ' ' + user.last_name
      });
      if (response.data.success) {
        toast.success(t('le mot de passe est modifié avec succès'), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });

        setConfirmPassword('')
        setNewPassword('')
        setOldPassword('')
        setMessage('');
        window.scrollTo(0, 0)
        setIsLoadingMdp(false)
      }
      if (response.data.error) {
        setMessage(response.data.error); // Show the error message
      }
      setIsLoadingMdp(false)

    } catch (err) {
      console.log(err.response.data.error);
      setIsLoadingMdp(false)
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <style>{styles}</style>


      <Card className="container   py-3">
        <h4 className="card-title px-2 px-sm-5 my-2 my-sm-4">{t("Modifier profil")}</h4>

        <CardBody className="container px-3 px-sm-5 py-2">

          <Form onSubmit={handleSubmit}>
            <Row >
              <Col style={{ alignItems: "center", justifyContent: "center", display: "flex" }} md="4" xl="3">
                <Media className="mb-3 mb-md-0" style={{ alignItems: "center", justifyContent: "center", display: "flex", width: "100%", alignContent: "center" }} as={Col} md="12">
                  <div className="position-relative" style={{ width: 180, height: 180, marginRight: 10 }}>

                    <Avatar
                      style={{ objectFit: "cover", borderRadius: "5%" }}
                      sx={{ width: 180, height: 180 }}
                      src={image ? image : user.image}
                      variant="square"
                    />
                    <label htmlFor="imageInput" className="position-absolute" style={{
                      cursor: 'pointer',
                      bottom: -15,
                      right: -10,
                      border: "3px solid white",
                      borderRadius: "50%"
                    }}>
                      <input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleChangeImage}
                      />
                      <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#232939', borderRadius: '50%', width: 30, height: 30 }}>
                        <FaPencilAlt size={15} color='#fff' />
                      </div>
                    </label>
                  </div>

                </Media>
              </Col>
              <Col md='8' xl="9">
                {messageEdit &&
                  <Alert variant={"danger"}>
                    {messageEdit}
                  </Alert>
                }
                <Row className="mb-3 gap-2 gap-md-0">
                  <Form.Group as={Col} md="12" controlId="validationCustomEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder={t("Entrez l'email")}
                      defaultValue={user.email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>


                </Row>
                <Row className="mb-3 gap-2 gap-md-0">

                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>{t("Nom")}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder={t("Entrez le nom")}
                      defaultValue={user.last_name}
                      onChange={(e) => setLastName(e.target.value)}
                    />

                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>{t("Prénom")}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder={t("Entrez le prénom")}
                      defaultValue={user.first_name}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 gap-2 gap-md-0">
                  <Form.Group as={Col} md="6" controlId="validationCustomTel">
                    <Form.Label>{t("Numéro de téléphone")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Entrez le numéro de téléphone")}
                      defaultValue={user.tel}
                      onChange={(e) => setTel(e.target.value)}
                    />
                  </Form.Group>


                </Row>
              </Col>
            </Row>


            <div className="d-flex justify-content-end gap-2">
              {user.role === 'Agency' &&
                <Button onClick={() => {
                  setSelectedAdvertiserId(user?.advertiser)
                  getAdvertiserWithId(user?.advertiser)
                }}
                  variant="secondary" >{t("Configuration")}</Button>
              }

              <Button type="submit" isLoading={isLoading} >  {isLoading ? <Spinner size="sm" /> : t('Enregistrer')}</Button>

            </div>
          </Form>
        </CardBody>
      </Card>
      <Accordion className="container py-3 px-0">
        <Accordion.Item className="bg-white" eventKey="0">


          <Accordion.Header>  <h4 className="card-title px-2 px-sm-5 my-2">{t('Sécurité de compte')}</h4></Accordion.Header>
          <Accordion.Body className="container px-4 px-sm-5 py-3">
            <Form onSubmit={handleFormSubmit}>

              {/* <Row>
            <Col className="d-flex align-items-center justify-content-center" md="4" >
               <Image width={'150'} height={'150'} src="https://cdn-icons-png.flaticon.com/512/103/103089.png" />
            </Col> */}
              <div className="d-flex justify-content-center ">
                <Col md="12">
                  {message &&
                    <Alert variant={"danger"}>
                      {message}
                    </Alert>
                  }
                  <Row className="mb-3 gap-2 gap-md-0">
                    <Form.Group as={Col} md="12" controlId="validationCustomOldPassword">
                      <Form.Label>{t("Ancien mot de passe")}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={t("Entrez l'ancien mot de passe")}
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 gap-2 gap-md-0">

                    <Form.Group as={Col} md="6" controlId="validationCustomPassword">
                      <Form.Label>{t("Nouveau mot de passe")}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={t("Entrez le nouveau mot de passe")}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      {newPassword.length > 0 ?
                        <p style={{ color: passwordColor, marginTop: 2 }}>{`${passwordStrength}`}</p>
                        : ""}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustomConfirmPassword">
                      <Form.Label>{t("Confirmez mot de passe")}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={t("Confirmer le mot de passe")}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                </Col>
              </div>

              {/* </Row> */}




              <div className="d-flex justify-content-end">
                <Button isLoading={isLoadingMdp} type="submit">{isLoadingMdp ? <Spinner size="sm" /> : t('Changer le mot de passe')}</Button>
              </div>
            </Form>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>

      <Modal dialogClassName="custom-modal-size" show={editAdvertiserModal} onHide={handleCloseEditAdvertiserModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Configuration  d'annonceur")}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="mx-3"
          style={{
            maxHeight: '70vh',
            overflow: 'auto'
          }}>
          <Form>
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Pick an Advertiser</Form.Label>
                            <Form.Control type="text" placeholder="Pick an Advertiser" />
                        </Form.Group> */}

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>{t("Nom d'agence")}</Form.Label>
              <Form.Control ref={nameRef} type="text" value={name} placeholder={t("Entrez le nom d'agence")} onChange={e => setName(e.target.value)} />
              {errors.Name && <Form.Text className="text-danger">{errors.Name}</Form.Text>}
            </Form.Group>

            {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Salesperson</Form.Label>
                            <Form.Control type="text" placeholder="Salesperson" />
                        </Form.Group> */}

            <Form.Group className="mb-3 d-flex align-items-center" controlId="dailyRevenue">
              <Form.Check className='w-50' type="checkbox" label={t("Plafond de revenus quotidiens")} checked={checkDailyCapAmount} onChange={() => setCheckDailyCapAmount(!checkDailyCapAmount)} />
              {checkDailyCapAmount && <div className='w-50' style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Control ref={dailyCapAmountRef} className='' type="number" min={0} placeholder="" value={dailyCapAmount} onChange={e => setDailyCapAmount(e.target.value)} />
                {errors.DailyCapAmount && <Form.Text className="text-danger">{errors.DailyCapAmount}</Form.Text>}
              </div>}
            </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center" controlId="lifetimeRevenue">
              <Form.Check className='w-50' type="checkbox" label={t("Plafond de revenus à vie")} checked={checkLifetimeCapAmount} onChange={() => setCheckLifetimeCapAmount(!checkLifetimeCapAmount)} />
              {checkLifetimeCapAmount &&
                <div className='w-50' style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Control ref={lifetimeCapAmountRef} className='' type="number" min={0} placeholder="" value={lifetimeCapAmount} onChange={e => setLifetimeCapAmount(e.target.value)} />
                  {errors.LifetimeCapAmount && <Form.Text className="text-danger">{errors.LifetimeCapAmount}</Form.Text>}
                </div>
              }
            </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center" controlId="placementLimit">
              <Form.Check className='w-50' type="checkbox" label={t("limite des placements par demande")} checked={checkLimitPlacementsPerRequest} onChange={() => setCheckLimitPlacementsPerRequest(!checkLimitPlacementsPerRequest)} />
              {checkLimitPlacementsPerRequest &&
                <div className='w-50' style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Control ref={placementLimitRef} className='' type="number" min={0} placeholder="" value={placementLimit} onChange={e => setPlacementLimit(e.target.value)} />
                  {errors.PlacementLimit && <Form.Text className="text-danger">{errors.PlacementLimit}</Form.Text>}
                </div>
              }
            </Form.Group>

            <Form.Group className="mb-3" controlId="enableFrequency">
              <Form.Check className="mb-3" type="checkbox" label={t("Activer la limitation de fréquence")} checked={checkEnableFrequencyCapping} onChange={e => setCheckEnableFrequencyCapping(!checkEnableFrequencyCapping)} />
              {checkEnableFrequencyCapping &&
                <div className='px-3 d-flex' style={{ flexDirection: 'column', gap: 10 }}>
                  <div>
                    <Form.Label className='m-0 mb-1' style={{ fontWeight: 100 }}>{t("Afficher les annonces de cet annonceur")}</Form.Label>
                    <Form.Control ref={freqCapRef} type="number" placeholder="" value={freqCap} onChange={e => setFreqCap(e.target.value)} />
                    {errors.FreqCap && <> <Form.Text className="text-danger">{errors.FreqCap}</Form.Text><br /></>}
                  </div>
                  <div>
                    <Form.Label className='m-0 mb-1' style={{ fontWeight: 100 }}>{t("fois par")}</Form.Label>
                    <Form.Control ref={freqCapDurationRef} type="number" placeholder="" value={freqCapDuration} onChange={e => setFreqCapDuration(e.target.value)} />
                    {errors.FreqCapDuration && <Form.Text className="text-danger">{errors.FreqCapDuration}</Form.Text>}
                    <Form.Select type="text" placeholder="" value={freqCapType} onChange={e => setFreqCapType(e.target.value)} >
                      <option value={1}>{t("Heure(s)")}</option>
                      <option value={2}>{t("Jour(s)")}</option>
                      <option value={3}>{t("Minute(s)")}</option>

                    </Form.Select>
                  </div>


                </div>}
            </Form.Group>



          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditAdvertiserModal}>
            {t("Annuler")}
          </Button>
          <Button variant="primary" onClick={() => updateAdvertiser(dataAdvertiserId?.Id)}>
            {t("Modifier")}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>

  );
};

export default Profile;
