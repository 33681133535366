import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, Spinner } from 'react-bootstrap';
import './custom.css';
import { Avatar, AvatarGroup, colors, Pagination, Stack } from '@mui/material';
import { MdCampaign, MdOutlineAdsClick, MdOutlineRadio, MdPublishedWithChanges } from 'react-icons/md';
import Slider from 'react-infinite-logo-slider'
import axiosClient, { link, pagination_size } from '../../../axios-client';
import { FaSackDollar, FaUserTie } from "react-icons/fa6";
import { GiMusicalNotes } from "react-icons/gi";
import { FaAd, FaRegChartBar } from "react-icons/fa";
import ApexCharts from 'apexcharts';
import { GrAnnounce } from "react-icons/gr";

import CryptoJS from 'crypto-js';
import { useStateContext } from '../../../context/ContextProvider';
import { useTranslation } from 'react-i18next';

const CardComponent = ({ bgGradientClass, amount, description, svgPath, loading }) => (
    <Col xs={12} sm={6} md={6} lg={4} xl={3}>
        <div className={`relative p-3 rounded-xl overflow-hidden ${bgGradientClass}`} style={{ height: '100px', borderRadius: "10px" }}>
            <div className="relative z-10 mb-1 text-white " style={{ fontWeight: "bold", fontSize: 25 }}>{loading ? <Spinner size='md' style={{ color: '#ffffff66' }} animation="border" /> : amount}</div>
            <div className="relative z-10 text-white" style={{ fontWeight: "semi-bold", fontSize: 16 }}>{description}</div>
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ position: "absolute", right: 0, bottom: 0, height: 140, width: 140, marginRight: -30, marginBottom: -30, opacity: 25 }} className="-mr-8 -mb-8 text-white opacity-25 z-0">
                {svgPath}
            </svg>
        </div>

    </Col>
);

function DashClient() {
    const [count, setCount] = useState()
    const { user } = useStateContext()
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation()

    const [clientStats, setClientStats] = useState({})


    useEffect(() => {

        axiosClient.get(`client-stats/${user.id}/`)
            .then((response) => {
                setClientStats(response.data);
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });

        axiosClient.get(`client-stats-by-day/${user?.id}/`)
            .then((response) => {
                const data = response.data;

                // Set the chart options with real data from the response
                const options = {
                    series: [
                        {
                            name: "Impression",
                            data: data.impressions // Use the real impressions data
                        },
                        {
                            name: t("Clics"),
                            data: data.clicks // Use the real clicks data
                        }
                    ],
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: { enabled: false }
                    },
                    colors: ['#7b67ff', '#f5c461'],
                    dataLabels: { enabled: false },
                    stroke: {
                        width: [3, 5],
                        curve: 'straight',
                        dashArray: [0, 8]
                    },
                    title: {
                        text: t('Impressions vs Clics'),
                        align: 'left'
                    },
                    legend: {
                        tooltipHoverFormatter: function (val, opts) {
                            return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>';
                        }
                    },
                    markers: { size: 0, hover: { sizeOffset: 6 } },
                    xaxis: {
                        categories: data.dates // Use the real dates for the x-axis
                    },
                    yaxis: [
                        {
                            title: { text: "Impressions" },
                            labels: {
                                formatter: function (value) {
                                    return value.toFixed(0);
                                }
                            }
                        },
                        {
                            opposite: true, // This places the second y-axis on the right
                            title: { text: `${t("Clics")}` },
                            labels: {
                                formatter: function (value) {
                                    return value.toFixed(0);
                                }
                            }
                        }
                    ],
                    tooltip: {
                        y: [
                            { title: { formatter: (val) => val } },
                            { title: { formatter: (val) => val } }
                        ]
                    },
                    grid: { borderColor: '#f1f1f1', padding: { left: 30, right: 30 } }
                };

                const chart = new ApexCharts(document.querySelector("#chart"), options);
                chart.render();

            })
            .catch((error) => {
                console.log(error);
            });

    }, [user]);

    return (
        <div>
            <div className="py-3 d-flex align-items-center justify-content-center">
                <div className='px-3' style={{ width: "100%" }}>
                    <Row className="g-3">
                        <CardComponent
                            bgGradientClass="bg-gradient-teal-green"

                            amount={clientStats?.impressions}
                            description={t("impressions totales")}
                            svgPath={
                                <GrAnnounce size={22} />
                            }
                            loading={loading}
                        />
                        <CardComponent
                            bgGradientClass="bg-gradient-blue"
                            amount={clientStats?.clicks}
                            description={t("nombre total de clics")}
                            svgPath={
                                <MdOutlineAdsClick size={22} />
                            }
                            loading={loading}
                        />
                        <CardComponent
                            bgGradientClass="bg-gradient-red"
                            amount={`${clientStats?.revenue || 0} $`}
                            description={t("Dépenses")}
                            svgPath={
                                <FaSackDollar size={22} />
                            }
                            loading={loading}
                        />
                        <CardComponent
                            bgGradientClass="bg-gradient-yellow"
                            amount={`${(clientStats.gmv / clientStats.revenue) || 0} %`}
                            description="ROAS"
                            svgPath={
                                <FaRegChartBar size={22} />
                            }
                            loading={loading}
                        />

                    </Row>
                </div>


            </div>
            <div style={{ borderRadius: 10 }} className='p-3 mx-3 bg-white'>
                <div id="chart"></div>
            </div>



        </div>


    )
}

export default DashClient