import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button, Spinner, Alert, InputGroup } from 'react-bootstrap';
import { useStateContext } from '../../../context/ContextProvider';
import axiosClient, { link } from '../../../axios-client';
import { useNavigate } from 'react-router-dom/dist';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function Register() {
  const { t } = useTranslation()
  const titleColor = "white";
  const textColor = "gray.400";
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { setToken, isLoading, setIsLoading } = useStateContext();
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordColor, setPasswordColor] = useState('');

  const checkPasswordStrength = () => {
    const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
    const minLengthRegex = /^.{8,}$/;
    const startLength = /^.{2,}$/;
    const digitRegex = /\d/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;

    let missingRequirements = [];

    if (!specialchar.test(password)) {
      missingRequirements.push(t("caractère spécial"));
    } else if (!lowercaseRegex.test(password)) {
      missingRequirements.push(t("minuscule"));
    } else if (!uppercaseRegex.test(password)) {
      missingRequirements.push(t("majuscule"));
    } else if (!digitRegex.test(password)) {
      missingRequirements.push(t("chiffres"));
    } else if (!minLengthRegex.test(password)) {
      missingRequirements.push(t("longueur minimale de 8 caractères"));
    }

    if (missingRequirements.length > 0) {
      const missingText = `${t("Vous avez besoin de")} ${missingRequirements.join(", ")} ${t("dans votre mot de passe.")}`;
      setPasswordStrength(missingText);
      setPasswordColor('#ff98a9');
    } else {
      setPasswordStrength(t('Le mot de passe est correct!'));
      setPasswordColor('#a7f2a7');
    }
  }
  useEffect(() => {
    checkPasswordStrength();
  }, [password]);
  // check the password complexity
  const isPasswordValid = (pwd) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
    const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
    const minLengthRegex = /^.{8,}$/;
    const digitRegex = /\d/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;

    let errors = [];

    if (!minLengthRegex.test(pwd)) {
      errors.push(t('Le mot de passe doit comporter au moins 8 caractères.'));
    }

    if (!digitRegex.test(pwd)) {
      errors.push(t('Le mot de passe doit contenir au moins un chiffre.'));
    }

    if (!lowercaseRegex.test(pwd)) {
      errors.push(t('Le mot de passe doit contenir au moins une lettre minuscule.'));
    }

    if (!uppercaseRegex.test(pwd)) {
      errors.push(t('Le mot de passe doit contenir au moins une lettre majuscule.'));
    }
    if (!specialchar.test(pwd)) {
      errors.push(t('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).'));
    }
    if (pwd.length > 20) {
      errors.push(t('Le mot de passe ne doit pas dépasser 20 caractères.'));
    }

    if (errors.length > 0) {

      setMessage(errors[0]);
      return false;
    }

    return passwordRegex.test(pwd);
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////


  const createAdvertiser = async (title) => {
    try {
      const response = await axios.post(`${link}/create-advertiser/`, {
        title: title,
      });
      return response.data; // Return the advertiser object
    } catch (error) {
      console.error('Error creating advertiser:', error.response ? error.response.data : error.message);
      throw error; // Rethrow to handle it in the calling function
    }
  };

  const onSubmit = async (ev) => { // Make the function async
    ev.preventDefault();
    setIsLoading(true);
    const check = await axios.get(`${link}/email_exists/${email}/`);

    if (!isFormFilled) {
      setMessage(t('Veuillez remplir les champs correctement'));
      setIsLoading(false);

      return;

    } else if (!emailRegex.test(email)) {
      setMessage(t("Veuillez entrer un email valide."));
      setIsLoading(false);
      return;
    }
    else if (check.data.exists) {
      setIsLoading(false);
      setMessage(t("Cet email existe déja"));
      return;
    }
    else if (password !== confirmPassword) {
      setMessage(t("Les mots de passe ne correspondent pas"));
      setIsLoading(false);

      return;
    }
    else if (passwordStrength !== 'Fort' && password.length > 0 && !isPasswordValid(password)) {
      setIsLoading(false);

      return;
    }


    try {
      const advertiser = await createAdvertiser(agencyName);

      const payload = {
        first_name: prenom,
        last_name: nom,
        email: email,
        Advertiser_title: agencyName,
        Advertiser: advertiser.Id,
        password: password,
      };

      axios.post(`${link}/agences/`, payload)
        .then(({ data }) => {
          navigate('/login');
          setIsLoading(false);
        })
        .catch((err) => {
          const response = err.response;
          setMessage(response.data);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error creating advertiser:", error);
      setMessage("Une erreur s'est produite lors de la création de compte, veuillez réessayer.");
      setIsLoading(false);
    }
  };


  const isFormFilled = nom.trim() && prenom.trim() && agencyName.trim() && email.trim() && password.trim() && confirmPassword.trim();

  return (
    <div className="bg-image" style={{ backgroundImage: `url(https://img.freepik.com/free-photo/top-viewtop-view-manager-employee-doing-teamwork-business-office-looking-charts-laptop-display_482257-2443.jpg?t=st=1723040773~exp=1723044373~hmac=27a66410abb3661b6c5fb4a1ae5ce29ff37f2d4b817c5bd740fc588ffcbd7625&w=826)`, backgroundSize: 'cover', backgroundRepeat: "no-repeat", minHeight: '100vh', width: "100%", overflowY: 'auto' }}>
      <ToastContainer />
      <div className='' style={{ position: 'relative', minHeight: '100vh', width: "100%", background: 'rgba(0, 0, 0, 0.5)' }}>
        <div className='col-xl-4 col-lg-5 col-md-6 col-12' style={{ display: "flex", minHeight: '100vh', width: "100%", alignItems: "center", justifyContent: "center" }}>

          <Card className='wrapper mx-2 mb-5 p-lg-4 p-md-3 p-3 shadow' style={{ background: 'rgba(0, 0, 0, 0)', width: "500px" }}>
            <Card.Body className='p-1'>
              <h1 className="fw-bold mb-4 mt-2 text-center" style={{ color: "white" }}>{t("Créer un compte")}</h1>

              {message && <Alert variant={"danger"}>{message}</Alert>}

              <Row>
                <Col>
                  <Form.Group style={{ background: 'transparent', borderBottom: "2px solid white", outline: "none" }} className='mb-4'>
                    <Form.Label style={{ color: "white", fontWeight: "lighter", marginBottom: 0 }}>{t("Nom")}</Form.Label>
                    <Form.Control autocomplete="off" style={{ background: 'transparent', border: "none", color: "white", outline: "none", borderRadius: "0" }} placeholder={t('Entrez votre nom')} value={nom} onChange={(e) => setNom(e.target.value)} size='lg' type='text' />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group style={{ background: 'transparent', borderBottom: "2px solid white", outline: "none" }} className='mb-4'>
                    <Form.Label style={{ color: "white", fontWeight: "lighter", marginBottom: 0 }}>{t("Prénom")}</Form.Label>
                    <Form.Control style={{ background: 'transparent', border: "none", color: "white", outline: "none", borderRadius: "0" }} placeholder={t('Entrez votre prénom')} value={prenom} onChange={(e) => setPrenom(e.target.value)} size='lg' type='text' />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group style={{ background: 'transparent', borderBottom: "2px solid white", outline: "none" }} className='mb-4'>
                <Form.Label style={{ color: "white", fontWeight: "lighter", marginBottom: 0 }}>{t("Nom d'agence")}</Form.Label>
                <Form.Control style={{ background: 'transparent', border: "none", color: "white", outline: "none", borderRadius: "0" }} placeholder={t("Entrez le nom d'agence")} value={agencyName} onChange={(e) => setAgencyName(e.target.value)} size='lg' type='text' />
              </Form.Group>

              <Form.Group style={{ background: 'transparent', borderBottom: "2px solid white", outline: "none" }} className='mb-4'>
                <Form.Label style={{ color: "white", fontWeight: "lighter", marginBottom: 0 }}>Email</Form.Label>
                <Form.Control style={{ background: 'transparent', border: "none", color: "white", outline: "none", borderRadius: "0" }} placeholder={t('Entrez votre email')} onChange={(e) => setEmail(e.target.value)} value={email} size='lg' type='email' />
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group style={{ background: 'transparent', borderBottom: "2px solid white", outline: "none" }} className='mb-4'>
                    <Form.Label style={{ color: "white", fontWeight: "lighter", marginBottom: 0 }}>{t("Mot de passe")}</Form.Label>
                    <InputGroup>
                      <Form.Control
                        style={{ background: 'transparent', border: "none", color: "white", outline: "none", borderRadius: "0" }}
                        placeholder={t('Entrez votre mot de passe')}
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        size='lg'
                        type={showPassword ? 'text' : 'password'} />
                      <Button
                        variant="trabsoarent"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEye color='white' size={"15px"} /> : <FaEyeSlash color='white' size={"15px"} />}  {/* Toggle between eye and eye-slash icons */}
                      </Button>
                    </InputGroup>

                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group style={{ background: 'transparent', borderBottom: "2px solid white", outline: "none" }} className='mb-4'>
                    <Form.Label style={{ color: "white", fontWeight: "lighter", marginBottom: 0 }}>{t("Confirmez le mot de passe")}</Form.Label>
                    <Form.Control style={{ background: 'transparent', border: "none", color: "white", outline: "none", borderRadius: "0" }} placeholder={t('Confirmez votre mot de passe')} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} size='lg' type='password' />
                  </Form.Group>
                </Col>
              </Row>
              {password && password.length > 0 ?
                <div style={{ color: passwordColor, marginBottom: 10 }} >{`${passwordStrength}`}</div>
                : ""}

              <Button onClick={onSubmit} isLoading={isLoading} disabled={!isFormFilled} className='mybtn w-100'>
                {isLoading ? <Spinner size="sm" /> : t('Registre')}
              </Button>

              <div class="register">
                <p>{t("Vous avez déjà un compte ?")} <span onClick={() => navigate("/login")} style={{ color: "white", textDecoration: 'underline', cursor: 'pointer' }}>{t("Connexion")}</span></p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Register;
