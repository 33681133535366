import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Card, CardBody, Image, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosClient from '../../../axios-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Resizer from 'react-image-file-resizer';
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

function Edit_demande() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { idDemande } = location.state || {};

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [videoList, setVideoList] = useState([]);
    const [selectedImageList, setSelectedImageList] = useState([]);
    const [selectedVideoList, setSelectedVideoList] = useState([]);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [inputKey, setInputKey] = useState(0);
    const [uploaded, setUploaded] = useState([])

    useEffect(() => {
        if (idDemande) {
            axiosClient.get(`/demandes/${idDemande}/`)
                .then(response => {
                    const data = response.data;
                    setTitle(data.object);
                    setDescription(data.description);
                    setStatus(data.etat);

                    const listimg = [];
                    const services_img = response.data.images
                    for (let index = 0; index < services_img?.length; index++) {
                        listimg.push(services_img[index].image);
                        setSelectedImageList(listimg);
                        setImageList(listimg);
                    }
                    const listvideos = [];
                    const services_videos = response.data.videos
                    for (let index = 0; index < services_videos?.length; index++) {
                        listvideos.push(services_videos[index].video);
                        setSelectedVideoList(listvideos);
                        setVideoList(listvideos);
                    }
                })
                .catch(error => {
                    toast.error(t("Erreur lors du chargement de la commande"), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                });
        }
    }, []);
    const handleRemoveImage = (index) => {
        const newSelectedImageList = [...selectedImageList];
        newSelectedImageList.splice(index, 1);

        setSelectedImageList(newSelectedImageList);
    };
    const handleImageChange = (e) => {
        const images = e.target.files;
        const allowedExtensions = ['.png', '.jpg', '.jpeg', '.webp'];

        if (images && images.length > 0) {
            if (selectedImageList.length + images.length > 5) {
                toast.error(t("Vous ne pouvez sélectionner que 5 images au maximum !"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                setInputKey(prevKey => prevKey + 1);
                return;
            }

            const selectedImages = Array.from(images);

            selectedImages.forEach(async (image) => {
                const extension = image.name.substring(image.name.lastIndexOf(".")).toLowerCase();
                if (!allowedExtensions.includes(extension)) {
                    toast.error(t("Extension de fichier non valide. Veuillez utiliser des fichiers .png, .jpg, .jpeg ou .webp."), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    try {
                        const imageURL = URL.createObjectURL(image);
                        const resizedImage = await new Promise((resolve, reject) => {
                            Resizer.imageFileResizer(
                                image,
                                451,
                                800,
                                'png',  // Output as PNG, regardless of input format
                                100,
                                0,
                                (uri) => resolve(uri),
                                'file'
                            );
                        });
                        setImageList(prevImageList => [...prevImageList, resizedImage]);
                        setSelectedImageList(prevSelectedImages => [...prevSelectedImages, imageURL]);
                    } catch (error) {
                        console.error('Error resizing image:', error);
                        toast.error(t("Une erreur s'est produite lors du redimensionnement de l'image."), {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }
            });

            setInputKey(prevKey => prevKey + 1);
        }
    };

    const handleVideoChange = (e) => {
        const videos = e.target.files;

        if (videos && videos.length > 0) {
            if (selectedVideoList.length + videos.length > 5) {
                toast.error(t("Vous ne pouvez sélectionner que 5 vidéos au maximum !"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                setInputKey(prevKey => prevKey + 1);
                return;
            }

            const selectedVideos = Array.from(videos);
            selectedVideos.forEach((video) => {
                const videoURL = URL.createObjectURL(video);
                setVideoList(prevVideoList => [...prevVideoList, video]);
                setSelectedVideoList(prevSelectedVideos => [...prevSelectedVideos, videoURL]);
            });

            setInputKey(prevKey => prevKey + 1);
        }
    };

    // const handleRemoveImage = (index) => {
    //     setSelectedImageList(prev => prev.filter((_, i) => i !== index));
    //     setImageList(prev => prev.filter((_, i) => i !== index));

    // };

    // const handleRemoveVideo = (index) => {
    //     setSelectedVideoList(prev => prev.filter((_, i) => i !== index));
    //     setVideoList(prev => prev.filter((_, i) => i !== index));
    // };


    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setIsLoading(true);
    //     const formData = new FormData();

    //     // Append demande data
    //     if (title) formData.append('object', title);
    //     if (description) formData.append('description', description);

    //     axiosClient.put(`/demandes/${idDemande}/`, formData)
    //         .then(response => {
    //             // Update images
    //             updateImages(response.data.id);

    //             // Update videos
    //             updateVideos(response.data.id);

    //             toast.success(t("Demande modifiée avec succès"));
    //             navigate('/demandes'); // Redirect to a relevant page
    //         })
    //         .catch(error => {
    //             toast.error(t("Erreur lors de la modification de la demande"));
    //             setMessage(error.message);
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         });
    // };

    const handleSubmit = () => {
        const formData = new FormData();
        if (title) formData.append("object", title);
        if (description) formData.append("description", description);
        formData.append("etat", status);
        try {
            axiosClient.patch(`/demandes/${idDemande}/`, formData).then((res) => {
                console.log('res data patch, ', res.data);
                // dispatch(setAjoutArticle(res.data))
            });
        } catch (error) {
            console.error('Error fetching boosting value:', error);
        }
        // Step 1: Update the Demande
        axiosClient.patch(`/demandes/${idDemande}/`, formData)
            .then(() => {

                // Handle Images
                axiosClient.get(`/demande-images/?search=${idDemande}`)
                    .then((res) => {
                        const existingImages = res.data.results;
                        const deleteImagesList = existingImages.filter((e) => !imageList.includes(e.image));
                        console.log("Data image:", deleteImagesList);
                        console.log("Image list:", imageList);

                        // Delete images not present in the updated imageList
                        deleteImagesList.forEach(image => {
                            axiosClient.delete(`/demande-images/${image.id}/`);
                        });

                        // Upload new images
                        const newImagesToAdd = imageList.filter((image) => typeof image !== "string");
                        const imageUploadPromises = newImagesToAdd.map((newImage) => {
                            console.log(newImage);

                            const imageData = new FormData();
                            imageData.append("demande", idDemande);
                            imageData.append("image", newImage);
                            return axiosClient.post(`/demande-images/`, imageData);
                        });
                        Promise.all(imageUploadPromises)
                            .then(() => {

                                // Fetch updated service data with images
                                return axiosClient.get(`/demandes/${idDemande}/`);
                            })
                            .then((res) => {
                                const { images, ...myNewData } = res.data;
                                navigate('/my-requests');
                            })
                            .catch((error) => {
                                console.error('Error uploading new images:', error);
                            });
                        // // Handle Videos
                        axiosClient.get(`/demande-videos/?search=${idDemande}`)
                            .then((res) => {
                                const existingVideos = res.data.results;
                                const deleteVideosList = existingVideos.filter((e) => !videoList.includes(e.video));
                                console.log("Data video:", deleteVideosList);
                                console.log("Video list:", videoList);

                                // Delete videos not present in the updated videoList
                                deleteVideosList.forEach(video => {
                                    axiosClient.delete(`/demande-videos/${video.id}/`);
                                });

                                // Upload new videos
                                const newVideosToAdd = videoList.filter((video) => typeof video !== "string");
                                const videoUploadPromises = newVideosToAdd.map((newVideo) => {
                                    const videoData = new FormData();
                                    videoData.append("demande", idDemande);
                                    videoData.append("video", newVideo);
                                    return axiosClient.post(`/demande-videos/`, videoData);
                                });

                                // Wait for all uploads to complete
                                Promise.all([...imageUploadPromises, ...videoUploadPromises])
                                    .then(() => {
                                        // Fetch updated demande data with images and videos
                                        return axiosClient.get(`/demandes/${idDemande}/`);
                                    })
                                    .then((res) => {
                                        console.log('Updated order with images and videos:', res.data);
                                        navigate('/my-requests');  // Navigate to the list page or wherever you want
                                    })
                                    .catch((error) => {
                                        console.error('Error uploading new images or videos:', error);
                                    });
                            })
                            .catch((error) => {
                                console.error('Error fetching existing videos:', error);
                            });
                    })
                    .catch((error) => {
                        console.error('Error fetching existing images:', error);
                    });
            })
            .catch((error) => {
                console.error('Error updating order:', error);
            });
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <h3 className="px-2 my-4">{t("Modifier la commande")}</h3>
            <Card className="container py-2">
                <CardBody className="container px-3 px-sm-5 py-2">
                    <div className="container mt-4">
                        {message && <Alert variant="danger">{message}</Alert>}
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                            <Form.Group className='mb-3' controlId="Title">
                                <Form.Label>{t("Objet")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t("Titre de votre commande")}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="description">
                                <Form.Label>{t("Description")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder={t("Entrez la description ici")}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className='' controlId="imageUpload">
                                <Form.Label>{t("Images")}</Form.Label>
                                <Form.Control
                                    key={inputKey}
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.webp"
                                    multiple
                                    onChange={handleImageChange}
                                />
                            </Form.Group>

                            <div className="container my-2">
                                <Row className="g-3">
                                    {selectedImageList.map((url, index) => (
                                        <Col
                                            key={index}
                                            xs={6} sm={6} md={4} lg={3} // These define the grid size per screen size
                                            className="image-container"
                                            style={{ position: "relative" }}
                                        >
                                            <Image
                                                src={url}
                                                alt={`image-${index}`}
                                                style={{
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                    height: "170px",
                                                    objectFit: "cover"
                                                }}
                                            />
                                            <button
                                            type="button"
                                                className="btn btn-danger"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    position: "absolute",
                                                    top: "8px",
                                                    right: "12px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 0,
                                                    borderRadius: "50%",
                                                    fontSize: "12px",
                                                }}
                                                onClick={() => {
                                                    // Filter out the selected image from both lists
                                                    const updatedSelectedResImagesList = selectedImageList.filter((e) => e !== url);
                                                    const updatedResImagesList = imageList.filter((image) => {
                                                        if (typeof image === 'string') {
                                                            // If it's a string, compare it with the image URL
                                                            return image !== url;
                                                        } else {
                                                            // If it's a file object, compare the object URL
                                                            return URL.createObjectURL(image) !== url;
                                                        }
                                                    });
                                            
                                                    setSelectedImageList(updatedSelectedResImagesList);
                                                    setImageList(updatedResImagesList);
                                                }}
                                               
                                            >
                                                <IoMdClose size={16} />
                                            </button>
                                        </Col>
                                    ))}
                                </Row>
                            </div>


                            <Form.Group className='' controlId="videoUpload">
                                <Form.Label>{t("Vidéos")}</Form.Label>
                                <Form.Control
                                    key={inputKey + 1}
                                    type="file"
                                    accept="video/*"
                                    multiple
                                    onChange={handleVideoChange}
                                />
                            </Form.Group>

                            <div className="container my-2">
                                <Row className="g-3">
                                    {selectedVideoList.map((url, index) => (
                                        <Col
                                            key={index}
                                            xs={12} sm={6} md={6} lg={4} // Define grid sizes for responsiveness
                                            className="video-container"
                                            style={{ position: "relative" }}
                                        >
                                            <video
                                                src={url}
                                                controls
                                                style={{
                                                    borderRadius: "5px",
                                                    background: '#0000000f',
                                                    width: "100%",
                                                    height: "auto"
                                                }}
                                            />
                                            <button
                                                className="btn btn-danger"
                                                style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    position: "absolute",
                                                    top: "8px",
                                                    right: "12px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 0,
                                                    borderRadius: "50%",
                                                    fontSize: "12px",
                                                }}
                                                onClick={() => {
                                                    // Filter out the selected video from both lists
                                                    const updatedSelectedResVideoList = selectedVideoList.filter((e) => e !== url);
                                                    const updatedResVideoList = videoList.filter((video) => {
                                                        if (typeof video === 'string') {
                                                            // If it's a string, compare it with the video URL
                                                            return video !== url;
                                                        } else {
                                                            // If it's a file object, compare the object URL
                                                            return URL.createObjectURL(video) !== url;
                                                        }
                                                    });
                                            
                                                    setSelectedVideoList(updatedSelectedResVideoList);
                                                    setVideoList(updatedResVideoList);
                                                }}
                                                
                                            >
                                                <IoMdClose size={20} />
                                            </button>
                                        </Col>
                                    ))}
                                </Row>
                            </div>

                        </div>
                    </div>

                    <Row className="py-3">
                        <Col className="text-end">
                            <Button size='lg' variant="primary" onClick={() => handleSubmit()} disabled={isLoading}>
                                {isLoading ? <Spinner size="sm" animation="border" /> : t("Enregistrer")}
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default Edit_demande;
