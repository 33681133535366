import { Button } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Alert, Form } from 'react-bootstrap'
import { FiPlus } from "react-icons/fi";
import axiosClient, { link, networkId } from '../../../../../axios-client';
import { useLocation, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
var randomstring = require("randomstring");



function Standard({ type, setType, name, sizeAd }) {

    const { id } = useParams()
    const location = useLocation()
    const { t } = useTranslation()

    const getDataSites = async () => {
        try {
            const res = await axiosClient.get(`get-sites/`);
            console.log('DATATATATA :', res.data.items);
            return res.data.items; // Return the data directly
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    };

    const getDataZones = async (id) => {
        try {
            const res = await axiosClient.get(`get-zones/`);
            console.log('DATATATATA :', res.data.items);
            return res.data; // Return the data directly

        } catch (error) {
            console.log('ERROR :', error);
            throw error;

        }
    }

    const [dataSites, setDataSites] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDataSites();
                setDataSites(data)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures this runs once when the component mounts


    const [dataZones, setDataZones] = useState([])
    const [idSiteSelected, setIdSiteSelected] = useState()
    useEffect(() => {
        const fetchDataZones = async () => {
            try {
                const data = await getDataZones(idSiteSelected)
                setDataZones(data)
            } catch (error) {
                console.log('Error fetching zones data :', error);
            }
        }
        fetchDataZones()
    }, [])

    // AD Management
    const getDataAdTypes = async () => {
        try {
            const res = await axiosClient.get(`get-adTypes/`);
            console.log('DATATATATA :', res.data.items);
            return res.data.items
            // return res.data.items; // Return the data directly
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    }


    const [dataAdTypes, setDataAdTypes] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDataAdTypes();
                console.log('DATA AD TYPEs : ', data);
                setDataAdTypes(data)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const [dataPlacement, setDataPlacement] = useState([
        {
            checkForceTarget: true,
            site: '',
            size: sizeAd ? sizeAd : '',
            zone: '',
            idTag: `azk${randomstring.generate({
                length: 6,
                charset: 'numeric'
            })}`
        }
    ])

    const addToDataPlacement = () => {
        setDataPlacement([...dataPlacement, {
            checkForceTarget: true,
            site: '',
            size: sizeAd ? sizeAd : '',
            zone: '',
            idTag: `azk${randomstring.generate({
                length: 6,
                charset: 'numeric'
            })}`
        }])
    }

    const removeFromDataPlacement = index => {
        const listProp = [...dataPlacement]
        listProp.splice(index, 1)
        setDataPlacement(listProp)
    }

    const handleChangePlacement = (e, index) => {
        const { name, value } = e.target;
        const listProp = [...dataPlacement];
        listProp[index][name] = value;
        setDataPlacement(listProp);
    };

    const handleChangeCheckPlacement = (e, index) => {
        const { name, checked } = e.target;
        const listProp = [...dataPlacement];
        listProp[index][name] = checked;
        setDataPlacement(listProp);
    };

    useEffect(() => {
        console.log('dataPlacementdataPlacementdataPlacement : ', dataPlacement)
    }, [dataPlacement])

    return (
        <div>
            <ToastContainer />
            <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                <strong>
                    {t('Le code publicitaire le plus rapide au monde')}
                </strong><br />
                <span>
                    {t('Notre code publicitaire standard est 100 % asynchrone et charge toutes les balises publicitaires en un seul appel au serveur. Il est compatible avec la plupart des tiers, y compris les fournisseurs de médias enrichis. Il ne fonctionnera pas avec certains fournisseurs de médias enrichis existants. Si vous devez les prendre en charge, utilisez le code publicitaire synchrone.')}
                </span>
            </Alert>

            <Button
                variant="outlined"
                color="primary"
                startIcon={<FiPlus />}
                style={{ width: 'auto', height: 40 }}
                onClick={addToDataPlacement}>
                {t('Ajouter un emplacement')}
            </Button>


            {
                dataPlacement.map((val, key) => {
                    return (
                        <div key={key}>
                            <div style={{
                                marginTop: 40,
                                marginBottom: 40,
                                border: "1px solid gray",
                                borderRadius: 50
                            }} />

                            <div style={{
                                marginTop: 25
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                        <input
                                            name='checkForceTarget'
                                            type='checkbox'
                                            checked={val.checkForceTarget}
                                            style={{
                                                width: 40,
                                                height: 20,
                                            }}
                                            onChange={e => handleChangeCheckPlacement(e, key)}
                                        />
                                        <span>{type !== 'annonce' ? t('Cibler les annonces de force dans') : t("Ciblez la force de l'")} {t(`${type}`)} "{`${name}`}."</span>
                                    </label>
                                </div>
                            </div>

                            <div style={{
                                marginTop: 15
                            }}>

                                <div className='d-flex flex-column flex-md-row w-100 gap-3'>
                                    <Form.Group className="w-100">
                                        <Form.Label>Site</Form.Label>
                                        <Form.Select
                                            name='site'
                                            value={val.site}
                                            style={{
                                                height: 35
                                            }}
                                            onChange={e => {
                                                handleChangePlacement(e, key)
                                                setIdSiteSelected(parseInt(e.target.value))
                                            }}>
                                            <option value=''>-- {t('Choisir')} --</option>
                                            {dataSites.map((val, key) => {
                                                return (
                                                    <option key={key} value={val.Id} title={val.title}>{val.Title}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100">
                                        <Form.Label>{t('Taille')}</Form.Label>
                                        <Form.Select
                                            name='size'
                                            value={sizeAd ? sizeAd : val.size}
                                            style={{
                                                height: 35
                                            }}
                                            disabled={sizeAd}
                                            onChange={e => handleChangePlacement(e, key)}>
                                            <option value=''>-- {t('Choisir')} --</option>
                                            {dataAdTypes.map((val, key) => {
                                                return (
                                                    <option key={key} value={val.Id}>{val.Name} - {val.Width}x{val.Height}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100">
                                        <Form.Label>Zone ({t('facultative')})</Form.Label>
                                        <Form.Select
                                            name='zone'
                                            value={val.zone}
                                            style={{
                                                height: 35
                                            }}
                                            onChange={e => handleChangePlacement(e, key)}>
                                            <option value=''>-- {t('Choisir')} --</option>
                                            {idSiteSelected ?
                                                dataZones.filter(e => e.SiteId === idSiteSelected).map((val, key) => {
                                                    return (
                                                        <option key={key} value={val.Id} title={val.Name}>{val.Name}</option>
                                                    )
                                                })
                                                :
                                                <option disabled>{t("Sélectionnez un site pour voir les zones")}</option>}
                                        </Form.Select>
                                    </Form.Group>
                                </div>

                                {(val.size && val.site) && <div style={{
                                    marginTop: 25
                                }}>
                                    <h3>{t('Étiqueter')}</h3>
                                    <span>{t("Placez-le sur votre page à l'endroit où vous souhaitez que l'annonce s'affiche.")}</span>

                                    <div style={{
                                        minHeight: 40,
                                        background: '#222',
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                    }}>
                                        <span style={{ color: '#00abad', }}>
                                            {`
                                <div id="${val.idTag}"></div>
                            `}
                                        </span>
                                    </div>

                                    <Button variant="outlined" color="primary" style={{ width: 'auto', height: 40, marginTop: 10 }}
                                        onClick={() => {
                                            const tagCode = `<div id="${val.idTag}"></div>`;
                                            // Utilise l'API Clipboard pour copier le texte avec la valeur de la variable
                                            navigator.clipboard.writeText(tagCode).then(() => {
                                                toast.success(t("Copié dans le presse-papiers"), {
                                                    position: "top-center",
                                                    autoClose: 500,
                                                    hideProgressBar: false,
                                                    closeOnClick: false,
                                                    pauseOnHover: false,
                                                    draggable: false,
                                                    progress: undefined,
                                                    theme: "light",
                                                });
                                            }).catch(err => {
                                                console.error('Error copying to clipboard: ', err);
                                            });
                                        }}>
                                        {t("Copier dans le presse-papiers")}
                                    </Button>

                                </div>}

                            </div>
                        </div>
                    )
                })
            }

            <div style={{
                marginTop: 40,
                marginBottom: 40,
                border: "1px solid gray",
                borderRadius: 50
            }} />


            <div style={{
                marginTop: 40
            }}>
                <h3>{t("Ajoutez à la balise d'en-tête de votre page")}</h3>

                <div style={{
                    minHeight: 40,
                    background: '#222',
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: 10,
                    paddingLeft: 10,
                }}>
                    {
                        dataPlacement.filter(e => e.site !== '' && e.size !== '').length > 0
                            ?
                            <pre style={{ color: '#00abad', }}>
                                {`
<script type="text/javascript" src="https://static.adzerk.net/ados.js"></script>
<script type="text/javascript">
var ados = ados || {};
ados.run = ados.run || [];
ados.run.push(function() {${dataPlacement.filter(e => e.site !== '' && e.size !== '').map((val) => (`
    ados_add_placement(${networkId}, ${val.site}, "${val.idTag}", ${val.size})  ${val.zone && `\n    .setZone(${val.zone})`}   ${val.checkForceTarget ? `\n    .${setType}` : ''}`))}    
    ados_load();
});
</script>
                    `}
                            </pre>
                            :
                            <pre style={{ color: '#00abad', }}>
                                {t("Sélectionnez un site et une taille d'annonce pour générer votre code.")}
                            </pre>}
                </div>


                <Button variant="outlined" disabled={dataPlacement.filter(e => e.site !== '' && e.size !== '').length === 0} color="primary" style={{ width: 'auto', height: 40, marginTop: 10 }}
                    onClick={() => {
                        const tagCode = `
                        <script type="text/javascript" src="https://static.adzerk.net/ados.js"></script>
<script type="text/javascript">
var ados = ados || {};
ados.run = ados.run || [];
ados.run.push(function() {${dataPlacement.filter(e => e.site !== '' && e.size !== '').map((val) => (`
    ados_add_placement(${networkId}, ${val.site}, "${val.idTag}", ${val.size})  ${val.zone && `\n    .setZone(${val.zone})`}   ${val.checkForceTarget ? `\n    .setCampaignId(${id})` : ''}`))}    
    ados_load();
});
</script>
`;
                        // Utilise l'API Clipboard pour copier le texte avec la valeur de la variable
                        navigator.clipboard.writeText(tagCode).then(() => {
                            toast.success(t("Copié dans le presse-papiers"), {
                                position: "top-center",
                                autoClose: 500,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });
                        }).catch(err => {
                            console.error('Error copying to clipboard: ', err);
                        });
                    }}>
                    {t("Copier dans le presse-papiers")}
                </Button>

            </div>

        </div>
    )
}

export default Standard