import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, Carousel, Spinner, ProgressBar } from 'react-bootstrap';
import './custom.css';
import { Avatar, AvatarGroup, Divider, Pagination, Stack } from '@mui/material';
import { MdCampaign, MdOutlineRadio, MdPublishedWithChanges } from 'react-icons/md';
import Slider from 'react-infinite-logo-slider'
import axiosClient, { link, pagination_size } from '../../../axios-client';
import { FaUserTie } from "react-icons/fa6";
import { GiMusicalNotes } from "react-icons/gi";
import { FaAd, FaUsers } from "react-icons/fa";
import ApexCharts from 'apexcharts';

import CryptoJS from 'crypto-js';
import { Height, Style } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const CardComponent = ({ bgGradientClass, newCount, oldCount, loading, description, svgPath }) => (
    <div className={`relative p-3 rounded-xl overflow-hidden ${bgGradientClass}`} style={{ height: '100px', borderRadius: "10px", margin: '0 5px' }}>
        <div className="relative z-10 mb-1 text-white" style={{ fontWeight: "bold", fontSize: 25 }}>
            {loading ? <Spinner size='md' style={{ color: '#ffffff66' }} animation="border" /> : oldCount}
            {newCount > 0 && <span style={{ color: "#cbffdc" }}>{`+${newCount}`}</span>}
        </div>
        <div className="relative z-10 text-white" style={{ fontWeight: "600", fontSize: 16 }}>{description}</div>
        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ position: "absolute", right: 0, bottom: 0, height: 140, width: 140, marginRight: -30, marginBottom: -30, opacity: 0.25 }} className="text-white">
            {svgPath}
        </svg>
    </div>
);


function DashAdmin() {

    const [loading, setLoading] = useState(true);
    const { t } = useTranslation()
    const [agencyNewCount, setAgencyNewCount] = useState(0)
    const [agencyOldCount, setAgencyOldCount] = useState(0)
    const [clientsNewCount, setClientsNewCount] = useState(0)
    const [clientsOldCount, setClientsOldCount] = useState(0)

    const [countTreated, setCountTreated] = useState(0)
    const [countInProgress, setCountInProgress] = useState(0)
    const [countUntreated, setCountUntreated] = useState(0)

    // const [campaignNewCount, setCampaignNewCount] = useState(0)
    const [campaignOldCount, setCampaignOldCount] = useState(0)

    // const [flightNewCount, setFlightNewCount] = useState(0)
    const [flightOldCount, setFlightOldCount] = useState(0)
    const [adsCount, setAdsCount] = useState(0)
    const [adsList, setAdsList] = useState([])
    useEffect(() => {
        setLoading(true);  // Start loading at the beginning

        // Fetch count_agency data
        axiosClient.get('count_agency/')
            .then((response) => {
                setAgencyNewCount(response.data.new_count);
                setAgencyOldCount(response.data.old_count);
            })
            .catch((error) => {
                console.log('Error fetching agency data:', error);
                setLoading(false);
            });

        axiosClient.get('count_clients/')
            .then((response) => {
                setClientsNewCount(response.data.new_count);
                setClientsOldCount(response.data.old_count);
            })
            .catch((error) => {
                console.log('Error fetching agency data:', error);
                setLoading(false);
            });    

        // Fetch count_campaign data
        axiosClient.get('count_campaign/')
            .then((response) => {
                setCampaignOldCount(response.data.total_count);
            })
            .catch((error) => {
                console.log('Error fetching campaign data:', error);
                setLoading(false);
            });

        // Fetch count_flights data
        axiosClient.get('count_flights/')
            .then((response) => {
                setFlightOldCount(response.data.total_count);
                setAdsCount(response.data.total_creatives);
                setAdsList(response.data.ads);
            })
            .catch((error) => {
                console.log('Error fetching flights data:', error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    
            
        axiosClient.get('count_commandes/')
            .then((response) => {
                setCountInProgress(response.data.count_in_progress);
                setCountTreated(response.data.count_treated);
                setCountUntreated(response.data.count_untreated);
            })
            .catch((error) => {
                console.log('Error fetching commande data:', error);
                setLoading(false);
            });
    }, []);


    // useEffect(() => {
    //     axiosClient.get('count_agency/')
    //         .then((response) => {
    //             setAgencyNewCount(response.data.new_count);
    //             setAgencyOldCount(response.data.old_count);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });

    //     axiosClient.get('count_campaign/')
    //         .then((response) => {
    //             setCampaignNewCount(response.data.new_count);
    //             setCampaignOldCount(response.data.old_count);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    //     axiosClient.get('count_flights/')
    //         .then((response) => {
    //             setFlightNewCount(response.data.new_count);
    //             setFlightOldCount(response.data.old_count);
    //             setAdsCount(response.data.total_creatives);
    //             setAdsList(response.data.ads);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });

    // }, [])
    const [chartData, setChartData] = useState({
        series: [],
        options: {}
    });


    useEffect(() => {
        // Fetch advertisers data
        axiosClient.get('top_advertisers_stats/')
            .then((response) => {
                const data = response.data;

                // Extract names, impressions, and clicks
                const names = data.map(item => item.name);
                const impressions = data.map(item => item.impressions);
                const clicks = data.map(item => item.clicks);

                // Set the chart options
                const options = {
                    series: [
                        {
                            name: 'Impressions',
                            data: impressions,
                        },
                        {
                            name: t('Clics'),
                            data: clicks,
                        }
                    ],
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: { enabled: false },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    title: {
                        text: t('Top Annonceurs'),
                        align: 'left',

                    },
                    xaxis: {
                        categories: names,
                    },
                    yaxis: [
                        {
                            title: { text: 'Impressions' },
                        },
                        {
                            opposite: true,
                            title: { text: 'Clics' }
                        }
                    ],
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val;
                            }
                        }
                    }
                };

                // Render the chart
                var chartOrigin = document.querySelector('#chart');
                if (chartOrigin) {
                    var chart = new ApexCharts(document.querySelector('#chart'), options);
                    chart.render();
                }
                // const chart = new ApexCharts(document.querySelector("#chart"), options);
                // chart.render();
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);



    return (
        <div>
            <div className="py-3 d-flex align-items-center justify-content-center">
                <div className="px-2 w-100">
                    <Row  className="g-3 g-md-1 flex-fill">
                    
                        <Col className='flex-fill' xs={12} sm={6} md={4}  xl={2}>
                            <CardComponent
                                bgGradientClass="bg-gradient-teal-green"
                                newCount={agencyNewCount}
                                oldCount={agencyOldCount}
                                description={t("Annonceurs")}
                                svgPath={<FaUserTie size={22} />}
                                loading={loading}
                            />
                        </Col>
                        <Col className='flex-fill' xs={12} sm={6} md={4}  xl={2}>
                            <CardComponent
                                bgGradientClass="bg-gradient-purple"
                                newCount={clientsNewCount}
                                oldCount={clientsOldCount}
                                description={t("Clients")}
                                svgPath={<FaUsers  size={22} />}
                                loading={loading}
                            />
                        </Col>
                        <Col className='flex-fill' xs={12} sm={6} md={4}  xl={2}>
                            <CardComponent
                                bgGradientClass="bg-gradient-blue"
                                oldCount={campaignOldCount}
                                description={t("Campagnes")}
                                svgPath={<MdCampaign size={22} />}
                                loading={loading}
                            />
                        </Col>
                        <Col className='flex-fill' xs={12} sm={6} md={4}  xl={2}>
                            <CardComponent
                                bgGradientClass="bg-gradient-red"
                                oldCount={flightOldCount}
                                description="Flights"
                                svgPath={<MdPublishedWithChanges size={22} />}
                                loading={loading}
                            />
                        </Col>
                        <Col className='flex-fill' xs={12} sm={6} md={4}  xl={2}>
                            <CardComponent
                                bgGradientClass="bg-gradient-yellow"
                                oldCount={adsCount}
                                description={t("Totale d'annonces")}
                                svgPath={<FaAd size={22} />}
                                loading={loading}
                            />
                        </Col>
                       
                    </Row>
                </div>
            </div>

            <div style={{ borderRadius: 10, background: "#ffffffb8", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }} className="p-3 mx-3 my-2">
                <div className="row align-items-center">
                    {/* Left side title */}
                    <div className="col-12 col-md-4">
                        <h4 className="mb-3 mb-md-0" >{t("Nombre des commandes")}</h4>
                    </div>

                    {/* Right side with three columns */}
                    <div className="col-12 col-md-8 d-flex flex-wrap justify-content-between mt-2 mt-md-3 gap-2 ">
                        {/* Column 1 */}
                        <div className="text-center flex-fill mb-md-0">
                            <h6 className="text-uppercase" style={{ fontWeight: "bold", fontSize: "11px", color: "#a1a1a1" }}>Commandes traitées</h6>
                            <p style={{ fontSize: 25, fontWeight: "bold" }}>{countTreated}</p>
                        </div>
                        {/* <Divider className='d-none d-md-flex' style={{borderColor:'#d7d7d7'}} orientation="vertical" flexItem /> */}
                        {/* Column 2 */}
                        <div className="text-center flex-fill mb-md-0">
                            <h6 className="text-uppercase" style={{ fontWeight: "bold", fontSize: "11px", color: "#a1a1a1" }}>Commandes en cours</h6>
                            <p style={{ fontSize: 25, fontWeight: "bold" }}>{countInProgress}</p>
                        </div>
                        {/* <Divider className='d-none d-md-flex' style={{borderColor:'#d7d7d7'}} orientation="vertical" flexItem /> */}
                        {/* Column 3 */}
                        <div className="text-center flex-fill">
                            <h6 className="text-uppercase" style={{ fontWeight: "bold", fontSize: "11px", color: "#a1a1a1" }}>Commandes non traitées</h6>
                            <p style={{ fontSize: 25, fontWeight: "bold" }}>{countUntreated}</p>
                        </div>
                    </div>
                </div>
            </div>



            <Row style={{ padding: 0, margin: 0 }} >
                <div className='px-3 pt-4 col-xl-8 col-12 '  >
                    <Card className='p-0 bg-white' style={{  borderRadius: '10px', height: "100%" ,boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                        <Card.Body className='p-0 pt-4 bg-white' style={{ borderRadius: 10 }}>

                            <div  className=' mx-3 '>
                                <div id="chart"></div>
                            </div>

                        </Card.Body>
                    </Card>
                </div>
                <div className=' px-3 pt-4 col-xl-4 col-12'>
                    <Card className='p-0 bg-white' style={{ borderRadius: '10px', height: "100%" ,boxShadow: "0 4px 8px rgba(0,0,0,0.1)"}}>
                        <Card.Body>
                            <Card.Title className='py-2 '>{t("Aperçu des annonces")}</Card.Title>

                            <Carousel indicators={false}>
                                {adsList.map((row) => (
                                    <Carousel.Item interval={3000}>
                                        <img
                                            style={{ height: "300px", objectFit: "cover", }}
                                            className="d-block  w-100"
                                            src={row}
                                            loading="lazy"
                                        />
                                    </Carousel.Item>
                                ))}

                            </Carousel>
                        </Card.Body>
                    </Card>
                </div>
            </Row>

        </div>


    )
}

export default DashAdmin