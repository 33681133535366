import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import axiosClient, { idSiteDAB, idTemplate, link, percentageDifferenceRevenueBudget } from '../../../../axios-client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Table } from 'reactstrap';
import DataCountries from './DataCountries';
import DataTimeZone from './DataTimeZone';
import moment from 'moment-timezone';
import { TablePagination, TextField, Tooltip } from '@mui/material';
import { useStateContext } from '../../../../context/ContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { useTranslation } from 'react-i18next';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { LuAlertCircle } from 'react-icons/lu';
import UpgradeBudget from './UpgradeBudget';


const styles = `
.custom-modal-size {
  max-width: 650px; 
}
`


function AddFlight() {

    const navigate = useNavigate();
    const location = useLocation();

    const { user } = useStateContext()
    const { id } = useParams();

    // Function to get query parameters
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };
    const query = getQueryParams(location.search);
    const r = query.get('r');
    const c = query.get('c');

    const { t } = useTranslation()

    const stripe = useStripe()
    const elements = useElements()

    const [amount, setAmount] = useState()

    const [error, setError] = useState({})
    const amountRef = useRef(null)

    const [oldBudget, setOldBudget] = useState()
    const [newBudget, setNewBudget] = useState()

    const [addAdModal, setAddAdModal] = useState(false);

    const handleCloseAddAdModal = () => {
        setAddAdModal(false)
        setCreativeMapsFormData({
            IsActive: true,
            Body: '',
        })
        setTemplateSelected(null)
        setFormat('Image')
        setErrorsAd({})
        setLinkAsset()
        setDureeAsset()
    };
    const handleShowAddAdModal = () => setAddAdModal(true);

    const [updateAddModal, setUpdateAddModal] = useState(false);

    const handleCloseUpdateAddModal = () => {
        setUpdateAddModal(false)
        setCreativeMapsFormData({
            IsActive: true,
            Body: '',
        })
        setKeyAdSelected()
        setErrorsAd({})
        setTemplateSelected(null)
        setFormat('Image')
        setLinkAsset()
        setDureeAsset()
    };
    const handleShowUpdateAddModal = () => {
        setUpdateAddModal(true)
    };

    const [deleteAdModal, setDeleteAdModal] = useState(false)

    const handleCloseDeleteAdModal = () => {
        setDeleteAdModal(false)
        setKeyAdSelected()
    };
    const handleShowDeleteAdModal = () => {
        setDeleteAdModal(true)
    };

    const [keyAdSelected, setKeyAdSelected] = useState()
    const [idAdSelected, setIdAdSelected] = useState()

    const [loading, setLoading] = useState()
    const [globalLoading, setGlobalLoading] = useState()
    const [loadingAds, setLoadingAds] = useState()

    // Variables
    const [flightFormData, setFlightFormData] = useState({
        IsActive: true,
        CampaignId: c,
        // PriorityId:'',
        RateType: 2,
        TimeZone: 'UTC',
        StartDate: new Date().toISOString().slice(0, 16),
        EndDate: null,
        EndDateISO: null,
        IsTrackingConversions: false,
        GoalType: 2,
        Impressions: 100,
        CapType: 4,
        Price: 10,
        // SiteZoneTargeting: [{
        //     // SiteId: 1285093,
        //     // ZoneId: 322038,
        //     // Name: 'DAB+',
        // }]
    })

    const [dataPriorities, setDataPriorities] = useState([])

    const [errors, setErrors] = useState({})
    const refAttributs = useRef({
        NameRef: null,
        PriorityIdRef: null,
        ImpressionsRef: null,
        EndDateRef: null,
        DailyCapAmountRef: null,
        LifetimeCapAmountRef: null,
        SiteZoneTargetingRef: null,
        GeoTargetingRef: null,
        PriceRef: null,
        amountRef: null
    })

    const Id = id ? id : 0


    const [siteTargetingFormData, setSiteTargetingFormData] = useState({
        IsExclude: false,
    })
    const [dataSiteZoneTargeting, setDataSiteZoneTargeting] = useState([])

    const [geoTargetingFormData, setGeoTargetingFormData] = useState({
        IsExclude: false,
    })
    const [dataGeoTargeting, setDataGeoTargeting] = useState([])

    const [format, setFormat] = useState("Image")

    const [dataTemplates, setDataTemplates] = useState([])
    const [templateSelected, setTemplateSelected] = useState(null)
    useEffect(() => {
        if (format === 'Video') {
            try {
                axiosClient.get(`get-templates/`).then(res => {
                    console.log('TEMPLATES : ', res.data);
                    setDataTemplates(res.data.items)
                })
            } catch (error) {
                console.log('ERROR : ', error);

            }
        }
    }, [format])

    //-------------------------------------------------------------------------------------------------------------------
    const [assetFormData, setAssetFormData] = useState()
    const [linkAsset, setLinkAsset] = useState()
    const [dureeAsset, setDureeAsset] = useState()
    const [uploadVideoLoading, setuploadVideoLoading] = useState()
    const addAsset = async () => {
        try {
            setuploadVideoLoading(true)
            let dataImagesAdded;
            const formData = new FormData()
            formData.append('video', assetFormData.video);
            formData.append('Ad', 0);
            try {
                const response = await axiosClient.post(`upload-video/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                dataImagesAdded = response.data;
                console.log(response.data);
                setLinkAsset(response.data.video_url)
                setDureeAsset(response.data.video_duration)

                setCreativeMapsFormData({
                    ...creativeMapsFormData,
                    TemplateValues: {
                        ...creativeMapsFormData?.TemplateValues,
                        // 'ctPlayback_URL' : linkAsset,
                        ctPlayback_URL: response.data.video_url,
                        ctduration: response.data.video_duration,
                    },
                });
                setuploadVideoLoading(false)

            } catch (error) {
                setuploadVideoLoading(false)
                console.error('Error uploading image:', error.response ? error.response.data : error.message);
                throw error; // Rethrow error to stop execution and handle it in the calling function
            }
        } catch (error) {
            console.log('ERROR : ', error);

        }
    }
    //-------------------------------------------------------------------------------------------------------------------

    const [creativeMapsFormData, setCreativeMapsFormData] = useState({
        IsActive: true,
        Body: '',
    })
    const [dataCreativeMaps, setDataCreativeMaps] = useState([])
    const [dataFiles, setDataFiles] = useState([])


    useEffect(() => {
        console.log('Updated FormData:', flightFormData);
        console.log('Updated FormData: siteTargetingFormData ', siteTargetingFormData);
        console.log('Updated FormData: dataSiteZoneTargeting ', dataSiteZoneTargeting);
        console.log('Updated FormData: dataGeoTargeting ', dataGeoTargeting);
        console.log('Updated FormData: creativeMapsFormData ', creativeMapsFormData);
        console.log('Updated FormData: dataCreativeMaps ', dataCreativeMaps);
        setDataFiles(dataCreativeMaps)
    }, [flightFormData, siteTargetingFormData, dataSiteZoneTargeting, creativeMapsFormData, dataCreativeMaps]); // This will log the updated state whenever it changes

    ////////////////////////////////the size of the table//////////////////////////////
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////
    // Fonction pour parser la chaîne
    function parseDataString(str) {
        const result = [];
        let currentOperator = '';
        let currentKeyword = '';

        // Parcours de chaque caractère de la chaîne
        for (let i = 0; i < str.length; i++) {
            const char = str[i];

            // Si le caractère est un opérateur (on considère que tout sauf une lettre est un opérateur)
            if (char === '!' || char === ',' || (char === '\n')) {
                // Si un mot-clé a été collecté avant, on le stocke
                if (currentKeyword) {
                    result.push({ operator: currentOperator, keyword: currentKeyword });
                    currentKeyword = ''; // Réinitialiser le mot-clé
                }

                // Gestion du caractère spécial '\n' (vérifier que c'est bien "\\n")
                if (char === "\n") {
                    currentOperator = '\n'; // Retour à la ligne dans l'opérateur
                    i++; // Sauter le 'n'
                } else {
                    currentOperator = char;
                }
            } else {
                // Ajout des caractères au mot-clé en cours
                currentKeyword += char;
            }
        }

        // Ajout du dernier mot-clé et opérateur
        if (currentKeyword) {
            result.push({ operator: currentOperator, keyword: currentKeyword });
        }

        return result;
    }



    // Gestion Zone DAB+ : Radio

    const [siteIdSelected, setSiteIdSelected] = useState(idSiteDAB)
    const [dataZoneDAB, setDataZoneDAB] = useState([{
        SiteId: siteIdSelected,
        ZoneId: ''
    }])

    const addDataZoneDAB = () => {
        setDataZoneDAB([...dataZoneDAB, {
            SiteId: siteIdSelected,
            ZoneId: ''
        }])
    }

    const removeDataZoneDAB = (index) => {
        const listRadio = [...dataZoneDAB]
        listRadio.splice(index, 1)
        setDataZoneDAB(listRadio)
    }

    const handleChangeSelectRadio = (e, index) => {
        const { name, value } = e.target
        console.log('dataZoneDAB TARGET : ', e);

        if (dataZoneDAB.find(e => parseInt(e.ZoneId) === parseInt(value))) {
            toast.error(t("Cette zone existe déjà!"), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            setDataZoneDAB([...dataZoneDAB])
            return;
        } else {
            const listRadio = [...dataZoneDAB]
            listRadio[index][name] = parseInt(value)
            setDataZoneDAB(listRadio)
        }
    }

    useEffect(() => {
        console.log('dataZoneDAB : ', dataZoneDAB);

        setFlightFormData({
            ...flightFormData,
            SiteZoneTargeting: dataZoneDAB
        })

    }, [dataZoneDAB])

    //KEYWORDS
    const [dataKeywords, setDataKeywords] = useState([
        { operator: '', keyword: '' }
    ])

    const addKeywords = () => {
        setDataKeywords([
            ...dataKeywords,
            { operator: '', keyword: '' },
        ]);
    };

    const removeKeywords = (index) => {
        const listProp = [...dataKeywords];
        listProp.splice(index, 1);
        setDataKeywords(listProp);
    };

    const handleChangeKeywords = (e, index) => {
        const { name, value } = e.target;
        const listProp = [...dataKeywords];
        listProp[index][name] = value;
        setDataKeywords(listProp);
    };

    useEffect(() => {
        console.log('dataKeywordsdataKeywords : ', dataKeywords);
        const result = dataKeywords.map((item, index) => {
            // Si c'est le premier élément et que l'opérateur est 'And', on ne met pas l'opérateur
            if (index === 0 && (item.operator === ',' || item.operator === '\n')) {
                return item.keyword;
            }
            // Sinon, on concatène l'opérateur et le mot-clé
            return item.operator + item.keyword;
        }).join(' ');
        console.log('dataKeywordsdataKeywords : RSL', result);

        // Mettre à jour les données du formulaire
        setFlightFormData({
            ...flightFormData,
            'Keywords': result
        });

        console.log(result);


    }, [dataKeywords])

    // const rsl = parseDataString(result);
    // console.log(rsl);

    // const [dataSection, setDataSection] = useState()
    // const [dataCustomTargeting, setDataCustomTargeting] = useState([
    //     { label: '', operator: '', value: '' }
    // ])

    // const addCustomTargeting = () => {
    //     setDataCustomTargeting([
    //         ...dataCustomTargeting,
    //         { logicOperator: 'and', label: '', operator: '', value: '' },
    //     ]);
    // };

    // const removeCustomTargeting = (index) => {
    //     const listProp = [...dataCustomTargeting];
    //     listProp.splice(index, 1);
    //     setDataCustomTargeting(listProp);
    // };

    // const handleChangeCustomTargeting = (e, index) => {
    //     const { name, value } = e.target;
    //     const listProp = [...dataCustomTargeting];
    //     listProp[index][name] = value;
    //     setDataCustomTargeting(listProp);
    // };



    // function parseDataString(dataString) {
    //     const result = [];
    //     const groupRegex = /\((.*?)\)/g; // Capturer les groupes entre parenthèses
    //     let match;
    //     let previousLogicOperator = null;
    //     let hasLeadingLogicOperator = false;

    //     // Vérifier si la chaîne commence par un opérateur global sans conditions précédentes
    //     const leadingOperatorMatch = dataString.match(/^(and|or)/);
    //     if (leadingOperatorMatch) {
    //         hasLeadingLogicOperator = true; // Indiquer qu'il y a un opérateur en début de chaîne
    //     }

    //     // Traiter chaque groupe de conditions entre parenthèses
    //     while ((match = groupRegex.exec(dataString)) !== null) {
    //         const group = match[1].trim();  // Le contenu à l'intérieur des parenthèses
    //         const conditions = group.split(/ (and|or) /); // Diviser selon les opérateurs globaux

    //         let currentGroup = [];

    //         // Parcourir chaque condition dans le groupe
    //         for (let i = 0; i < conditions.length; i++) {
    //             const condition = conditions[i];

    //             if (condition.toLowerCase() === 'and' || condition.toLowerCase() === 'or') {
    //                 continue; // Ignorer les opérateurs globaux, on les ajoutera plus tard
    //             }

    //             // Extraire le label, l'opérateur et la valeur
    //             const [label, operator, ...valueParts] = condition.split(' ');
    //             const value = valueParts.join(' ');

    //             const conditionObject = {
    //                 label: label,
    //                 operator: operator,
    //                 value: value
    //             };

    //             // Ajouter l'opérateur global si c'est une condition non initiale dans le groupe
    //             if (i > 0 && (conditions[i - 1].toLowerCase() === 'and' || conditions[i - 1].toLowerCase() === 'or')) {
    //                 conditionObject.operatorGlobal = conditions[i - 1];
    //             }

    //             currentGroup.push(conditionObject);
    //         }

    //         // Ajouter ce groupe de conditions au résultat
    //         result.push(...currentGroup);

    //         // Vérifier l'opérateur logique entre les groupes
    //         const remainingString = dataString.slice(groupRegex.lastIndex).trim();
    //         const logicOperatorMatch = remainingString.match(/^(and|or)/);

    //         if (logicOperatorMatch) {
    //             previousLogicOperator = logicOperatorMatch[0];
    //             groupRegex.lastIndex += logicOperatorMatch[0].length; // Avancer l'index du RegEx
    //         } else {
    //             previousLogicOperator = null;
    //         }

    //         // Ajouter l'opérateur logique au premier élément du groupe suivant s'il y en a un
    //         if (previousLogicOperator && result.length > 0) {
    //             result[result.length - currentGroup.length].logicOperator = previousLogicOperator;
    //         }
    //     }

    //     // Si un opérateur logique est en début de chaîne, l'ignorer
    //     if (hasLeadingLogicOperator && result.length > 0) {
    //         delete result[0].logicOperator;
    //     }

    //     return result;
    // }




    // function convertToDataString(data) {
    //     let conditions = [];
    //     let currentGroup = [];

    //     data.forEach((item, index) => {
    //         let condition = `${item.label} ${item.operator} ${item.value}`;

    //         if (item.operatorGlobal) {
    //             // Si un operatorGlobal est trouvé, terminer le groupe actuel
    //             if (currentGroup.length > 0) {
    //                 conditions.push(`(${currentGroup.join(' ')})`);
    //                 currentGroup = [];
    //             }
    //             // Ajouter l'operatorGlobal avec le groupe suivant
    //             conditions.push(item.operatorGlobal);
    //         }

    //         // Ajouter les conditions avec logicOperator s'il existe
    //         if (item.logicOperator && currentGroup.length > 0) {
    //             currentGroup.push(`${item.logicOperator} ${condition}`);
    //         } else {
    //             currentGroup.push(condition);
    //         }
    //     });

    //     // Ajouter le dernier groupe de conditions s'il existe
    //     if (currentGroup.length > 0) {
    //         conditions.push(`(${currentGroup.join(' ')})`);
    //     }

    //     return conditions.join(' ');
    // }


    // useEffect(() => {
    //     console.log('dataCustomTargetingdataCustomTargeting : ', dataCustomTargeting);
    //     // function convertToDataString(data) {
    //     //     return data.map((item, index) => {
    //     //         let condition = `${item.label} ${item.operator} ${item.value}`;

    //     //         // Ajouter le `logicOperator` avec des espaces seulement si ce n'est pas le premier élément
    //     //         if (index > 0 && item.logicOperator) {
    //     //             condition = `${item.logicOperator} ${condition}`;
    //     //         }

    //     //         return condition;
    //     //     }).join(' ');
    //     // }

    //     // // Exemple d'utilisation
    //     // const dataString = convertToDataString(dataCustomTargeting);

    //     // setFlightFormData({
    //     //     ...flightFormData,
    //     //     'CustomTargeting': dataString
    //     // })
    //     // console.log(dataString);


    //     // Exemple d'utilisation
    //     const dataString = convertToDataString(dataCustomTargeting);
    //     console.log('    : ', dataString);

    //     setDataSection({ ...dataSection, dataString });

    // }, [dataCustomTargeting])


    // const adddataSection = (opp) => {

    //     setDataCustomTargeting([
    //         ...dataCustomTargeting,
    //         { operatorGlobal: 'or', label: '', operator: '', value: '' },
    //     ]);
    // };

    const [myDataSection, setMyDataSection] = useState([
        {
            operatorGlobal: '',
            section: [
                { logicOperator: '', label: '', operator: '', value: '' }
            ]
        }
    ])

    const addGlobalSection = () => {
        setMyDataSection([
            ...myDataSection,
            {
                operatorGlobal: 'or',
                section: [
                    { logicOperator: '', label: '', operator: '', value: '' }
                ]
            }
        ])
    }

    const removeGlobalSection = (index) => {
        const listProp = [...myDataSection];
        listProp.splice(index, 1);
        setMyDataSection(listProp);
    }

    const addSection = (index) => {
        setMyDataSection(myDataSection.map((val, key) => {
            if (index === key) {
                return {
                    ...val,
                    section: [
                        ...val.section,
                        { logicOperator: 'and', label: '', operator: '', value: '' }
                    ]
                };
            }
            // Retourner la section inchangée si ce n'est pas l'index ciblé
            return val;
        }));
    };

    const removeSection = (i, k) => {
        setMyDataSection(myDataSection.map((val, key) => {
            if (i === key) {
                return {
                    ...val,
                    section: val.section.filter((_, index) => index !== k)
                };
            }
            // Retourner la section inchangée si ce n'est pas l'index ciblé
            return val;
        }));
    };

    const handleInputChangeGlobalSection = (e, index) => {
        const { name, value } = e.target;
        const listProp = [...myDataSection];
        listProp[index][name] = value;
        setMyDataSection(listProp);
    };

    const handleInputChangeSection = (e, key, k = null) => {
        const { name, value } = e.target;
        setMyDataSection(prev =>
            prev.map((val, i) => {
                if (i === key) {
                    if (k !== null) {
                        // Mise à jour des sous-sections
                        const updatedSubSection = {
                            ...val.section[k],
                            [name]: value
                        };

                        // Si le label est différent de "age", définir l'opérateur sur "="
                        if (name === "label" && value !== "age") {
                            updatedSubSection.operator = "=";
                        }

                        return {
                            ...val,
                            section: val.section.map((subVal, j) =>
                                j === k ? updatedSubSection : subVal
                            )
                        };
                    }
                }
                return val;
            })
        );
    };


    const formatData = (data) => {
        return data.map((sectionData, index) => {
            // Concaténer les sous-sections avec leurs opérateurs logiques
            const sectionString = sectionData.section
                .filter(subSection => subSection.value !== null && subSection.value !== '') // Vérifier si la valeur est présente
                .map((subSection, subIndex) => {
                    const value = isNaN(subSection.value) ? `"${subSection.value}"` : subSection.value; // Mettre des guillemets pour les chaînes
                    return `${subSection.logicOperator ? subSection.logicOperator + ' ' : ''}${subSection.label} ${subSection.operator} ${value}`;
                }).join(' ');

            // Si la section est vide après le filtre, ne rien retourner
            if (!sectionString) return '';

            // Si c'est le premier élément, ne pas ajouter d'opérateur global au début
            if (index === 0) {
                return `(${sectionString})`;
            } else {
                return `${sectionData.operatorGlobal ? sectionData.operatorGlobal + ' ' : ''}(${sectionString})`;
            }
        }).filter(section => section !== '').join(' '); // Filtrer les sections vides
    };

    const [formattedStringCustomTargeting, setFormattedStringCustomTargeting] = useState()
    useEffect(() => {
        console.log('myDataSectionmyDataSection : ', myDataSection);

        const formattedString = formatData(myDataSection);
        setFormattedStringCustomTargeting(formattedString)
        setFlightFormData({
            ...flightFormData,
            'CustomTargeting': formattedString
        })
        console.log('myDataSectionmyDataSection : RSLT', formattedString);

    }, [myDataSection])

    const parseStringToData = (input) => {
        // Nettoyer la chaîne pour éviter les erreurs de parsing
        const cleanInput = input.replace(/\s+/g, ' ').trim();

        // Regex pour extraire les sections et les opérateurs globaux
        const sectionRegex = /\(([^)]+)\)/g;
        const globalOperatorRegex = /\)\s*(\w+)\s*\(/g;

        let match;
        const result = [];
        let lastIndex = 0;

        // Extraire toutes les sections
        while ((match = sectionRegex.exec(cleanInput)) !== null) {
            const sectionString = match[1].trim();
            const sectionData = [];

            // Diviser les sous-sections
            const subSectionRegex = /(\w+)\s*(<>|<=|>=|!=|=|>|<|contains|like)\s*(?:\"([^\"]*)\"|([^\s()]+))/g;
            let subMatch;
            while ((subMatch = subSectionRegex.exec(sectionString)) !== null) {
                const [, label, operator, stringValue, numberValue] = subMatch;
                sectionData.push({
                    logicOperator: subMatch.index > 0 && subSectionRegex.lastIndex > 0 ? 'and' : '',
                    label,
                    operator,
                    value: stringValue !== undefined ? stringValue : numberValue
                });
            }

            // Extraire l'opérateur global qui suit la section actuelle
            const globalOperatorMatch = globalOperatorRegex.exec(cleanInput.substring(sectionRegex.lastIndex));
            const globalOperator = globalOperatorMatch ? globalOperatorMatch[1] : '';

            result.push({
                operatorGlobal: globalOperator,
                section: sectionData
            });

            lastIndex = sectionRegex.lastIndex;
        }

        // Assurer que le premier élément n'a pas d'opérateur global
        if (result.length > 0) {
            result[0].operatorGlobal = '';
        }

        // Corriger les opérateurs globaux pour les sections suivantes
        for (let i = 1; i < result.length; i++) {
            const prevSectionEnd = sectionRegex.lastIndex - result[i].section.join(' ').length;
            const nextOperatorMatch = globalOperatorRegex.exec(cleanInput.substring(prevSectionEnd));
            result[i].operatorGlobal = nextOperatorMatch ? nextOperatorMatch[1] : '';
        }

        return result;
    };





    const getDataFlight = async () => {
        setGlobalLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axiosClient.get(`get-flight-with-id/${Id}/`).then(res => {
                    console.log('RES.DATA ! ', res.data);

                    setFlightFormData(res.data)
                    setOldBudget(res.data.LifetimeCapAmount)
                    setSiteIdSelected(res.data.SiteZoneTargeting[0].SiteId)

                    // const rsl = parseStringToData("(age <> 25)");
                    // console.log('res.data.keywordsres.data.keywords : ', rsl);
                    // setMyDataSection(rsl)

                    if (res.data.SiteZoneTargeting[0].SiteId === idSiteDAB) {
                        setDataZoneDAB(res.data.SiteZoneTargeting)
                    } else {
                        setDataZoneDAB([])
                    }

                    if (res.data.Keywords.length !== 0) {
                        console.log('res.data.Keywordsres.data.Keywords : ', res.data.Keywords);

                        const rsl = parseDataString(res.data.Keywords);
                        setDataKeywords(rsl)
                    } else {
                        setDataKeywords([
                            { operator: '', keyword: '' }
                        ])
                    }
                    console.log('res.data.CustomTargeting : ', res.data);

                    if (res.data.CustomTargeting !== null) {
                        console.log('res.data.CustomTargetingres.data.CustomTargeting : ', res.data.CustomTargeting);

                        const parsedData = parseStringToData(res.data.CustomTargeting);
                        console.log('res.data.CustomTargetingres.data.CustomTargeting : RSL', parsedData);
                        setMyDataSection(parsedData)
                    } else {
                        setMyDataSection([
                            {
                                operatorGlobal: '',
                                section: [
                                    { logicOperator: '', label: '', operator: '', value: '' }
                                ]
                            }
                        ])
                    }

                })
                setGlobalLoading(false)
            } catch (error) {
                setGlobalLoading(false)
            }
        }
    }

    const getDataAds = async () => {
        setLoadingAds(true)
        try {
            await axiosClient.get(`get-Ads/${Id}/?page=${page + 1}&page_size=${pageSize}`).then(res => {
                console.log('get-Ads : ', res.data);
                setDataCreativeMaps(res?.data || [])
                // setTotalPages(Math.ceil(res.data.count / pageSize));
                setTotalPages(res?.data[0]?.totalPages);
                setLoadingAds(false)
            })
        } catch (error) {
            console.log('ERROR : ', error);
            setLoadingAds(false)
        }
    }

    const getDataSiteZoneFlight = async () => {
        // setLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axiosClient.get(`get-site-flight/${Id}/`).then(res => {
                    setDataSiteZoneTargeting(res.data || [])
                })
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const getDataGeoTargetingFlight = async () => {
        // setLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axiosClient.get(`get-geoTargeting-flight/${Id}/`).then(res => {
                    setDataGeoTargeting(res.data || [])
                })
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const getPriorities = async () => {
        try {
            await axiosClient.get(`get-priorities/`).then(res => {
                console.log('DATA PRIORITIES : ', res.data.items);
                setDataPriorities(res.data.items)
            })
        } catch (error) {
            setLoading(false)

        }
    }


    useEffect(() => {
        if (parseInt(Id) !== 0) {
            getDataAds()
        }
    }, [page, pageSize])

    //Change Date By TimeZone
    const InvalideDate = 'Invalid date'
    const handleTimeZoneChange = (e) => {
        const selectedTimeZone = e.target.value;

        const startDate = moment.tz(flightFormData.StartDateISO, flightFormData.TimeZone || moment.tz.guess())
            .tz(selectedTimeZone)
            .format('YYYY-MM-DDTHH:mm:ss');

        const endDate = moment.tz(flightFormData.EndDateISO, flightFormData.TimeZone || moment.tz.guess())
            .tz(selectedTimeZone)
            .format('YYYY-MM-DDTHH:mm:ss');

        console.log('Start Date : ', startDate);
        console.log('Date END : ', endDate !== InvalideDate ? endDate : 'null');


        setFlightFormData({
            ...flightFormData,
            'TimeZone': selectedTimeZone,
            'StartDate': startDate,
            'StartDateISO': startDate,
            'EndDate': endDate !== InvalideDate ? endDate : null,
            'EndDateISO': endDate !== InvalideDate ? endDate : null
        });
    };

    const [loadingAdd, setLoadingAdd] = useState(false)

    const [confirmFlight, setConfirmFlight] = useState(false)

    const handleCloseConfirmFlight = () => {
        setConfirmFlight(false)
    };
    const handleShowConfirmFlight = () => setConfirmFlight(true);

    useEffect(() => {
        console.log('ERROROROROR : ', errors);

    }, [errors])
    const AddEditFlight = async () => {
        setErrors({})
        setError({})

        let newErrors = { ...errors };
        let firstErrorRef = null;

        let newErrorsAd = { ...errorsAd };
        let firstErrorAdRef = null;

        let errorAmount = { ...error };
        let AmountErrorRef = null;

        if (!amount || amount === '') {
            newErrors.Amount = t("Ce champ est obligatoire *")
            console.log('USE REF amountRef ! ', refAttributs.current.amountRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.amountRef;
        } else {
            const { Amount, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!flightFormData?.Name || flightFormData?.Name === '') {
            newErrors.Name = t("Ce champ est obligatoire *")
            console.log('USE REF NAME ! ', refAttributs.current.NameRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.NameRef;
        } else {
            const { Name, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        // if (!flightFormData?.PriorityId || flightFormData?.PriorityId === '') {
        //     newErrors.PriorityId = t("Ce champ est obligatoire *")
        //     console.log('USE REF NAME ! ', refAttributs.current.PriorityIdRef);
        //     if (!firstErrorRef) firstErrorRef = refAttributs.current.PriorityIdRef;
        // } else {
        //     const { PriorityId, ...updatedErrors } = newErrors;
        //     newErrors = updatedErrors;
        // }

        if (!flightFormData?.Price || flightFormData?.Price === '' || flightFormData?.Price === null) {
            newErrors.Price = t("Ce champ est obligatoire *")
            console.log('USE REF IMPRESSIONS ! ', refAttributs.current.PriceRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.PriceRef;
        } else if (flightFormData?.Price && flightFormData?.Price < 10) {
            newErrors.Price = t("Le prix doit être supérieur ou égal à 10 *")
            console.log('USE REF IMPRESSIONS ! ', refAttributs.current.PriceRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.PriceRef;
        } else {
            const { Price, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }




        if (!flightFormData?.Impressions || flightFormData?.Impressions === '') {
            newErrors.Impressions = t("Ce champ est obligatoire *")
            console.log('USE REF IMPRESSIONS ! ', refAttributs.current.ImpressionsRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.ImpressionsRef;
        } else {
            const { Impressions, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if ([1, 3, 7, 8].includes(flightFormData?.GoalType) && !flightFormData?.EndDate) {
            newErrors.EndDate = t("Ce champ est obligatoire *")
            console.log('USE REF IMPRESSIONS ! ', refAttributs.current.EndDateRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.EndDateRef;

        } else {
            const { EndDate, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        // if ([1, 2, 3, 4].includes(flightFormData?.CapType) && !flightFormData?.DailyCapAmount) {
        //     newErrors.DailyCapAmount = t("Ce champ est obligatoire *")
        //     console.log('USE REF NAME ! ', refAttributs.current.DailyCapAmountRef);
        //     if (!firstErrorRef) firstErrorRef = refAttributs.current.DailyCapAmountRef;
        // } else {
        //     const { DailyCapAmount, ...updatedErrors } = newErrors;
        //     newErrors = updatedErrors;
        // }

        // if ([1, 2, 3, 4].includes(flightFormData?.CapType) && !flightFormData?.LifetimeCapAmount) {
        //     newErrors.LifetimeCapAmount = t("Ce champ est obligatoire *")
        //     console.log('USE REF NAME ! ', refAttributs.current.LifetimeCapAmountRef);
        //     if (!firstErrorRef) firstErrorRef = refAttributs.current.LifetimeCapAmountRef;
        // } else {
        //     const { LifetimeCapAmount, ...updatedErrors } = newErrors;
        //     newErrors = updatedErrors;
        // }

        if (!siteIdSelected || siteIdSelected.length === 0) {
            newErrors.SiteZoneTargeting = t("Ce champ est obligatoire *")
            console.log('USE REF NAME ! ', refAttributs.current.SiteZoneTargetingRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;
        } else {
            const { SiteZoneTargeting, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        console.log('newErrorsnewErrors : ', newErrors);

        setErrors(newErrors);
        setError(errorAmount);
        console.log(newErrors);

        // Scroll to the first error if any
        if (firstErrorRef) {
            console.log('Scrolling/focusing on:', firstErrorRef);

            if (Object.keys(newErrors).some(key => key !== 'Amount')) {
                handleCloseConfirmFlight()
            }
            setTimeout(() => {
                firstErrorRef.focus(); // For focusing
            }, 500)

            // Uncomment one of these depending on your requirement
            // firstErrorRef.scrollIntoView({ behavior: 'smooth', block: 'center' }); // For scrolling


            // Exit the function if there's an error
        }
        console.log('Scrolling/focusing on:', firstErrorRef);
        if (!firstErrorRef) {
            // Créer une nouvelle liste qui contient des objets {index, fileName}
            let dataImagesAdded = [];

            const newList = dataCreativeMaps.map(async (item, index) => {
                const formData = new FormData();
                const FieldName = item.Creative?.FieldName || ''; // Extraire FieldName s'il existe, sinon utiliser une chaîne vide
                const Id = item?.Creative?.Id || index; // Extraire Id s'il existe, sinon utiliser l'index

                if (FieldName) {
                    formData.append('image', FieldName);
                    formData.append('index', index);
                    try {
                        const response = await axiosClient.post(`upload-image/`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });
                        console.log(response.data);
                        dataImagesAdded.push(response.data); // Ajouter la réponse au tableau dataImagesAdded
                        delete item.Creative.FieldName; // Supprimer FieldName s'il existe
                    } catch (error) {
                        console.error('Error uploading image:', error.response ? error.response.data : error.message);
                        throw error; // Rethrow error to stop execution and handle it in the calling function
                    }
                }
                return { index, FieldName }; // Retourner l'objet {index, FieldName}
            });

            try {

                if (parseInt(Id) === 0) {
                    if (!stripe || !elements) {
                        return;
                    }
                    // Ensure PaymentElement is properly initialized
                    const { error: submitError } = await elements.submit();

                    if (submitError) {
                        console.log('[submitError]', submitError);
                        return;
                    }
                }


                setLoadingAdd(true)
                // Attendre que toutes les promesses soient résolues
                const results = await Promise.all(newList);
                console.log('Nouvelle liste :', results); // Nouvelle liste après toutes les résolutions
                console.log('Liste initiale modifiée :', dataCreativeMaps);

                // Préparer les données pour la requête POST finale
                if (dataSites.find(e => e.Id === parseInt(siteIdSelected))?.Title !== 'DAB+') {
                    if (dataGeoTargeting.some(item => item.hasOwnProperty('LocationId'))) {
                        console.log('GGGGGGGGGGGGGGGGGGGR : ', dataGeoTargeting.some(item => item.hasOwnProperty('LocationId') && item).length);

                        for (let index = 0; index < dataGeoTargeting.filter(item => item.hasOwnProperty('LocationId') && item).length; index++) {
                            console.log('GGGGGGGGGGGGGGGGGGGR : ', index);

                            deleteGeoTargeting(dataGeoTargeting.filter(item => item.hasOwnProperty('LocationId'))?.[index]?.LocationId)
                        }
                    }
                    else flightFormData.GeoTargeting = null;
                }
                if (dataSites.find(e => e.Id === parseInt(siteIdSelected))?.Title !== 'Radio Karaoke') {
                    flightFormData.Keywords = null;
                    flightFormData.CustomTargeting = null;
                }
                const data = {
                    flightFormData: flightFormData,
                    dataCreativeMaps: dataCreativeMaps,
                    IdsCreativeUpdated: IdsCreativeUpdated,
                    dataFiles: dataImagesAdded,
                };
                console.log('FIIIIIIIIIIIILE : ', data.dataCreativeMaps);
                console.log('FIIIIIIIIIIIILE : ', data);

                // Envoyer la requête POST finale

                setErrors({})
                const response = await axiosClient.post(`add-edit-flight/${Id}/`, data)
                    .catch(error => {
                        setLoadingAdd(false)
                        // let newErrors = { ...errors };
                        // let firstErrorRef = null;
                        // alert('first')


                        console.error('Error creating flight:', error.response ? error.response.data : error.message);
                        console.log('ERROR:  creating flight: ', error.response.data.creative_errors?.[0]?.index);

                        const updatedDataCreativeMaps = dataCreativeMaps.map((val, key) => {
                            const matchingResult = results.find(result => result.index === key); // Trouver l'élément correspondant par index

                            // Si un match est trouvé, on met à jour le FieldName
                            return matchingResult ? {
                                ...val, // Garde les propriétés existantes de `val`
                                Creative: {
                                    ...val.Creative, // Garde les propriétés existantes de `Creative`
                                    FieldName: matchingResult.FieldName // Met à jour le FieldName à l'intérieur de Creative
                                } // Met à jour le FieldName avec la valeur correspondante
                            } : val; // Si pas de match, renvoie l'élément inchangé
                        });
                        setDataCreativeMaps(updatedDataCreativeMaps);

                        console.log('Nouvelle liste : | updatedDataCreativeMaps : ', updatedDataCreativeMaps);


                        if (error.response.data?.creative_errors?.[0]?.response?.Errors?.[0]?.message) {


                            console.log('ERROR 1 with eRROR Ads :', error.response.data?.creative_errors?.[0]?.response?.Errors?.[0]?.message);
                            setLoadingAdd(false)
                            setManageAdLoading(false)
                            console.error('Error creating flight:', error.response.data?.creative_errors?.[0]?.response?.Errors?.[0]?.message);
                            console.error('Error creating flight:', error.response.data.creative_errors?.[0]?.index);
                            newErrorsAd.FieldName = error.response.data?.creative_errors?.[0]?.response?.Errors?.[0]?.message
                            console.log('USE REF SiteZoneTargeting ! ', refAttributsAd.current.FieldNameRef);
                            if (!firstErrorAdRef) firstErrorAdRef = refAttributsAd.current.FieldNameRef;
                            setErrorsAd(newErrorsAd);



                            if (10 > 2) {
                                console.log('Scrolling/focusing on:', firstErrorRef);
                                console.log('dataCreativeMaps FROM UPDATE AD FUNCTION : ', dataCreativeMaps);

                                handleCloseConfirmFlight()
                                setTimeout(() => {
                                    setDataCreativeMaps(updatedDataCreativeMaps);
                                    const key = parseInt(error.response.data.creative_errors?.[0]?.index)
                                    // Utiliser la version mise à jour des données
                                    const creative = updatedDataCreativeMaps.find((_, index) => index === key)?.Creative;

                                    console.log('dataCreativeMaps.find((_, index) => index === key)?.Creative?.Title : ', dataCreativeMaps, ' | ', key);


                                    handleShowUpdateAddModal()
                                    setKeyAdSelected(key)
                                    setImageLink(dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink != 'https://static.adzerk.net/Advertisers/' ? dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink : '')
                                    setTitle(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Title)
                                    setAdTypeId(dataCreativeMaps.find((_, index) => index === key)?.Creative?.AdTypeId)
                                    setUrl(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Url)
                                    setAltText(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Alt)
                                    setMetadata(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Metadata)
                                    setLinkAsset(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues ? JSON.parse(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues)?.ctPlayback_URL : '')
                                    setDureeAsset(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues ? JSON.parse(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues)?.ctduration : '')
                                    setFormat(dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink !== 'https://static.adzerk.net/Advertisers/' ? 'Image' : 'Video')
                                    // firstErrorRef.focus(); // For focusing
                                }, 500)
                            }


                            console.log('DATA dataCreativeMaps : ', dataCreativeMaps);
                            if (error.response.data && error.response.data.response.Errors[0].message.includes('Incorrect image size for ad type,')) {

                            }
                            if (error.response.data[0].provided) {

                            }
                        }

                        console.log('ERROR 1 with Provided :', error.response.data?.[0]);
                        if (error.response.data?.[0]?.provided && error.response.data?.[0]?.provided.some(e => e.CountryName)) {
                            newErrors.GeoTargeting = (error.response.data?.[0]?.message?.errors?.[0]?.message)
                            console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.GeoTargetingRef);
                            if (!firstErrorRef) firstErrorRef = refAttributs.current.GeoTargetingRef;
                        } else {
                            const { GeoTargeting, ...updatedErrors } = newErrors;
                            newErrors = updatedErrors;
                        }
                        if (error.response.data?.[0]?.value && error.response.data?.[0]?.value.some(e => e.SiteId) || error.response.data?.[0]?.provided && error.response.data?.[0]?.provided.some(e => e.Title)) {
                            console.log('Message Erreur : Site : ', error.response.data?.[0]?.message);
                            newErrors.SiteZoneTargeting = error.response.data?.[0]?.message
                            console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
                            if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;
                        }



                        if (firstErrorRef) {
                            console.log('Scrolling/focusing on:', firstErrorRef);

                            handleCloseConfirmFlight()
                            setTimeout(() => {
                                setDataCreativeMaps(updatedDataCreativeMaps);
                                setErrors(newErrors);
                                firstErrorRef.focus(); // For focusing
                            }, 500)
                        }





                        // return
                    })
                console.log('RESPONSE DATA : after Post : 2222 ', response.data);
                console.log('DATATA DATATA DATAta : ', flightFormData);
                if (response.data) {

                    console.log('DATAA PAIEMENT : ', response.data);


                    // setLoadingAdd(false)
                    try {
                        // toast.success(t("Le groupe d'annonces a été ajouté avec succès"), {
                        //     position: "top-center",
                        //     autoClose: 3000,
                        //     hideProgressBar: false,
                        //     closeOnClick: false,
                        //     pauseOnHover: false,
                        //     draggable: false,
                        //     progress: undefined,
                        //     theme: "light",
                        // });
                        // Fetch the clientSecret here
                        const { data } = await axiosClient.post('/create-payment-intent', {
                            user: user?.id,
                            flight: response.data?.Id,
                            amount: amount,
                            currency: 'usd',
                        });

                        const { clientSecret } = data;

                        // Confirm the payment with the fetched clientSecret
                        const { error } = await stripe.confirmPayment({
                            elements,
                            clientSecret,
                            confirmParams: {
                                return_url: `${window.location.origin}/flights/${c}`,
                            },
                        });
                        if (error) {
                            setLoadingAdd(false)
                            console.log('[error]', error);
                        } else {
                            console.log('Payment successful');
                        }

                    } catch (error) {
                        setLoadingAdd(false)
                        console.error('Error fetching client secret:', error);
                    }
                }
                if (parseInt(Id) > 0) {
                    handleCloseConfirmFlight()
                    toast.success(t("Le groupe d'annonces a été mis à jour avec succès"), {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        navigate(`/flights/${c}`)
                    }, 1500)
                }
                if (parseInt(Id) === 0) {
                    handleCloseConfirmFlight()
                    toast.success(t("Le groupe d'annonces a été ajouté avec succès"), {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        navigate(`/flights/${c}`)
                    }, 1500)
                }


                return response.data;
            } catch (error) {
                setErrors({})
                setLoadingAdd(false)
                let newErrors = { ...errors };
                let firstErrorRef = null;

                // console.log('EEEEEEE : ', error.response);

                // if (error.response.data.response.Errors[0].message.includes('Incorrect image size for ad type,')) {
                //     alert('second')
                //     setManageAdLoading(false)
                //     console.error('Error creating flight:', error.response.data.response.Errors[0].message);
                //     newErrors.FieldName = JSON.stringify(error.response.data.response.Errors[0].message)
                //     console.log('USE REF SiteZoneTargeting ! ', refAttributsAd.current.FieldNameRef);
                //     firstErrorRef = refAttributsAd.current.FieldNameRef;
                //     setErrors(newErrors);


                //     if (firstErrorRef) {
                //         console.log('Scrolling/focusing on:', firstErrorRef);

                //         handleCloseConfirmFlight()
                //         setTimeout(() => {
                //             const key = error.response.data.index
                //             handleShowUpdateAddModal()
                //             setKeyAdSelected(key)
                //             setImageLink(dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink != 'https://static.adzerk.net/Advertisers/' ? dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink : '')
                //             setTitle(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Title)
                //             setAdTypeId(dataCreativeMaps.find((_, index) => index === key)?.Creative?.AdTypeId)
                //             setUrl(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Url)
                //             setAltText(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Alt)
                //             setMetadata(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Metadata)
                //             setLinkAsset(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues ? JSON.parse(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues)?.ctPlayback_URL : '')
                //             setCtDuration(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues ? JSON.parse(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues)?.ctduration : '')
                //             setFormat(dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink !== 'https://static.adzerk.net/Advertisers/' ? 'Image' : 'Video')
                //             firstErrorRef.focus(); // For focusing
                //         }, 1000)
                //     }
                //     return
                // }

                // if (error.response.data[0].provided && error.response.data[0].provided.some(e => e.CountryName)) {
                //     newErrors.GeoTargeting = JSON.stringify(error.response.data[0].message)
                //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.GeoTargetingRef);
                //     if (!firstErrorRef) firstErrorRef = refAttributs.current.GeoTargetingRef;
                // } else {
                //     const { GeoTargeting, ...updatedErrors } = newErrors;
                //     newErrors = updatedErrors;
                // }
                // if (error.response.data[0].value && error.response.data[0].value.some(e => e.SiteId)) {
                //     console.log('Message Erreur : Site : ', error.response.data[0].message);
                //     newErrors.SiteZoneTargeting = error.response.data[0].message
                //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
                //     if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;
                // } else {
                //     const { SiteZoneTargeting, ...updatedErrors } = newErrors;
                //     newErrors = updatedErrors;
                // }


                // setErrors(newErrors);

                // if (firstErrorRef) {
                //     console.log('Scrolling/focusing on:', firstErrorRef);

                //     handleCloseConfirmFlight()
                //     setTimeout(() => {
                //         firstErrorRef.focus(); // For focusing
                //     }, 500)
                // }



                // console.error('Error creating flight:', error.response ? error.response.data : error.message);
                // throw error; // Rethrow to handle it in the calling function
            }
        }

    }




    const getDataZones = async (id) => {
        try {
            const res = await axiosClient.get(`get-zones/`);
            console.log('DATATATATA :', res.data.items);
            return res.data; // Return the data directly

        } catch (error) {
            console.log('ERROR :', error);
            throw error;

        }
    }

    const [dataSites, setDataSites] = useState([])
    const getDataSites = async () => {
        try {
            const res = await axiosClient.get(`get-sites/`);
            console.log('DATATATATA :', res.data.items);
            return res.data.items; // Return the data directly
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    };
    const fetchData = async () => {
        try {
            const data = await getDataSites();
            setDataSites(data)
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };
    useEffect(() => {


        fetchData();
    }, []); 
    

    const [dataZones, setDataZones] = useState([])
    const [idSiteSelected, setIdSiteSelected] = useState()
    const fetchDataZones = async () => {
        try {
            const data = await getDataZones(idSiteSelected)
            setDataZones(data.filter(
                (zone) => zone.SiteId === idSiteDAB
            ))
            console.log('DATA ZOOONES : ', data);

        } catch (error) {
            console.log('Error fetching zones data :', error);
        }
    }
    useEffect(() => {
        
        fetchDataZones()
    }, [])

    useEffect(() => {
        console.log('ERROOOORS : ', errors);

    }, [errors])

    const [manageSiteTargetingLoading, setManageSiteTargetingLoading] = useState()
    const addSiteTargeting = async () => {
        let newErrors = { ...errors };
        let firstErrorRef = null;
        try {
            // if (dataSiteZoneTargeting?.length !== 0 && dataSiteZoneTargeting?.some(e => e.IsExclude === siteTargetingFormData?.IsExclude && e.SiteId === siteTargetingFormData?.SiteId && e.ZoneId === siteTargetingFormData?.ZoneId)) {
            //     newErrors.SiteZoneTargeting = 'This site target already exists.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;

            // }
            // if (dataSiteZoneTargeting?.length !== 0 && dataSiteZoneTargeting?.some(e => e.IsExclude !== siteTargetingFormData?.IsExclude && e.SiteId === siteTargetingFormData?.SiteId && e.ZoneId === siteTargetingFormData?.ZoneId)) {
            //     newErrors.SiteZoneTargeting = 'An include rule exactly matches an exclude rule and is therefore invalid.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;

            // }
            // if (dataSiteZoneTargeting?.length !== 0 && siteTargetingFormData?.IsExclude === true && dataSiteZoneTargeting?.some(e => e.IsExclude === false && e.SiteId === siteTargetingFormData?.SiteId)) {
            //     newErrors.SiteZoneTargeting = 'A rule included a site and zone, but another rule excludes that site.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;

            // }

            // if (dataSiteZoneTargeting?.length !== 0 && dataSiteZoneTargeting?.some(e => e.SiteId === siteTargetingFormData?.SiteId && e.ZoneId === siteTargetingFormData?.ZoneId)) {
            //     newErrors.SiteZoneTargeting = 'This site target already exists.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;

            // }
            // if (dataSiteZoneTargeting?.length !== 0 && dataSiteZoneTargeting?.some(e => e.SiteId === siteTargetingFormData?.SiteId && e.ZoneId !== null && siteTargetingFormData?.ZoneId === null)) {
            //     newErrors.SiteZoneTargeting = 'This site target already exists.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;

            // }

            if (firstErrorRef) {
                console.log('Scrolling/focusing on:', firstErrorRef);
                firstErrorRef.focus();
            } else {
                if (parseInt(Id) !== 0) {
                    setManageSiteTargetingLoading(true)
                    const data = { siteTargetingFormData }
                    try {
                        await axiosClient.post(`add-site-flight/${parseInt(Id)}/`, data).then(() => {
                            setSiteTargetingFormData({ IsExclude: false, })
                            setManageSiteTargetingLoading(false)
                            getDataSiteZoneFlight()

                            toast.success(t("Le site/la zone est ajouté"), {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });
                        })

                    } catch (error) {
                        setManageSiteTargetingLoading(false)
                        // console.error('Error creating flight:', error.response ? error.response.data : error.message);

                        newErrors.SiteZoneTargeting = error.response.data[0].message
                        console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.SiteZoneTargetingRef);
                        if (!firstErrorRef) firstErrorRef = refAttributs.current.SiteZoneTargetingRef;
                        setErrors(newErrors);
                        if (firstErrorRef) {
                            console.log('Scrolling/focusing on:', firstErrorRef);
                            firstErrorRef.focus();
                        }

                    }
                } else {
                    const { SiteZoneTargeting, ...updatedErrors } = newErrors;
                    newErrors = updatedErrors;
                    const data = [...dataSiteZoneTargeting, siteTargetingFormData]
                    setDataSiteZoneTargeting(data)
                    setFlightFormData({
                        ...flightFormData,
                        'SiteZoneTargeting': data
                    })
                    setSiteTargetingFormData({ IsExclude: siteTargetingFormData?.IsExclude, SiteId: '' })
                    console.log('DATTTTTTTTTTTTTTA : ', [...dataSiteZoneTargeting, siteTargetingFormData]);
                }

            }

            setErrors(newErrors);
            console.log('newErrorsnewErrors : ', newErrors);


        } catch (error) {
            console.log('ERROR ! ', error);

        }
    }


    const [deleteSiteZoneTargetingselected, setDeleteSiteZoneTargetingselected] = useState()
    const deleteSiteZoneTargeting = async (idSite) => {
        try {
            await axiosClient.get(`delete-site-flight/${parseInt(Id)}/${parseInt(idSite)}/`).then(() => {
                setDataSiteZoneTargeting(dataSiteZoneTargeting.filter(e => e.Id !== idSite))
                setDeleteSiteZoneTargetingselected()

                toast.success(t("Le site/la zone est supprimé"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            })
        } catch (error) {
            setDeleteSiteZoneTargetingselected()
            console.log('ERROR : ', error);
        }
    }



    const getDataRegions = async (country) => {
        try {
            const res = await axiosClient.get(`get-regions/${country}/`);
            console.log('DATATATATA Regions :', res.data.Regions);
            console.log('DATATATATA AllMetros :', res.data.AllMetros);
            return {
                regions: res.data.Regions,
                metros: res.data.AllMetros
            };
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    };

    const [dataRegions, setDataRegions] = useState([])
    const [dataMetros, setDataMetros] = useState([])
    const [loadingDataRegions, setLoadingDataRegion] = useState(false)
    useEffect(() => {
        if (geoTargetingFormData?.CountryCode) {
            setLoadingDataRegion(true)
            const fetchData = async () => {
                try {
                    const { regions, metros } = await getDataRegions(geoTargetingFormData?.CountryCode)
                    console.log('DATAAAAAAAAAAAAA REGION : ', regions);
                    console.log('DATAAAAAAAAAAAAA Metros : ', metros);

                    setDataRegions(regions || [])
                    setDataMetros(metros || [])
                    setLoadingDataRegion(false)
                } catch (error) {
                    console.log('Error fetching data:', error);
                    setLoadingDataRegion(false)
                }
            };

            fetchData();
        }
    }, [geoTargetingFormData?.CountryCode]);

    const [manageGeoTargetingLoading, setManageGeoTargetingLoading] = useState()
    const addGeoTargeting = async () => {
        let newErrors = { ...errors };
        let firstErrorRef = null;
        try {
            // if (dataGeoTargeting && dataGeoTargeting?.some(e => e.Region === geoTargetingFormData?.Region)) {
            //     newErrors.GeoTargeting = 'Geolocation Record Already Exists.'
            //     console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.GeoTargetingRef);
            //     if (!firstErrorRef) firstErrorRef = refAttributs.current.GeoTargetingRef;

            // }
            console.log('newErrorsnewErrors : ', newErrors);

            if (firstErrorRef) {
                console.log('Scrolling/focusing on:', firstErrorRef);
                firstErrorRef.focus();
            }
            else {
                if (parseInt(Id) !== 0) {
                    setManageGeoTargetingLoading(true)
                    const data = { geoTargetingFormData }
                    try {
                        await axiosClient.post(`add-geoTargeting-flight/${parseInt(Id)}/`, data).then(() => {
                            setGeoTargetingFormData({
                                IsExclude: false,
                            })
                            setManageGeoTargetingLoading(false)
                            getDataGeoTargetingFlight()

                            toast.success(t("Le ciblage géographique est ajouté"), {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });
                        })

                    } catch (error) {
                        setManageGeoTargetingLoading(false)

                        console.log('ERROR : ', JSON.stringify(error.response.data[0].message));
                        console.error('Error creating GeoTargeting:', error.response ? error.response.data : error.message);

                        newErrors.GeoTargeting = JSON.stringify(error.response.data[0].message)
                        console.log('USE REF SiteZoneTargeting ! ', refAttributs.current.GeoTargetingRef);
                        if (!firstErrorRef) firstErrorRef = refAttributs.current.GeoTargetingRef;
                        setErrors(newErrors);
                        if (firstErrorRef) {
                            console.log('Scrolling/focusing on:', firstErrorRef);
                            firstErrorRef.focus();
                        }

                    }
                }
                else {
                    const { SiteZoneTargeting, ...updatedErrors } = newErrors;
                    newErrors = updatedErrors;
                    const data = [...dataGeoTargeting, geoTargetingFormData]
                    setDataGeoTargeting(data)
                    setFlightFormData({
                        ...flightFormData,
                        'GeoTargeting': data
                    })
                    setGeoTargetingFormData({ IsExclude: geoTargetingFormData?.IsExclude, CountryCode: '', Region: '', MetroCode: '' })
                    console.log('DATTTTTTTTTTTTTTA : ', [...geoTargetingFormData, geoTargetingFormData]);
                }
            }


        } catch (error) {
            console.log('ERROR ! ', error);

        }
    }


    const [deleteGeoTargetingselected, setDeleteGeoTargetingselected] = useState()
    const deleteGeoTargeting = async (idGeo, showToat) => {
        try {
            await axiosClient.get(`delete-geoTargeting-flight/${parseInt(Id)}/${parseInt(idGeo)}/`).then(() => {
                setDataGeoTargeting(dataGeoTargeting.filter(e => e.LocationId !== idGeo))
                setDeleteGeoTargetingselected()
                console.log('idGeoidGeoidGeoidGeo : ', idGeo);


                showToat && toast.success(t("Le ciblage géographique est supprimé"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            })
        } catch (error) {
            setDeleteGeoTargetingselected()
            console.log('ERROR : ', error);
        }
    }

    const get_Region_Metros_Name = (CountryCode, regionCode, metrosCode) => {
        try {
            axiosClient.get(`get-regions/${CountryCode}/`).then(async (res) => {
                console.log('TEEEEEEEST : ', res.data.Regions.find(e => e.Code === regionCode));
                const region = res.data.Regions.find(e => e.Code === regionCode)
                const regionName = region ? region.Name : '-'
                const MetroName = '-'
                console.log('TEEEEEEEST : ', 515, ' : ', res.data.AllMetros);
                return {
                    regionName,
                    MetroName
                }
            })
        } catch (error) {
            console.log('ERROR : ', error.message);
            return 'Error occurred'; // Retourner une valeur par défaut en cas d'erreur
        }
    };


    // AD Management
    const getDataAdTypes = async (format) => {
        try {
            const res = await axiosClient.get(`get-adTypes/`);
            console.log('DATATATATA :', res.data.items);
            if (format === 'Image') {
                const data = res.data.items.filter(e => !e.Name.includes('video'))
                console.log('DATATATATA_DATATATATA : ', data);
                return data

            } else {
                const data = res.data.items.filter(e => e.Name.includes('video'))
                console.log('DATATATATA_DATATATATA : ', data);
                return data
            }
            // return res.data.items; // Return the data directly
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    }


    const [dataAdTypes, setDataAdTypes] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDataAdTypes(format);
                console.log('DATA AD TYPEs : ', data);

                setDataAdTypes(data)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, [format]); // Empty dependency array ensures this runs once when the component mounts


    //Variable 
    const [fieldName, setFieldName] = useState()
    const [imageLink, setImageLink] = useState('')
    const [title, setTitle] = useState('')
    const [adTypeId, setAdTypeId] = useState()
    const [url, setUrl] = useState('')
    const [altText, setAltText] = useState('')
    const [metadata, setMetadata] = useState('')
    const [ctDuration, setCtDuration] = useState('')

    const [IdsCreativeUpdated, setIdsCreativeUpdated] = useState([])

    const [errorsAd, setErrorsAd] = useState({})
    const refAttributsAd = useRef({
        FieldNameRef: null,
        ImageLinkRef: null,
        TitleRef: null,
        AdTypeIdRef: null,
        UrlRef: null,
        AltRef: null,
        MetadataRef: null,
        ctPlayback_URLRef: null,
        ctdurationRef: null,
    })

    const [manageAdLoading, setManageAdLoading] = useState()
    const createNewAd = async () => {

        let newErrors = { ...errorsAd };
        let firstErrorRef = null;

        const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

        if (format === "Image" && !creativeMapsFormData?.ImageLink && (!creativeMapsFormData?.FieldName || creativeMapsFormData?.ImageLink === null)) {
            newErrors.FieldName = t("Ce champ est obligatoire *")
            console.log('USE ImageLink ! ', refAttributs.current.FieldNameRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.FieldNameRef;
        } else {
            const { ImageLink, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (format === "Image" && !creativeMapsFormData?.FieldName && (!creativeMapsFormData?.ImageLink || creativeMapsFormData?.ImageLink === '')) {
            newErrors.ImageLink = t("Ce champ est obligatoire *")
            console.log('USE ImageLink ! ', refAttributs.current.ImageLinkRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ImageLinkRef;
        } else {
            const { ImageLink, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (format === "Video" && !creativeMapsFormData?.TemplateValues?.ctPlayback_URL) {
            newErrors.ctPlayback_URL = 'Upload new video'
            console.log('USE ImageLink ! ', refAttributs.current.ctPlayback_URLRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ctPlayback_URLRef;
        } else {
            const { ctPlayback_URL, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (format === "Video" && (!creativeMapsFormData?.TemplateValues?.ctduration || !creativeMapsFormData?.TemplateValues?.ctduration === '')) {
            newErrors.ctduration = t("Ce champ est obligatoire *")
            console.log('USE ImageLink ! ', refAttributs.current.ctdurationRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ctdurationRef;
        } else {
            const { ctduration, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!creativeMapsFormData?.Title || creativeMapsFormData?.Title === '') {
            newErrors.Title = t("Ce champ est obligatoire *")
            console.log('USE Title ! ', refAttributs.current.TitleRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.TitleRef;
        } else {
            const { Title, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!creativeMapsFormData?.AdTypeId || creativeMapsFormData?.AdTypeId === '') {
            newErrors.AdTypeId = t("Ce champ est obligatoire *")
            console.log('USE AdTypeId ! ', refAttributs.current.AdTypeIdRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.AdTypeIdRef;
        } else {
            const { AdTypeId, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!creativeMapsFormData?.Url || creativeMapsFormData?.Url === '') {
            newErrors.Url = t("Ce champ est obligatoire *")
            console.log('USE Url ! ', refAttributs.current.UrlRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.UrlRef;
        }

        else if (!urlRegex.test(creativeMapsFormData?.Url)) {

            const lines = creativeMapsFormData?.Url.split('\n');
            let isInvalid = false;

            lines.forEach((line, index) => {
                if (!urlRegex.test(line)) {
                    isInvalid = true;
                }
            });
            if (isInvalid) {
                newErrors.Url = t("Le lien que vous avez entré est invalide. Veuillez saisir un lien valide.");
                console.log('USE Url ! ', refAttributs.current.UrlRef);
                if (!firstErrorRef) firstErrorRef = refAttributsAd.current.UrlRef;
            } else {
                const { Url, ...updatedErrors } = newErrors;
                newErrors = updatedErrors;
            }
        } else {
            const { Url, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        let metadata;  // Change 'const' to 'let'

        if (creativeMapsFormData?.Metadata && creativeMapsFormData?.Metadata.trim()) {
            console.log('hh : ', typeof creativeMapsFormData?.Metadata);

            try {
                metadata = JSON.parse(creativeMapsFormData?.Metadata.trim());  // Now 'metadata' can be reassigned
                console.log('hh metadata après parse : ', typeof metadata);
                const { Metadata, ...updatedErrors } = newErrors;
                newErrors = updatedErrors;
            } catch (error) {
                newErrors.Metadata = t('Objet JSON non valide');
                console.log('Erreur de parsing metadata : ', error.message);
                if (!firstErrorRef) firstErrorRef = refAttributsAd.current.MetadataRef;
            }

            if (typeof metadata === 'object' && !Array.isArray(metadata)) {
                console.log('Metadata est un objet valide : ', metadata);
                const { Metadata, ...updatedErrors } = newErrors;
                newErrors = updatedErrors;
            }
        } else {
            const { Metadata, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }


        // if (creativeMapsFormData?.Metadata && typeof creativeMapsFormData.Metadata === 'string') {
        //     newErrors.Metadata = 'Invalid JSON object'
        //     console.log('USE Metadata ! ', refAttributs.current.MetadataRef);
        //     if (!firstErrorRef) firstErrorRef = refAttributsAd.current.MetadataRef;
        // } else {
        //     const { Metadata, ...updatedErrors } = newErrors;
        //     newErrors = updatedErrors;
        // }

        setErrorsAd(newErrors);
        console.log(newErrors);
        if (firstErrorRef) {
            console.log('Scrolling/focusing on:', firstErrorRef);
            firstErrorRef.focus();
        } else {
            if (parseInt(Id) !== 0) {
                setManageAdLoading(true)
                console.log('parseInt(Id) : ', parseInt(Id));

                // console.log('creativeMapsFormDatacreativeMapsFormData : ', creativeMapsFormData);


                let dataImagesAdded;
                const formData = new FormData();
                const FieldName = creativeMapsFormData?.FieldName || ''; // Extraire FieldName s'il existe, sinon utiliser une chaîne vide
                const index = 0; // Extraire Id s'il existe, sinon utiliser l'index
                if (FieldName) {

                    formData.append('image', FieldName);
                    formData.append('index', 0);


                    try {
                        const response = await axiosClient.post(`upload-image/`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }).catch((error) => {
                            setManageAdLoading(false)
                            console.error('Error uploading image:', error.response ? error.response.data : error.message);
                            throw error; // Rethrow error to stop execution and handle it in the calling function
                        })
                        dataImagesAdded = response.data;
                        console.log(response.data);
                    } catch (error) {
                        setManageAdLoading(false)
                        console.error('Error uploading image:', error.response ? error.response.data : error.message);
                        throw error; // Rethrow error to stop execution and handle it in the calling function
                    }

                }

                try {
                    const data = {
                        dataCreativeMaps: {
                            IsActive: true,
                            Creative: {
                                ...creativeMapsFormData,
                                TemplateValues: format === "Video" ?
                                    JSON.stringify({
                                        ctPlayback_URL: linkAsset,
                                        ctduration: dureeAsset
                                    }) : null, // Conversion de TemplateValues en chaîne JSON encodée,
                                TemplateId: format === "Video" ? idTemplate : null,
                            }
                        },
                        dataFiles: dataImagesAdded,
                    };
                    await axiosClient.post(`add-Ad/${parseInt(Id)}/`, data).then(() => {
                        handleCloseAddAdModal()
                        setManageAdLoading(false)
                        getDataAds()

                        toast.success(t("L'annonce est ajoutée"), {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        });
                    })
                    console.log('DATA : ', data);


                    // return response.data;
                } catch (error) {
                    setManageAdLoading(false)
                    console.error('Error creating flight:', error.response.data.response.Errors[0].message);
                    newErrors.FieldName = JSON.stringify(error.response.data.response.Errors[0].message)
                    console.log('USE REF SiteZoneTargeting ! ', refAttributsAd.current.FieldNameRef);
                    if (!firstErrorRef) firstErrorRef = refAttributsAd.current.FieldNameRef;
                    setErrors(newErrors);
                    if (firstErrorRef) {
                        console.log('Scrolling/focusing on:', firstErrorRef);
                        firstErrorRef.focus();
                    }
                    // throw error; // Rethrow to handle it in the calling function
                }
            }
            else {
                setDataCreativeMaps(
                    [
                        ...dataCreativeMaps,
                        {
                            IsActive: true,
                            Creative: {
                                ...creativeMapsFormData,
                                TemplateValues: format === "Video" ?
                                    JSON.stringify({
                                        ctPlayback_URL: linkAsset,
                                        ctduration: dureeAsset
                                    }) : null, // Conversion de TemplateValues en chaîne JSON encodée,
                                TemplateId: format === "Video" ? idTemplate : null,
                            }
                        }
                    ]
                )
                setCreativeMapsFormData({
                    IsActive: true,
                    Body: '',
                })
                setErrorsAd({})
                handleCloseAddAdModal()
            }

        }

    }

    const updateAd = async () => {

        let newErrors = { ...errorsAd };
        let firstErrorRef = null;

        if (format === "Image" && !imageLink && !fieldName) {
            newErrors.FieldName = t("Ce champ est obligatoire *");
            newErrors.ImageLink = t("Ce champ est obligatoire *");
            console.log('USE FieldName !', refAttributs.current.FieldNameRef);
            if (!firstErrorRef) {
                firstErrorRef = refAttributsAd.current.ImageLinkRef
                firstErrorRef = refAttributsAd.current.FieldNameRef
            }
        } else {
            const { FieldName, ImageLink, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (format === "Video" && (!linkAsset || linkAsset === '')) {
            newErrors.ctPlayback_URL = 'Upload new video'
            console.log('USE ImageLink ! ', refAttributs.current.ctPlayback_URLRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ctPlayback_URLRef;
        } else {
            const { ctPlayback_URL, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (format === "Video" && (!ctDuration || !ctDuration === '')) {
            newErrors.ctduration = t("Ce champ est obligatoire *")
            console.log('USE ImageLink ! ', refAttributs.current.ctdurationRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ctdurationRef;
        } else {
            const { ctduration, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        // if ((!fieldName) || imageLink === null || imageLink === '') {
        //     newErrors.ImageLink = 'This field is required';
        //     console.log('USE ImageLink !', refAttributs.current.ImageLinkRef);
        //     if (!firstErrorRef) firstErrorRef = refAttributsAd.current.ImageLinkRef;
        // } else {
        //     const { ImageLink, ...updatedErrors } = newErrors;
        //     newErrors = updatedErrors;
        // }

        if (!title || title === '') {
            newErrors.Title = t("Ce champ est obligatoire *")
            console.log('USE Title ! ', refAttributs.current.TitleRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.TitleRef;
        } else {
            const { Title, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        if (!adTypeId || adTypeId === '') {
            newErrors.AdTypeId = t("Ce champ est obligatoire *")
            console.log('USE AdTypeId ! ', refAttributs.current.AdTypeIdRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.AdTypeIdRef;
        } else {
            const { AdTypeId, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }


        const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

        if (!url || url === '') {
            newErrors.Url = t("Ce champ est obligatoire *")
            console.log('USE Url ! ', refAttributs.current.UrlRef);
            if (!firstErrorRef) firstErrorRef = refAttributsAd.current.UrlRef;
        }
        else if (!urlRegex.test(url)) {

            const lines = url.split('\n');
            let isInvalid = false;

            lines.forEach((line, index) => {
                if (!urlRegex.test(line)) {
                    isInvalid = true;
                }
            });
            if (isInvalid) {
                newErrors.Url = t("Le lien que vous avez entré est invalide. Veuillez saisir un lien valide.");
                console.log('USE Url ! ', refAttributs.current.UrlRef);
                if (!firstErrorRef) firstErrorRef = refAttributsAd.current.UrlRef;
            } else {
                const { Url, ...updatedErrors } = newErrors;
                newErrors = updatedErrors;
            }
        } else {
            const { Url, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }


        let MetaData;  // Change 'const' to 'let'

        if (metadata && metadata.trim()) {
            console.log('hh : ', typeof metadata);

            try {
                MetaData = JSON.parse(metadata.trim());  // Now 'metadata' can be reassigned
                console.log('hh metadata après parse : ', typeof MetaData);
                const { Metadata, ...updatedErrors } = newErrors;
                newErrors = updatedErrors;
            } catch (error) {
                newErrors.Metadata = t('Objet JSON non valide');
                console.log('Erreur de parsing metadata : ', error.message);
                if (!firstErrorRef) firstErrorRef = refAttributsAd.current.MetadataRef;
            }

            if (typeof MetaData === 'object' && !Array.isArray(MetaData)) {
                console.log('Metadata est un objet valide : ', MetaData);
                const { Metadata, ...updatedErrors } = newErrors;
                newErrors = updatedErrors;
            }
        } else {
            const { Metadata, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        // if (metadata && metadata.trim()) {
        //     console.log('hh : ', typeof metadata);

        //     // Si c'est une chaîne, essayer de la convertir en objet JSON
        //     try {
        //         metadata = JSON.parse(metadata.trim());  // On utilise trim() pour enlever les espaces
        //         console.log('hh metadata après parse : ', typeof metadata);
        //     } catch (error) {
        //         // Si la chaîne JSON est invalide, afficher l'erreur et ajouter un message d'erreur
        //         newErrors.Metadata = t('Objet JSON non valide');
        //         console.log('Erreur de parsing metadata : ', error.message);  // Afficher le message d'erreur pour plus de clarté
        //         if (!firstErrorRef) firstErrorRef = refAttributsAd.current.MetadataRef;
        //     }

        //     // Si c'est déjà un objet ou après parsing, vérifier que c'est un objet valide
        //     if (typeof metadata === 'object' && !Array.isArray(metadata)) {
        //         console.log('Metadata est un objet valide : ', metadata);
        //     } else {
        //         newErrors.Metadata = t('Le JSON doit être un objet');
        //     }

        // } else {
        //     // Si 'metadata' est un objet valide ou est absent, on enlève l'erreur de Metadata
        //     const { Metadata, ...updatedErrors } = newErrors;
        //     newErrors = updatedErrors;
        // }



        setErrorsAd(newErrors);
        console.log(newErrors);
        if (firstErrorRef) {
            console.log('Scrolling/focusing on:', firstErrorRef);
            firstErrorRef.focus();
        }
        else if (!firstErrorRef) {
            if (parseInt(Id) !== 0) {
                setManageAdLoading(true)
                try {
                    let idCreative
                    let creative
                    await dataCreativeMaps.map((val, key) => {
                        if (key === keyAdSelected) {
                            idCreative = val.Creative.Id
                            creative = {
                                ...val.Creative,
                                FieldName: format === "Image" ? fieldName : '',
                                ImageLink: fieldName ? '' : imageLink,
                                ImageName: format === "Image" ? val.ImageName : '',
                                Title: title,
                                AdTypeId: parseInt(adTypeId),
                                Url: url,
                                Alt: altText,
                                Metadata: metadata,
                                TemplateValues: format === "Video" ?
                                    JSON.stringify({
                                        ctPlayback_URL: linkAsset,
                                        ctduration: ctDuration
                                    }) : null, // Conversion de TemplateValues en chaîne JSON encodée,
                                TemplateId: format === "Video" ? idTemplate : null,
                            }
                            console.log('creativecreativecreativecreative : ', creative);

                        }
                    })

                    console.log('dataCreativeMapsdataCreativeMapsdataCreativeMaps : ', dataCreativeMaps);

                    let dataImagesAdded;
                    const formData = new FormData();
                    if (fieldName) {

                        formData.append('image', fieldName);
                        formData.append('index', idCreative);


                        try {
                            const response = await axiosClient.post(`upload-image/`, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            });
                            dataImagesAdded = response.data;
                            console.log(response.data);
                        } catch (error) {
                            setManageAdLoading(false)
                            console.error('Error uploading image:', error.response ? error.response.data : error.message);
                            throw error; // Rethrow error to stop execution and handle it in the calling function
                        }

                    }
                    try {

                        const data = {
                            dataCreativeMaps: creative,
                            dataFiles: format === "Image" && dataImagesAdded,
                        };
                        axiosClient.put(`edit-Ad/${parseInt(idCreative)}/${parseInt(Id)}/`, data).then((res) => {
                            handleCloseUpdateAddModal()
                            setManageAdLoading(false)
                            getDataAds()
                            console.log('RRRRRRRRRRRRRRRRRRRRRR : ', res.data);

                            toast.success(t("L'annonce est modifiée avec succès"), {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });

                        }).catch(error => {
                            console.log('ERROOOOOOOOR : ', error);

                            setManageAdLoading(false)
                            console.error('Error creating flight:', error.response.data.response.Errors[0].message);
                            newErrors.FieldName = JSON.stringify(error.response.data.response.Errors[0].message)
                            console.log('USE REF SiteZoneTargeting ! ', refAttributsAd.current.FieldNameRef);
                            firstErrorRef = refAttributsAd.current.FieldNameRef;
                            setErrors(newErrors);
                            if (firstErrorRef) {
                                console.log('Scrolling/focusing on:', firstErrorRef);
                                firstErrorRef.focus();
                            }
                        })
                    } catch (error) {
                        setManageAdLoading(false)
                        console.error('Error creating flight:', error.response.data.response.Errors[0].message);
                        newErrors.FieldName = JSON.stringify(error.response.data.response.Errors[0].message)
                        console.log('USE REF SiteZoneTargeting ! ', refAttributsAd.current.FieldNameRef);
                        firstErrorRef = refAttributsAd.current.FieldNameRef;
                        setErrors(newErrors);
                        if (firstErrorRef) {
                            console.log('Scrolling/focusing on:', firstErrorRef);
                            firstErrorRef.focus();
                        }
                        // throw error; // Rethrow to handle it in the calling function
                    }

                } catch (error) {
                    setManageAdLoading(false)
                    console.log('ERROR UPDATE AD FUNCTION : ', error);

                }
            }
            else {

                await setDataCreativeMaps(dataCreativeMaps.map((val, key) => {
                    if (key === keyAdSelected) {
                        setIdsCreativeUpdated([...IdsCreativeUpdated, val.Id])
                        return {
                            ...val,
                            Creative: {
                                ...val.Creative,
                                FieldName: format === "Image" ? fieldName : '',
                                ImageLink: fieldName ? '' : imageLink,
                                ImageName: '',
                                Title: title,
                                AdTypeId: parseInt(adTypeId),
                                Url: url,
                                Alt: altText,
                                Metadata: metadata,
                                TemplateValues: format === "Video" ?
                                    JSON.stringify({
                                        ctPlayback_URL: linkAsset,
                                        ctduration: ctDuration
                                    }) : null, // Conversion de TemplateValues en chaîne JSON encodée,
                                TemplateId: format === "Video" ? idTemplate : null,
                            }
                        }
                    }
                    return val
                }))
                handleCloseUpdateAddModal()
            }
        }


    }

    const deletedAd = async () => {

        try {
            setLoadingAds(true)
            if (parseInt(Id) === 0) {
                const data = dataCreativeMaps.filter((_, e) => e !== idAdSelected)
                setDataCreativeMaps(data)
                setIdAdSelected()
                handleCloseDeleteAdModal()
                setLoadingAds(false)
            }
            else {
                await axiosClient.get(`delete-Ad/${parseInt(Id)}/${parseInt(idAdSelected)}/`).then(() => {
                    const data = dataCreativeMaps.filter((e) => e.Id !== idAdSelected)
                    setDataCreativeMaps(data)
                    setIdAdSelected()
                    handleCloseDeleteAdModal()
                    setLoadingAds(false)

                    toast.success(t("L'annonce est supprimée avec succès"), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                })
            }
        } catch (error) {
            console.log('ERROR : ', error);
            setLoadingAds(false)

        }
    }

    const updateIsActive = async (id) => {
        axiosClient.put(`update-isActive-Ad/${Id}/${id}/`)
            .then(res => {
                setDataCreativeMaps(dataCreativeMaps.map(item => {
                    if (item.Id === res.data.Id) {
                        return {
                            ...item,
                            IsActive: res.data.IsActive
                        }
                    }
                    return item
                }))
                if (res.data.IsActive) {
                    toast.success(t("L'annonce est activée"), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.success(t("L'annonce est désactivée"), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                }

            }).catch(err => {
                console.error('ERROOR : ', err)

            })
    }

    useEffect(() => {
        console.log('IdsCreativeUpdated FROM UPDATE AD FUNCTION : ', IdsCreativeUpdated);
        console.log('dataCreativeMaps FROM UPDATE AD FUNCTION : ', dataCreativeMaps);

    }, [IdsCreativeUpdated, dataCreativeMaps])

    // -------------------- Fix Edit Budget Button -------------------------
    const [buttonEditBudget, setButtonEditBudget] = useState(false);

    useEffect(() => {
        const onPageScroll = () => {
            if (window.pageYOffset > 30) {
                setButtonEditBudget(true);
            } else {
                setButtonEditBudget(false);
            }
        };
        window.addEventListener("scroll", onPageScroll);
        return () => {
            window.removeEventListener("scroll", onPageScroll);
        };
    }, []);

    const [upgradeBudgetFlightModal, setUpgradeBudgetFlightModal] = useState(false);
    const handleCloseUpgradeBudgetFlightModal = () => {
        setUpgradeBudgetFlightModal(false)
        setNewBudget()
    };
    const handleShowUpgradeBudgetFlightModal = () => setUpgradeBudgetFlightModal(true);


    //Update LifetimeCapAmount
    const [upgradeBudgetLoading, setUpgradeBudgetLoading] = useState(false)
    const [formDataUpdateBudget, setFormDataUpdateBudget] = useState({})
    const updateLifetimeCapAmount = async () => {
        setUpgradeBudgetLoading(true)
        try {

            if (!stripe || !elements || !flightFormData?.LifetimeCapAmount) {
                setUpgradeBudgetLoading(false)
                return;
            }
            // Ensure PaymentElement is properly initialized
            const { error: submitError } = await elements.submit();

            if (submitError) {
                console.log('[submitError]', submitError);
                return;
            }

            console.log('flightFormData : ', flightFormData);

            const response = await axiosClient.put(`edit-budget-flight/${Id}/`, formDataUpdateBudget).catch(error => {
                console.log('ERROR: ', error);
                setUpgradeBudgetLoading(false)
            })

            if (response.data) {
                console.log('flightFormData RES.DATA : ', response.data, ' | newBudget :', newBudget);

                try {
                    const { data } = await axiosClient.post('/create-payment-intent', {
                        user: user?.id,
                        flight: response?.data?.Id,
                        amount: newBudget,
                        // oldAmount: oldBudget,
                        currency: 'usd',
                    })


                    const { clientSecret } = data;

                    // Confirm the payment with the fetched clientSecret
                    const { error } = await stripe.confirmPayment({
                        elements,
                        clientSecret,
                        confirmParams: {
                            return_url: window.location.href,
                        },
                    });
                    handleCloseUpgradeBudgetFlightModal()
                    setFormDataUpdateBudget({})
                    if (error) {
                        console.log('[error]', error);
                    } else {
                        console.log('Payment successful');
                    }

                } catch (error) {
                    setUpgradeBudgetLoading(false)
                    console.error('Error fetching client secret:', error);

                }
            }
        } catch (error) {
            console.log('ERROR: ', error);
            setUpgradeBudgetLoading(false)
        }
    }

    const percentageDifference = (revenue, lifetimeCapAmount) => {
        if (revenue !== 0 && lifetimeCapAmount !== 0) {
            if ((((revenue) / lifetimeCapAmount) * 100) >= percentageDifferenceRevenueBudget) {
                return <LuAlertCircle size={22} color='#941f1f' />
            }
        }
        return null
    }


    const getGlobalData = async () => {
        setGlobalLoading(true)
        await getDataFlight()
        await getDataSiteZoneFlight()
        await getDataGeoTargetingFlight()
        await fetchDataZones()
        setGlobalLoading(false)
    }

    useEffect(() => {
        // if (parseInt(Id) !== 0) {
        //     getGlobalData()
        // }
        getGlobalData()
        getPriorities()
        // setLoading(false)
    }, [])


    return (
        <>
            {!globalLoading && flightFormData ?
                <div style={{ position: 'relative' }}>

                    <ToastContainer />

                    {parseInt(Id) > 0 &&
                        <div style={{
                            margin: '10px 0px',
                            display: 'flex',
                            justifyContent: "end",
                            width: buttonEditBudget ? "auto" : "100%",
                            position: buttonEditBudget ? "fixed" : null,
                            top: buttonEditBudget ? 65 : null,
                            right: 18,
                            zIndex: buttonEditBudget && 3,
                        }}>
                            <Tooltip title={`${t("Revenue")} : $ ${r} / Budget : $ ${flightFormData?.LifetimeCapAmount}`}>
                                <Button onClick={handleShowUpgradeBudgetFlightModal}>
                                    {percentageDifference(r, flightFormData?.LifetimeCapAmount)}
                                    <span className='mx-2'>{t("Augmenter le budget")}</span>
                                </Button>
                            </Tooltip>
                        </div>
                    }

                    <Accordion
                        defaultActiveKey={[
                            '0',
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                        ]}
                        alwaysOpen>

                        <Accordion.Item className='mb-4' eventKey="2">
                            <Accordion.Header>{t("Support")}</Accordion.Header>
                            <Accordion.Body style={{ padding: 0 }}>


                                <Form.Group className="px-4 my-3" controlId="formBasicKeywords">

                                    <Form.Select
                                        ref={(e) => refAttributs.current.SiteZoneTargetingRef = e}
                                        name='siteIdSelected'
                                        value={siteIdSelected}
                                        onChange={(e) => {
                                            // let updatedSiteZoneTargeting
                                            // if (flightFormData?.SiteZoneTargeting?.length) {
                                            //     updatedSiteZoneTargeting = [...flightFormData?.SiteZoneTargeting];
                                            //     updatedSiteZoneTargeting[0] = {
                                            //         ...updatedSiteZoneTargeting[0],
                                            //         IsExclude: false,
                                            //         SiteId: parseInt(e.target.value),
                                            //         // ZoneId: parseInt(e.target.value),
                                            //         // Name: e.target.options[e.target.selectedIndex].text
                                            //     };
                                            // } else {
                                            //     updatedSiteZoneTargeting = [{
                                            //         IsExclude: false,
                                            //         SiteId: parseInt(e.target.value),
                                            //         // ZoneId: parseInt(e.target.value),
                                            //         // Name: e.target.options[e.target.selectedIndex].text
                                            //     }]
                                            // }


                                            setSiteIdSelected(parseInt(e.target.value))
                                            if (parseInt(e.target.value) === idSiteDAB) {
                                                setDataZoneDAB([...dataZoneDAB.filter(e => e.SiteId === idSiteDAB), {
                                                    SiteId: parseInt(e.target.value),
                                                    ZoneId: null
                                                }])
                                            } else {
                                                setDataZoneDAB([{
                                                    SiteId: parseInt(e.target.value),
                                                }])
                                            }
                                        }}
                                    >
                                        <option> -- {t('Choisir')} -- </option>
                                        {/* {dataZones
.filter((zone) => zone.SiteId === dataSites.find((site) => site.Title === 'Web')?.Id)
.map((zone, key) => (
<option key={key} value={zone.Id} title={zone.Name}>
{zone.Name}
</option>
))} */}

                                        {dataSites.map((val, key) => {
                                            return (
                                                <option key={key} value={val.Id} title={val.Title}>
                                                    {val.Title}
                                                </option>
                                            )
                                        })}
                                    </Form.Select>

                                    {errors?.SiteZoneTargeting && <Form.Text className="text-danger">{errors?.SiteZoneTargeting}</Form.Text>}
                                </Form.Group>

                                {siteIdSelected === parseInt(idSiteDAB) &&
                                    dataZoneDAB.map((v, k) => {
                                        return (
                                            <div key={k} className='px-4 my-3 d-flex w-100 gap-2'>
                                                <Form.Group className=" w-100" controlId="formBasicKeywords">
                                                    <Form.Select
                                                        name='ZoneId'
                                                        value={v.ZoneId}
                                                        onChange={(e) => {
                                                            handleChangeSelectRadio(e, k)
                                                        }}
                                                    >
                                                        {!v.ZoneId && <option> -- {t('Choisir')} -- </option>}
                                                        {dataZones
                                                            // .filter(
                                                            //     zone=>{
                                                            //         !dataZoneDAB.some((item) => parseInt(item.ZoneId) === parseInt(zone.Id) && zone !== k)
                                                            //     }
                                                            // )
                                                            .map((zone, key) => (
                                                                <option key={key} value={zone.Id} title={zone.Name}>
                                                                    {zone.Name}
                                                                </option>
                                                            ))}
                                                    </Form.Select>

                                                    {errors?.SiteZoneTargeting && <Form.Text className="text-danger">{errors?.SiteZoneTargeting}</Form.Text>}
                                                </Form.Group>
                                                {dataZoneDAB.length > 1 && <Button variant="danger" onClick={() => {
                                                    removeDataZoneDAB(k)
                                                }}>{t("Supprimer")}</Button>}
                                                {k === dataZoneDAB.length - 1 && <Button disabled={!v.ZoneId} onClick={() => {
                                                    addDataZoneDAB()
                                                }}>{t("Ajouter")}</Button>}
                                            </div>
                                        )
                                    }
                                    )}

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{t("Paramètres généraux")}</Accordion.Header>
                            <Accordion.Body>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>{t("Titre")}</Form.Label>
                                    <Form.Control
                                        ref={(e) => refAttributs.current.NameRef = e}
                                        type="text"
                                        placeholder={t("Titre")}
                                        value={flightFormData?.Name}
                                        onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'Name': e.target.value,
                                                'PriorityId': dataPriorities[0].Id
                                            })

                                        }
                                        } />
                                    {errors?.Name && <Form.Text className="text-danger">{errors?.Name}</Form.Text>}
                                </Form.Group>

                                {/* <Form.Group className="mb-3" controlId="formBasiPriority">
                                    <Form.Label>Priority</Form.Label>
                                    <Form.Select
                                        ref={(e) => refAttributs.current.PriorityIdRef = e}
                                        value={flightFormData?.PriorityId}
                                        onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'PriorityId': parseInt(e.target.value)
                                            })

                                        }
                                        } >
                                        <option value=''>-- Choose --</option>
                                        {dataPriorities.map((val, key) => {
                                            return (
                                                <option key={key} value={val.Id}>
                                                    <span>{val.Name}</span>
                                                    <em>(All sites)</em>
                                                </option>
                                            )
                                        })}
                                    </Form.Select>
                                    {errors?.PriorityId && <Form.Text className="text-danger">{errors?.PriorityId}</Form.Text>}
                                </Form.Group> */}

                                <div className='d-flex flex-column flex-md-row gap-2 mb-3'>
                                    <Form.Group className="w-100" controlId="formBasicTimeZone">
                                        <Form.Label>{t("Fuseau horaire")}</Form.Label>

                                        <Form.Select value={flightFormData?.TimeZone} onChange={handleTimeZoneChange}>
                                            {DataTimeZone.map((val, key) => {
                                                return (
                                                    <option key={key} value={val.value} title={val.label}>{val.label} | {val.value}</option>
                                                )
                                            })}
                                        </Form.Select>

                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicStartDate">
                                        <Form.Label>{t("Date de début")}</Form.Label>
                                        <Form.Control type="datetime-local" value={flightFormData.StartDate} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'StartDate': e.target.value,
                                                'StartDateISO': e.target.value,
                                                'EndDate': '',
                                                'EndDateISO': '',
                                            })

                                        }
                                        } />
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicEndDate">
                                        <Form.Label>{t("Date de fin")}</Form.Label>
                                        <Form.Control ref={(e) => refAttributs.current.EndDateRef = e} type="datetime-local" min={flightFormData.StartDate ? new Date(new Date(flightFormData.StartDate).getTime() + 24 * 60 * 60 * 1000).toISOString().slice(0, 16) : undefined} value={flightFormData?.EndDate} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'EndDate': e.target.value,
                                                'EndDateISO': e.target.value,
                                            })

                                        }
                                        } />
                                        {errors?.EndDate && <Form.Text className="text-danger">{errors?.EndDate}</Form.Text>}
                                    </Form.Group>
                                </div>
                                <div className='d-flex flex-column flex-md-row gap-2 mb-3'>
                                    <Form.Group className="w-100" controlId="formBasicRate">
                                        <Form.Label>{t("Taux")}</Form.Label>

                                        <Form.Select value={flightFormData?.RateType} onChange={e => {
                                            if ([4, 5, 6].includes(parseInt(flightFormData?.RateType))) {
                                                console.log('YEEEEEEEEEEs');

                                                setFlightFormData({
                                                    ...flightFormData,
                                                    'RateType': parseInt(e.target.value),
                                                    'IsTrackingConversions': true
                                                })

                                            } else {
                                                console.log('NOOOOOOOOOoooooo');
                                                setFlightFormData({
                                                    ...flightFormData,
                                                    'RateType': parseInt(e.target.value)
                                                })

                                            }
                                        }
                                        } >
                                            <option value={1}>
                                                <span>{t("Plat")}</span>
                                            </option>
                                            <option value={2}>
                                                <span>CPM</span>
                                            </option>
                                            <option value={3}>
                                                <span>CPC</span>
                                            </option>
                                            <option value={4}>
                                                <span>CPA ({t("Afficher")})</span>
                                            </option>
                                            <option value={5}>
                                                <span>CPA ({t("Cliquer")})</span>
                                            </option>
                                            <option value={6}>
                                                <span>CPA ({t("Afficher")} & {t("Cliquer")})</span>
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicPrice">
                                        <Form.Label>{t("Prix")}</Form.Label>
                                        <Form.Control ref={(e) => refAttributs.current.PriceRef = e} type="number" min={10} placeholder={t("Prix")} value={flightFormData?.Price}

                                            onChange={(e) => setFlightFormData({ ...flightFormData, Price: e.target.value })}
                                            onBlur={(e) => {
                                                if (e.target.value < 10) {
                                                    setFlightFormData({ ...flightFormData, Price: 10 });
                                                }
                                            }} />
                                        {errors?.Price && <Form.Text className="text-danger">{errors?.Price}</Form.Text>}
                                    </Form.Group>



                                    {/* <Form.Group className="w-100" controlId="dailyRevenue">
                                        <Form.Label></Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            label="Track Conversions"
                                            checked={flightFormData?.IsTrackingConversions}
                                            onChange={e => {
                                                if (![4, 5, 6].includes(parseInt(flightFormData?.RateType))) {
                                                    console.log('NONONONONONONO');
                                                    setFlightFormData({
                                                        ...flightFormData,
                                                        'IsTrackingConversions': e.target.checked
                                                    })
                                                }
                                            }
                                            }
                                        />
                                    </Form.Group> */}

                                </div>

                                {/* <div className='d-flex flex-column flex-md-row gap-2 mb-3'>
                                    <Form.Group className="w-100" controlId="formBasicGoalType">
                                        <Form.Label>Goal Type</Form.Label>

                                        <Form.Select value={flightFormData?.GoalType} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'GoalType': parseInt(e.target.value)
                                            })

                                        }
                                        } >
                                            <option value={1}>
                                                <span>Impressions</span>
                                            </option>
                                            <option value={2}>
                                                <span>Percentage</span>
                                            </option>
                                            <option value={3}>
                                                <span>Click</span>
                                            </option>
                                            <option value={7}>
                                                <span>Conversions</span>
                                            </option>
                                            <option value={8}>
                                                <span>Revenue</span>
                                            </option>
                                            <option value={9}>
                                                <span>Daily Revenue</span>
                                            </option>
                                            <option value={10}>
                                                <span>Monthly Revenue</span>
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicGoalAmount">
                                        <Form.Label>Goal Amount</Form.Label>
                                        <Form.Control
                                            ref={(e) => refAttributs.current.ImpressionsRef = e}
                                            type="number"
                                            min={100}
                                            max={1000}
                                            placeholder="Goal Amount"
                                            value={flightFormData?.Impressions}
                                            onChange={e => {
                                                setFlightFormData({
                                                    ...flightFormData,
                                                    'Impressions': e.target.value
                                                })

                                            }
                                            } />

                                        {errors?.Impressions && <Form.Text className="text-danger">{errors?.Impressions}</Form.Text>}
                                    </Form.Group>
                                </div> */}

                                {/* <div className='d-flex flex-column flex-md-row gap-2 mb-3'>
                                    <Form.Group className="w-100" controlId="formBasicCapType">
                                        <Form.Label>Cap Type</Form.Label>

                                        <Form.Select value={flightFormData?.CapType} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'CapType': parseInt(e.target.value)
                                            })

                                        }
                                        } >
                                            <option value=''>
                                                <span>None</span>
                                            </option>
                                            <option value={1}>
                                                <span>Impressions</span>
                                            </option>
                                            <option value={2}>
                                                <span>Click</span>
                                            </option>
                                            <option value={3}>
                                                <span>Converions</span>
                                            </option>
                                            <option value={4}>
                                                <span>Budget</span>
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicDaily">
                                        <Form.Label>Daily</Form.Label>
                                        <Form.Control ref={(e) => refAttributs.current.DailyCapAmountRef = e} type="text" placeholder="Daily" value={flightFormData?.DailyCapAmount} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'DailyCapAmount': e.target.value,
                                                'DailyCapAmountDecimal': parseFloat(e.target.value).toFixed(2),
                                            })

                                        }
                                        } />
                                        {errors?.DailyCapAmount && <Form.Text className="text-danger">{errors?.DailyCapAmount}</Form.Text>}
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicLifetime">
                                        <Form.Label>Lifetime</Form.Label>
                                        <Form.Control ref={(e) => refAttributs.current.LifetimeCapAmountRef = e} type="text" placeholder="Lifetime" value={flightFormData?.LifetimeCapAmount} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'LifetimeCapAmount': e.target.value,
                                                'LifetimeCapAmountDecimal': parseFloat(e.target.value).toFixed(2),
                                            })

                                        }
                                        } />
                                        {errors?.LifetimeCapAmount && <Form.Text className="text-danger">{errors?.LifetimeCapAmount}</Form.Text>}
                                    </Form.Group>
                                </div> */}

                            </Accordion.Body>
                        </Accordion.Item>

                        {/* <Accordion.Item className='mt-4' eventKey="2">
                    <Accordion.Header>Behavioral Targeting</Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                        <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                            <strong>Behavioral Targeting delivers ads to users based on their interests, as determined by their interactions with other ads.</strong><br />
                            <span>UserDB is required for storing interests.</span>
                        </Alert>

                        <div className='px-4'>
                            <div className='d-flex flex-wrap gap-1'>
                                <span>When a user</span>
                                <div>
                                    <Form.Group className="px-2 " controlId="clicks1" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="clicks" />
                                    </Form.Group>
                                </div>
                                <span>or</span>
                                <div>
                                    <Form.Group className="px-2" controlId="converts1" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="converts" />
                                    </Form.Group>
                                </div>
                                <span>
                                    on an ad from this flight <strong>stop showing</strong> them ads in this <strong>flight</strong>.
                                </span>
                            </div>
                        </div>

                        <div className='mt-1 px-4'>
                            <div className='d-flex flex-wrap gap-1'>
                                <span>When a user</span>
                                <div>
                                    <Form.Group className="px-2 " controlId="clicks2" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="clicks" />
                                    </Form.Group>
                                </div>
                                <span>or</span>
                                <div>
                                    <Form.Group className="px-2" controlId="converts2" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="converts" />
                                    </Form.Group>
                                </div>
                                <span>
                                    on an ad from this flight <strong>stop showing</strong> them ads from this <strong>advertiser</strong>.
                                </span>
                            </div>
                        </div>

                        <div className='mt-1 px-4'>
                            <div className='d-flex flex-wrap gap-1'>
                                <span>When a user</span>
                                <div>
                                    <Form.Group className="px-2 " controlId="clicks3" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="clicks" />
                                    </Form.Group>
                                </div>
                                <span>or</span>
                                <div>
                                    <Form.Group className="px-2" controlId="converts3" style={{ background: 'lightgray' }}>
                                        <Form.Check type="checkbox" label="converts" />
                                    </Form.Group>
                                </div>
                                <span>
                                    on an ad from this flight <strong>store this flight's categories</strong> as an interest for them.
                                </span>
                            </div>
                        </div>

                        <Form.Group className="px-4 my-3" controlId="formBasicCategories">
                            <Form.Label>Categories</Form.Label>
                            <Form.Control type="text" placeholder="Categories" />
                        </Form.Group>

                    </Accordion.Body>
                </Accordion.Item> */}

                        {dataSites.find(e => e.Id === parseInt(siteIdSelected))?.Title === 'Radio Karaoke' &&
                            <>
                                <Accordion.Item className='mt-4' eventKey="3">
                                    <Accordion.Header>{t("Ciblage par mots-clés")}</Accordion.Header>
                                    <Accordion.Body style={{ padding: 0 }}>
                                        <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                                            <strong>{t("Le ciblage par mots clés est un filtre que vous pouvez appliquer à un groupe d'annonces ou à une annonce afin que les annonces du groupe d'annonces soient diffusées uniquement aux emplacements où ces mots clés sont transmis dans le code d'annonce ou la demande d'API de décision")}.</strong><br />
                                            <span>{t("Si aucun mot clé n'est défini, ce groupe d'annonces sera éligible à la diffusion quels que soient les mots clés transmis avec la demande")}.</span>
                                        </Alert>

                                        {dataKeywords.map((val, key) => {
                                            return (
                                                <div key={key} className='d-flex flex-column flex-md-row col-12'>
                                                    <Form.Group className="px-4 mb-3 col-12 col-md-4" controlId="formBasicKeywords">
                                                        <Form.Label>{t("Opérateur")}</Form.Label>
                                                        <Form.Select
                                                            // as="textarea"
                                                            name="operator"
                                                            // style={{ height: '100px', resize: 'none' }}
                                                            value={val.operator}
                                                            onChange={(e) => handleChangeKeywords(e, key)}
                                                        >
                                                            <option value=''>{t("Sélectionner l'opérateur")}</option>
                                                            <option value={'!'}>{t("Exclure")}</option>
                                                            <option value={','}>{t("Et")}</option>
                                                            {key !== 0 && <option value={'\n'}>{t("Ou")}</option>}
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group className="px-4 col-12 col-md-4" controlId="formBasicKeywords">
                                                        <Form.Label>{t("Mot-clé")}</Form.Label>
                                                        <Form.Control
                                                            // as="textarea"
                                                            name="keyword"
                                                            // style={{ height: '100px', resize: 'none' }}
                                                            value={val.keyword}
                                                            onChange={(e) => handleChangeKeywords(e, key)}
                                                        />
                                                    </Form.Group>

                                                    <div className='px-4 my-4 col-12 col-md-4'>
                                                        {dataKeywords.length - 1 === key && <Button className='' style={{ marginRight: 5 }} onClick={addKeywords}>{t("Ajouter")}</Button>}
                                                        {dataKeywords.length > 1 && <Button variant="danger" onClick={() => removeKeywords(key)}>{t("Supprimer")}</Button>}
                                                    </div>

                                                    {/* <Form.Group className="px-4 mb-3" controlId="formBasicKeywords">
                                                <Form.Label></Form.Label>
                                                {dataKeywords.length - 1 === key && <Button onClick={addKeywords}>Add</Button>}
                                            </Form.Group>

                                            <Form.Group className="px-4 mb-3" controlId="formBasicKeywords">
                                                <Form.Label></Form.Label>
                                                {dataKeywords.length > 1 && <Button onClick={() => removeKeywords(key)}>Delete</Button>}
                                            </Form.Group> */}

                                                </div>
                                            )
                                        })}

                                        {/* <Form.Group className="px-4 mb-3" controlId="formBasicKeywords">
                                    <Form.Label>Keywords</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                        value={flightFormData?.Keywords} onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'Keywords': e.target.value
                                            })
                                        }
                                        }
                                    />
                                </Form.Group> */}

                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item className='mt-4' eventKey="4">
                                    <Accordion.Header>{t("Ciblage personnalisé")}</Accordion.Header>
                                    <Accordion.Body style={{ padding: 0 }}>
                                        <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                                            <strong>{t("Le ciblage personnalisé vous permet de configurer des règles pour cibler certaines combinaisons de paires clé/valeur")}.</strong>
                                        </Alert>

                                        {myDataSection.map((val, key) => {
                                            return (
                                                <div key={key}>

                                                    {val.operatorGlobal &&
                                                        <>

                                                            <div className='col-12' style={{ height: 1, border: '1px solid gray', borderRadius: 10, }} />
                                                            {/* <div className='d-flex my-3 col-12'>
                                                                <Form.Group className="px-4 col-12 col-md-4" controlId="formBasicKeywords">
                                                                    <Form.Label>{t('Logic Operator')}</Form.Label>
                                                                    <Form.Select
                                                                        name="logicOperator"
                                                                        value={val.operatorGlobal}
                                                                        onChange={(e) => handleInputChangeGlobalSection(e, key)}
                                                                    >
                                                                        <option value='and'>{t('Et')}</option>
                                                                        <option value='or'>{t('Ou')}</option>

                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </div> */}
                                                        </>
                                                    }
                                                    <div className='mt-3'>
                                                        {val.section.map((v, k) => {
                                                            return (
                                                                <div key={k} className='mx-4 d-flex flex-column flex-md-row  gap-2 col-md-12 '>
                                                                    {v.logicOperator && <Form.Group className=" mb-3 col-md-2" controlId="formBasicKeywords">
                                                                        <Form.Label>{t('Logic Operator')}</Form.Label>
                                                                        <Form.Select
                                                                            // as="textarea"
                                                                            name="logicOperator"
                                                                            // style={{ height: '100px', resize: 'none' }}
                                                                            value={v.logicOperator}
                                                                            onChange={(e) => handleInputChangeSection(e, key, k)}
                                                                        >
                                                                            <option value='and'>{t("Et")}</option>
                                                                            <option value='or'>{t("Ou")}</option>
                                                                            {/* <option value='and not'>{t("Et non")}</option> */}

                                                                        </Form.Select>
                                                                    </Form.Group>}
                                                                    <Form.Group className=" mb-3 col-md-2" controlId="formBasicKeywords">
                                                                        <Form.Label>{t("Étiquette")}</Form.Label>
                                                                        <Form.Select
                                                                            // as="textarea"
                                                                            name="label"
                                                                            // style={{ height: '100px', resize: 'none' }}
                                                                            value={v.label}
                                                                            onChange={(e) => handleInputChangeSection(e, key, k)}
                                                                        >
                                                                            <option value=''>-- {t("Choisir")} --</option>
                                                                            <option value='age'>{t("âge")}</option>
                                                                            <option value='sexe'>{t("sexe")}</option>
                                                                            <option value='categorie'>{t("Catégorie socioprofessionnelle")}</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    <Form.Group className=" mb-3 col-md-2" controlId="formBasicKeywords">
                                                                        <Form.Label>{t("Opérateur")}</Form.Label>
                                                                        {v.label === "age"
                                                                            ? <Form.Select
                                                                                // as="textarea"
                                                                                name="operator"
                                                                                // style={{ height: '100px', resize: 'none' }}
                                                                                value={v.operator}
                                                                                onChange={(e) => handleInputChangeSection(e, key, k)}
                                                                            >
                                                                                <option value=''>{t("Sélectionner l'opérateur")}</option>
                                                                                <option value={'='}>=</option>
                                                                                <option value={'<>'}>{'<>'}</option>
                                                                                <option value={'<'}>{'<'}</option>
                                                                                <option value={'<='}>{'<='}</option>
                                                                                <option value={'>'}>{'>'}</option>
                                                                                <option value={'>='}>{'>='}</option>
                                                                            </Form.Select>
                                                                            : <Form.Control
                                                                                name="operator"
                                                                                value={"="}
                                                                                disabled={true}
                                                                            />}
                                                                    </Form.Group>
                                                                    <Form.Group className=" col-md-2" controlId="formBasicKeywords">
                                                                        <Form.Label>{t('Valeur')}</Form.Label>
                                                                        {v.label === "age"
                                                                            ? <Form.Control
                                                                                // as="textarea"
                                                                                name="value"
                                                                                // style={{ height: '100px', resize: 'none' }}
                                                                                value={v.value}
                                                                                onChange={(e) => handleInputChangeSection(e, key, k)}
                                                                            />
                                                                            : <Form.Select
                                                                                // as="textarea"
                                                                                name="value"
                                                                                // style={{ height: '100px', resize: 'none' }}
                                                                                value={v.value}
                                                                                onChange={(e) => handleInputChangeSection(e, key, k)}
                                                                            >
                                                                                <option value=''>-- {t("Choisir")} --</option>
                                                                                {v.label === "sexe"
                                                                                    ? <>
                                                                                        <option value={'homme'}>{t('homme')}</option>
                                                                                        <option value={'femme'}>{t('femme')}</option>
                                                                                        <option value={'non-binaire'}>{t('non-binaire')}</option>
                                                                                    </>
                                                                                    : <>
                                                                                        <option value={'groupe1'}>{t('Agriculteurs exploitants / Agricultrices exploitantes')}</option>
                                                                                        <option value={'groupe2'}>{t("Artisans / Artisanes, commerçants / commerçantes et chefs / cheffes d'entreprise")}</option>
                                                                                        <option value={'groupe3'}>{t("Cadres et professions intellectuelles supérieures")}</option>
                                                                                        <option value={'groupe4'}>{t("Professions intermédiaires")}</option>
                                                                                        <option value={'groupe5'}>{t("Employés / Employées")}</option>
                                                                                        <option value={'groupe6'}>{t("Ouvriers / Ouvrières")}</option>
                                                                                    </>}
                                                                            </Form.Select>
                                                                        }
                                                                    </Form.Group>
                                                                    <div className='my-4 col-12 col-md-4'>
                                                                        {val.section.length - 1 === k && <Button className='' style={{ marginRight: 5 }} onClick={() => addSection(key)}>{t('Ajouter')}</Button>}
                                                                        {k > 0 && <Button variant="danger" onClick={() => removeSection(key, k)}>{t('Supprimer')}</Button>}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className='m-4 '>

                                                        {myDataSection.length - 1 === key && <Button className='' style={{ marginRight: 5 }} onClick={addGlobalSection}>{t("Ajouter une section")}</Button>}
                                                        {key !== 0 && <Button variant="danger" onClick={() => removeGlobalSection(key)}>{t("Supprimer une section")}</Button>}
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        {formattedStringCustomTargeting && <Form.Control
                                            as="textarea"
                                            style={{ height: '100px', resize: 'none', paddingTop: 15 }}
                                            value={formattedStringCustomTargeting}
                                            disabled
                                        />}

                                        {/* <Form.Group className="px-4 mb-3" controlId="formBasicCustomTargeting">
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                        value={flightFormData?.CustomTargeting}
                                        onChange={e => {
                                            setFlightFormData({
                                                ...flightFormData,
                                                'CustomTargeting': e.target.value
                                            })
                                        }
                                        }
                                    />
                                </Form.Group> */}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </>
                        }


                        {/* <Accordion.Item className='mt-4' eventKey="5" style={{ zIndex: 0 }}>
                    <Accordion.Header>Frequency Capping</Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                        <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                            <strong>Cap the number of times the flight can be displayed to any particular person in a particular time period.</strong>
                        </Alert>
                        <div className='mb-3 px-4 d-flex flex-column gap-3'>
                            <Form.Group className="w-100" controlId="Enable frequency capping">
                                <Form.Check type="checkbox" label="Enable frequency capping" />
                            </Form.Group>
                            <Form.Group className="w-100" controlId="This flight should not affect the advertiser/campaign frequency cap">
                                <Form.Check type="checkbox" label="This flight should not affect the advertiser/campaign frequency cap" />
                            </Form.Group>
                        </div>
                    </Accordion.Body>
                </Accordion.Item> */}

                        {/* <Accordion.Item className='mt-4' eventKey="6">
                            <Accordion.Header>{t("Ciblage par site/zone")}</Accordion.Header>
                            <Accordion.Body style={{ padding: 0 }}>
                                <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                                    <strong>{t("Par défaut, votre groupe d'annonces s'affichera sur tous les sites et zones de la chaîne.")}</strong><br />
                                    <span>{t("Vous pouvez ici limiter votre diffusion par site, zone ou site et zone. Vous pouvez également cibler par site ou zone des créations spécifiques lorsque vous les ajoutez.")}</span>
                                </Alert>

                                <div className='px-4 my-3 d-flex flex-column flex-md-row gap-2'>
                                    <Form.Group className="w-100" controlId="formBasicInclude/Exclude1" ref={(e) => refAttributs.current.SiteZoneTargetingRef = e}>
                                        <Form.Label>{t("Inclure/exclure")}</Form.Label>
                                        <Form.Select value={siteTargetingFormData?.IsExclude} onChange={e => {
                                            setSiteTargetingFormData({
                                                ...siteTargetingFormData,
                                                'IsExclude': e.target.value === "true"
                                            })

                                        }
                                        }>
                                            <option value={false}>{t("Inclure")}</option>
                                            <option value={true}>{t("Exclure")}</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <div className="w-100" style={{ display: 'flex', gap: 5 }}>
                                        <Form.Group className="w-50" controlId="formBasicSite">
                                            <Form.Label>Site</Form.Label>
                                            <Form.Select value={siteTargetingFormData?.SiteId || ''} onChange={e => {
                                                setSiteTargetingFormData({
                                                    ...siteTargetingFormData,
                                                    'SiteId': parseInt(e.target.value),
                                                    'Title': e.target.options[e.target.selectedIndex].text
                                                })
                                                setIdSiteSelected(parseInt(e.target.value))
                                            }
                                            }>
                                                <option>-- {t("Choisir")} --</option>
                                                {dataSites.map((val, key) => {
                                                    return (
                                                        <option key={key} value={val.Id} title={val.title}>{val.Title}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="w-50" controlId="formBasicSite">
                                            <Form.Label>Zone</Form.Label>
                                            <Form.Select value={siteTargetingFormData?.ZoneId} onChange={e => {
                                                setSiteTargetingFormData({
                                                    ...siteTargetingFormData,
                                                    'ZoneId': parseInt(e.target.value),
                                                    'Name': e.target.options[e.target.selectedIndex].text
                                                })
                                            }
                                            }>
                                                <option>-- {t("Choisir")} --</option>
                                                {siteTargetingFormData?.SiteId
                                                    ? dataZones.filter(e => e.SiteId === siteTargetingFormData?.SiteId).map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.Id} title={val.Name}>{val.Name}</option>
                                                        )
                                                    })
                                                    :
                                                    <option disabled>{t("Sélectionnez un site pour voir les zones")}</option>
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <Form.Group className="w-25" controlId="formBasicZone">
                                        <Form.Label></Form.Label>
                                        <div className='w-100 p-0 mt-2' style={{ display: 'flex', alignItems: "center", gap: 20 }}>
                                            <Button className='w-100' onClick={() => {
                                                addSiteTargeting()
                                            }} disabled={!siteTargetingFormData?.SiteId || manageSiteTargetingLoading}>
                                                {manageSiteTargetingLoading ?
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        {t('Chargement')}...
                                                    </>
                                                    : <span style={{ fontWeight: 900 }}>{t('Ajouter')}</span>}
                                            </Button>

                                        </div>
                                    </Form.Group>
                                </div>

                                {errors?.SiteZoneTargeting &&
                                    <div className='mx-4'>
                                        <Alert color="danger">
                                            {errors?.SiteZoneTargeting}
                                        </Alert>
                                    </div>}

                                {dataSiteZoneTargeting?.length !== 0 && <Table className='container'>
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Site</th>
                                            <th>Zone</th>
                                            <th>Option</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataSiteZoneTargeting?.map((val, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{val.IsExclude ? t("Exclure") : t("Inclure")}</td>
                                                    <td>{val.Title ? val.Title : dataSites.find(e => e.Id === val.SiteId)?.Title}</td>
                                                    <td>{val.Name ? val.Name : dataZones.find(e => e.Id === val.ZoneId)?.Name}</td>
                                                    <td>
                                                        {val.Id && val?.Id === deleteSiteZoneTargetingselected
                                                            ?
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            :
                                                            <span class="mdi mdi-close" style={{ cursor: 'pointer' }} onClick={() => {
                                                                if (parseInt(Id) !== 0) {
                                                                    deleteSiteZoneTargeting(val.Id)
                                                                    setDeleteSiteZoneTargetingselected(val.Id)
                                                                } else {
                                                                    const data = dataSiteZoneTargeting.filter((_, index) => index !== key)
                                                                    setDataSiteZoneTargeting(data)
                                                                    setFlightFormData({
                                                                        ...flightFormData,
                                                                        'SiteZoneTargeting': data
                                                                    })
                                                                }
                                                            }}></span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>}

                            </Accordion.Body>
                        </Accordion.Item> */}
                        {dataSites.find(e => e.Id === parseInt(siteIdSelected))?.Title === 'DAB+' &&
                            <Accordion.Item className='mt-4' eventKey="7">
                                <Accordion.Header>{t("Ciblage géographique")}</Accordion.Header>
                                <Accordion.Body style={{ padding: 0 }}>
                                    <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                                        <strong>{t("Ciblez les utilisateurs en fonction de leur emplacement actuel.")}</strong><br />
                                    </Alert>

                                    <div className='px-4 my-3 d-flex flex-column flex-md-row gap-2'>
                                        <Form.Group className="w-100" controlId="formBasicInclude/Exclude2">
                                            <Form.Label>{t("Inclure/exclure")}</Form.Label>
                                            <Form.Select ref={(e) => refAttributs.current.GeoTargetingRef = e} value={geoTargetingFormData?.IsExclude} onChange={e => {
                                                setGeoTargetingFormData({
                                                    ...geoTargetingFormData,
                                                    'IsExclude': e.target.value === "true"
                                                })

                                            }
                                            }>
                                                <option value={false}>{t("Inclure")}</option>
                                                <option value={true}>{t("Exclure")}</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="w-100" controlId="formBasicCountry">
                                            <Form.Label>{t("Pays")}</Form.Label>
                                            <Form.Select ref={(e) => refAttributs.current.geoTargetingRef = e} value={geoTargetingFormData?.CountryCode || ''} onChange={e => {
                                                setErrors({})
                                                setGeoTargetingFormData({
                                                    ...geoTargetingFormData,
                                                    'CountryCode': e.target.value,
                                                    'CountryName': e.target.options[e.target.selectedIndex].text,
                                                    'Region': null,
                                                    'RegionName': '',
                                                    'MetroCode': null,
                                                    'MetroName': '',
                                                })

                                            }
                                            }>
                                                <option value=''>-- {t('Choisir')} --</option>
                                                {DataCountries.filter(country =>
                                                    dataSites.find(e => e.Id === parseInt(siteIdSelected))?.Title === 'DAB+' ?
                                                        (country.value === 'FR' || country.value === 'CH' || country.value === 'MC') : country
                                                ).map((val, key) => (
                                                    <option key={key} value={val.value} title={val.title}>{val.title}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="w-100" controlId="formBasicRegion">
                                            <Form.Label>{t('Région')}</Form.Label>
                                            <Form.Select ref={(e) => refAttributs.current.geoTargetingRef = e} value={geoTargetingFormData?.Region} onChange={e => {
                                                console.log('REEEEEEEGIONNn : ', e.target.value);
                                                setErrors({})
                                                setGeoTargetingFormData({
                                                    ...geoTargetingFormData,
                                                    'Region': e.target.value,
                                                    'RegionName': e.target.options[e.target.selectedIndex].text,
                                                    'MetroCode': null,
                                                    'MetroName': '',
                                                })

                                            }
                                            }>
                                                {!loadingDataRegions ?
                                                    geoTargetingFormData?.CountryCode ?
                                                        <>
                                                            <option value={''} checked>{t('Tout')}</option>
                                                            {dataRegions
                                                                .filter(e => geoTargetingFormData?.MetroCode ? dataMetros.find(e => e.Code === geoTargetingFormData?.MetroCode)?.regionCodes.includes(e.Code) : true)
                                                                .map((val, key) => {
                                                                    return (
                                                                        <option key={key} value={val.Code} title={val.Name}>{val.Code}- {val.Name}</option>
                                                                    )
                                                                })}
                                                        </>
                                                        :
                                                        <option checked>{t('Sélectionnez un pays pour voir les régions')}</option>
                                                    :
                                                    <option disabled>{t("Chargement")}...</option>
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="w-100" controlId="formBasicMetro/DMA_Code">
                                            <Form.Label>{t("Code métro/DMA")}</Form.Label>
                                            <div className='w-100 p-0' style={{ display: 'flex', alignItems: "center", gap: 15 }}>
                                                <Form.Select ref={(e) => refAttributs.current.geoTargetingRef = e} value={geoTargetingFormData?.MetroCode} onChange={e => {
                                                    setErrors({})
                                                    setGeoTargetingFormData({
                                                        ...geoTargetingFormData,
                                                        'MetroCode': e.target.value,
                                                        'MetroName': e.target.options[e.target.selectedIndex].text
                                                    })
                                                }
                                                }>
                                                    {!loadingDataRegions ?
                                                        geoTargetingFormData?.CountryCode ?
                                                            <>
                                                                <option value={null}>{t('Tout')}</option>
                                                                {dataMetros
                                                                    .filter(e => geoTargetingFormData?.Region ? e.regionCodes.includes(geoTargetingFormData?.Region) : true)
                                                                    .map((val, key) => {
                                                                        return (
                                                                            <option key={key} value={val.Code} title={val.Name}>{val.Code}: {val.Name}</option>
                                                                        )
                                                                    })}
                                                            </>
                                                            :
                                                            <option checked>{t("Sélectionnez une région pour voir le code métro/DMA")}</option>
                                                        :
                                                        <option disabled>{t("Chargement")}...</option>
                                                    }
                                                </Form.Select>
                                                <Button className='w-50' onClick={addGeoTargeting} disabled={!geoTargetingFormData?.CountryCode || manageGeoTargetingLoading}>
                                                    {manageGeoTargetingLoading ?
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            <span>{t("Ajout")}...</span>
                                                        </div>
                                                        : <span style={{ fontWeight: 900 }}>{t('Ajouter')}</span>}
                                                </Button>
                                                {/* <Button
                                                className='p-0'
                                                style={{
                                                    display: 'flex',
                                                    alignItems: "center",
                                                    gap: 7,
                                                    background: "none",
                                                    border: "none",
                                                    color: 'gray'
                                                }}
                                                onClick={addGeoTargeting}
                                                disabled={!geoTargetingFormData?.CountryCode}
                                            >
                                                <span class="mdi mdi-plus-circle" style={{ color: 'black', fontSize: 22 }}></span>
                                                <span style={{ color: 'black' }}>Add</span>
                                            </Button> */}
                                                {/* <span class="mdi mdi-reload" style={{ fontSize: 22, cursor: 'pointer' }}></span> */}

                                            </div>
                                        </Form.Group>
                                    </div>

                                    {errors?.GeoTargeting &&
                                        <div className='mx-4'>
                                            <Alert color="danger">
                                                {errors?.GeoTargeting}
                                            </Alert>
                                        </div>}

                                    {dataGeoTargeting?.length !== 0 && <Table className='container' responsive>
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>{t("Pays")}</th>
                                                <th>{t("Région")}</th>
                                                <th>{t("Métro")}</th>
                                                <th>Option</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataGeoTargeting?.map((val, key) => {

                                                return (
                                                    <tr key={key}>
                                                        <td>{val.IsExclude ? t("Exclure") : t("Inclure")}</td>
                                                        <td>{val.CountryName ? `${val.CountryCode}-${val.CountryName}` : `${val.CountryCode}-${DataCountries.find(e => e.value === val.CountryCode)?.title}`}</td>
                                                        <td>{val.RegionName ? `${val.Region}` : val.Region ? `${val.Region}` : '-'}</td>
                                                        <td>{val.MetroCode ? `${val.MetroCode}` : val.MetroCode ? `${val.MetroCode}` : '-'}</td>
                                                        {/* <td>{val.RegionName }</td> */}
                                                        <td>
                                                            {val.LocationId && val.LocationId === deleteGeoTargetingselected
                                                                ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                :
                                                                <span class="mdi mdi-close" style={{ cursor: 'pointer' }} onClick={() => {
                                                                    if (parseInt(Id) !== 0) {
                                                                        deleteGeoTargeting(val.LocationId, true)
                                                                        setDeleteGeoTargetingselected(val.LocationId)
                                                                    } else {
                                                                        const data = dataGeoTargeting.filter((_, index) => index !== key)
                                                                        setDataGeoTargeting(data)
                                                                        setFlightFormData({
                                                                            ...flightFormData,
                                                                            'GeoTargeting': data
                                                                        })
                                                                    }
                                                                }}></span>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>}

                                </Accordion.Body>
                            </Accordion.Item>}



                        <Accordion.Item className='mt-4' eventKey="1">
                            <Accordion.Header>{t("Annonces")}</Accordion.Header>
                            {!loadingAds ?
                                <Accordion.Body className='p-0'>
                                    <div className='p-4' style={{ maxHeight: 400, overflow: 'auto' }}>
                                        {dataCreativeMaps.length !== 0 ?
                                            <>

                                                {/* PAGINATION */}
                                                <Row>
                                                    <Col lg={12} className="d-flex justify-content-start">

                                                        <TablePagination
                                                            component="div"
                                                            count={totalPages * pageSize} // Total number of items
                                                            page={page}
                                                            onPageChange={handlePageChange}
                                                            rowsPerPage={pageSize}
                                                            onRowsPerPageChange={handlePageSizeChange}

                                                            rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                                                            showFirstButton={true}
                                                            showLastButton={true}
                                                            sx={{
                                                                ".MuiTablePagination-displayedRows": {
                                                                    margin: 0
                                                                },
                                                                ".MuiTablePagination-selectLabel": {
                                                                    display: 'none',
                                                                    margin: 0
                                                                },
                                                                ".MuiSelect-select": {
                                                                    backgroundColor: "#ededed"
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>

                                                {/* PAGINATION */}
                                                <Table className='mt-4' style={{ width: '100%', borderColor: 'lightgray' }} responsive>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">{t("Actif")}</th>
                                                            <th scope="col">{t("Tittre")}</th>
                                                            <th scope="col">{t("ID d'annonce")}</th>
                                                            {/* <th scope="col">Status</th> */}
                                                            <th scope="col">Impressions</th>
                                                            <th scope="col">{t("Clics")}</th>
                                                            <th scope="col">{t("Clics uniques")}</th>
                                                            <th scope="col">Conversions</th>
                                                            <th scope="col">{t("Revenue")}</th>
                                                            <th scope="col">{t("Dernière mise à jour")}</th>
                                                            <th scope="col">Options</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataCreativeMaps.map((val, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <img src={val.ImageLink} style={{
                                                                            height: 'auto',
                                                                            maxWidth: 40,
                                                                            width: 'auto !important',
                                                                        }} />
                                                                    </td>
                                                                    <td className='d-flex justify-content-center align-self-center'>
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ cursor: 'pointer' }} checked={val.IsActive} onClick={(e) => {
                                                                                updateIsActive(val.Id)
                                                                                // setDataCreativeMaps(dataCreativeMaps.map((v, k) => {
                                                                                //     if (v.Id === val.Id) {
                                                                                //         if (val.Id !== undefined && !IdsCreativeUpdated.includes(val.Id)) {
                                                                                //             setIdsCreativeUpdated([...IdsCreativeUpdated, val.Id]);
                                                                                //         }
                                                                                //         return ({
                                                                                //             ...v,
                                                                                //             IsActive: !val.IsActive
                                                                                //         })
                                                                                //     }
                                                                                //     return v
                                                                                // }))
                                                                            }}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span>{val.Creative?.Title}</span>

                                                                        {/* <a className='d-flex flex-column' onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                    <span>Second campaign</span>
                                </a> */}
                                                                    </td>
                                                                    <td>{val.Id}</td>
                                                                    {/* <td>
                                                                        status
                                                                    </td> */}
                                                                    <td>{val.impressions}</td>
                                                                    <td>{val.clicks}</td>
                                                                    <td>{val.uniqueclicks ? val.uniqueclicks : '-'}</td>
                                                                    <td>{val.conversions}</td>
                                                                    <td>${val.revenue}</td>
                                                                    <td>
                                                                        {new Date().toUTCString(val.LastModified)}
                                                                    </td>
                                                                    <td>
                                                                        <div className='d-flex justify-content-between'>
                                                                            {/* <Link to={`/add-flight/${val.Id}/`} style={{}}> */}
                                                                            <span class="mdi mdi-pencil" style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
                                                                                handleShowUpdateAddModal()
                                                                                setKeyAdSelected(key)
                                                                                setImageLink(dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink != 'https://static.adzerk.net/Advertisers/' ? dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink : '')
                                                                                setTitle(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Title)
                                                                                setAdTypeId(dataCreativeMaps.find((_, index) => index === key)?.Creative?.AdTypeId)
                                                                                setUrl(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Url)
                                                                                setAltText(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Alt)
                                                                                setMetadata(dataCreativeMaps.find((_, index) => index === key)?.Creative?.Metadata)
                                                                                setLinkAsset(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues ? JSON.parse(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues)?.ctPlayback_URL : '')
                                                                                setDureeAsset(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues ? JSON.parse(dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues)?.ctduration : '')
                                                                                setFormat((dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink !== 'https://static.adzerk.net/Advertisers/' || dataCreativeMaps.find((_, index) => index === key)?.Creative?.ImageLink === '') ? 'Image' : 'Video')
                                                                                console.log('sddsfsdf : ', dataCreativeMaps.find((_, index) => index === key)?.Creative?.TemplateValues?.length > 0 ? 'Video' : 'Image');

                                                                            }
                                                                            }></span>
                                                                            {/* </Link> */}
                                                                            <span class="mdi mdi-close" style={{ fontSize: 25, cursor: 'pointer' }} onClick={() => {
                                                                                handleShowDeleteAdModal()
                                                                                setIdAdSelected(val.Id || key)

                                                                                // setFlightFormData({
                                                                                //     ...flightFormData,
                                                                                //     'SiteZoneTargeting': data
                                                                                // })
                                                                            }}></span>




                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </>
                                            :
                                            <span>{t("Aucun élément trouvé.")}</span>
                                        }
                                    </div>

                                    <div className='w-100 px-3' style={{ height: 50, background: "lightgray", display: 'flex', alignItems: 'center' }}>
                                        <Button
                                            variant='primary'
                                            onClick={handleShowAddAdModal}
                                        >
                                            {t("Créer une annonce")}
                                        </Button>
                                    </div>
                                </Accordion.Body>
                                :
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 10,
                                    padding: 10
                                }}>
                                    <span>{t("Chargement")}</span>
                                    <Spinner animation="grow" size='sm' />
                                </div>}
                        </Accordion.Item>

                        {/* <Accordion.Item className='mt-4' eventKey="8">
                    <Accordion.Header>Day Parting</Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                        <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                            <strong>You can configure specific times on specific days for this flight to serve. Times are in the time zone of this flight which is GMT. You can change this above.</strong><br />
                            <span>Blank start time or end time will default to midnight (i.e. "00:00"). Leaving all days unchecked will select the entire week.</span>
                        </Alert>

                        <div className='px-4 my-3 d-flex flex-column flex-md-row gap-2'>
                            <Form.Group className="w-100" controlId="formBasicBeginTime">
                                <Form.Label>Begin Time</Form.Label>
                                <Form.Control type="text" placeholder="Begin Time" />
                                <Form.Label style={{ fontWeight: 100, marginLeft: 2 }}>HH:mm (e.g. "09:00")</Form.Label>
                            </Form.Group>
                            <Form.Group className="w-100" controlId="formBasicEndTime">
                                <Form.Label>End Time</Form.Label>
                                <Form.Control type="text" placeholder="End Time" />
                                <Form.Label style={{ fontWeight: 100, marginLeft: 2 }}>HH:mm (e.g. "21:00")</Form.Label>
                            </Form.Group>
                            <div className='w-100 d-flex gap-2'>
                                <Form.Group className="" controlId="Sun">
                                    <Form.Label>Sun</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Mon">
                                    <Form.Label>Mon</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Tue">
                                    <Form.Label>Tue</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Wed">
                                    <Form.Label>Wed</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Thu">
                                    <Form.Label>Thu</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Fri">
                                    <Form.Label>Fri</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>
                                <Form.Group className="" controlId="Sat">
                                    <Form.Label>Sat</Form.Label>
                                    <Form.Check className='mt-1' type="checkbox" />
                                </Form.Group>

                                <div className='w-100' style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button className='p-0' style={{ display: 'flex', alignItems: "center", gap: 7, background: "none", border: "none", color: 'gray' }}>
                                        <span class="mdi mdi-plus-circle" style={{ fontSize: 22 }}></span>
                                        <span>Add</span>
                                    </Button>
                                </div>
                            </div>

                        </div>

                    </Accordion.Body>
                </Accordion.Item> */}

                        {/* <Accordion.Item className='mt-4' eventKey="9">
                    <Accordion.Header>Distribution</Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                        <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                            <strong>
                                To use these settings, creatives in this flight must be served through the same ad request. These settings can't be used together on one flight.
                            </strong><br />
                        </Alert>

                        <div className='px-4'>
                            <Form.Group className="w-100 d-flex align-items-start gap-2" controlId="t">
                                <Form.Check className='mt-1' type="checkbox" />
                                <Form.Label className='d-flex flex-column align-items-start'>
                                    <div className='d-flex align-items-center'>
                                        <span>Don't allow duplicates with other creatives from this</span>
                                        <Form.Group className="" controlId="formBasicRegion">
                                            <Form.Control type="text" placeholder="Region" />
                                        </Form.Group>

                                    </div>
                                    <span style={{ fontWeight: 100 }}>Prevents creatives from showing multiple times per page view</span>
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className="w-100 d-flex align-items-start gap-2" controlId="p">
                                <Form.Check className='' type="checkbox" />
                                <Form.Label className='d-flex flex-column align-items-start'>
                                    <span>Always serve creatives in this flight together</span>
                                    <span style={{ fontWeight: 100 }}>This makes creatives in this flight Companion Ads. What are Companion Ads?</span>
                                </Form.Label>
                            </Form.Group>
                        </div>

                    </Accordion.Body>
                </Accordion.Item> */}

                    </Accordion>



                    <div
                        className=' w-100 d-flex align-items-center justify-content-end pt-4 mt-4'
                        style={{ background: 'whitesmoke', borderTop: '1px solid lightgray', }}
                    >
                        <Button onClick={handleShowConfirmFlight} disabled={loadingAdd}>
                            {loadingAdd ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {t('Chargement')}...
                                </>
                                : <span>{t("Enregistrer")}</span>}
                        </Button>
                    </div>




                    {/* Boite de dialogue */}
                    <style>{styles}</style>
                    <Modal
                        dialogClassName='custom-modal-size'
                        show={confirmFlight}
                        onHide={handleCloseConfirmFlight}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{parseInt(Id) > 0 ? t("confirmer la modification") : t("confirmer l'ajout")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                            style={{
                                maxHeight: '80vh',
                                overflow: 'auto'
                            }}>

                            <div className='mb-2'>{t("Etes-vous sûr de vouloir")} {parseInt(Id) > 0 ? t('ajouter') : t('modifier')} {t("ce groupe d'annonce")}?</div>

                            <div className='container p-2 p-sm-3 p-md-4 '>
                                <div className='px-4 py-3 bg-light rounded ' style={{ borderLeft: '4px solid #7a6fbe', boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px" }}>
                                    <h4 className='mb-4'>{t("Détails du groupe d'annonce")}</h4>
                                    {Object.entries({
                                        [t("Titre")]: flightFormData?.Name,
                                        [t("Fuseau horaire")]: flightFormData?.TimeZone,
                                        [t("Date de début")]: flightFormData?.StartDate ? new Date(flightFormData.StartDate).toLocaleString() : '-',
                                        [t('Date de fin')]: flightFormData?.EndDate ? new Date(flightFormData.EndDate).toLocaleString() : '-',
                                        [t('Taux')]: (() => {
                                            const rateOptions = [
                                                '-',
                                                t("Plat"),
                                                'CPM',
                                                'CPC',
                                                `CPA (${t("Afficher")})`,
                                                `CPA (${t("Cliquer")})`,
                                                `CPA (${t("Afficher")} & ${t("Cliquer")})`
                                            ];
                                            return rateOptions[flightFormData?.RateType] || rateOptions[0];
                                        })(),
                                        [t('Prix')]: flightFormData?.Price,
                                        [t('Support')]: dataSites.find(e => e.Id === parseInt(siteIdSelected))?.Title,
                                        [t('Annonces')]: dataCreativeMaps.length,
                                        ...(dataSites.find(e => e.Id === parseInt(siteIdSelected))?.Title === 'Radio Karaoke' && {
                                            [t('Ciblage par mots-clés')]: flightFormData?.Keywords,
                                            [t('Ciblage personnalisé')]: flightFormData?.CustomTargeting
                                        })
                                    }).map(([key, value], index) => (
                                        <div className='d-flex flex-wrap justify-content-between align-items-center mt-3' key={index}>
                                            <strong>{key} :</strong>
                                            <span>{value || '-'}</span>
                                        </div>
                                    ))}

                                    {/* 
                                    <div className='mt-4'>
                                        <h6>{t("Ciblage par site/zone")}:</h6>
                                        <div className='d-flex flex-column gap-2'>
                                            {dataSiteZoneTargeting.map((val, key) => (
                                                <span key={key} className='px-3 py-2 rounded'
                                                    style={{
                                                        background: val.IsExclude ? '#ff00001a' : '#00807b2e',
                                                        color: val.IsExclude ? '#dc3545' : '#548484',
                                                    }}>
                                                    {`${val.IsExclude ? '!' : ''} ${val.Title || dataSites.find(e => e.Id === val.SiteId)?.Title || '-'} ${val.Name || dataZones.find(e => e.Id === val.ZoneId)?.Name || '-'}`}
                                                </span>
                                            ))}
                                        </div>
                                    </div> 
                                    */}

                                    {dataSites.find(e => e.Id === parseInt(siteIdSelected))?.Title === 'DAB+' &&
                                        <div className={`mt-4 ${dataGeoTargeting.length === 0 && 'd-flex justify-content-between'}`}>
                                            <h6><strong>{t("Ciblage géographique")} :</strong></h6>
                                            {dataGeoTargeting.length > 0
                                                ? <div className='d-flex flex-column gap-2'>
                                                    {dataGeoTargeting.map((val, key) => (
                                                        <span key={key} className='px-3 py-2 rounded'
                                                            style={{
                                                                background: val.IsExclude ? '#ff00001a' : '#00807b2e',
                                                                color: val.IsExclude ? '#dc3545' : '#548484',
                                                            }}>
                                                            {`${val.IsExclude ? '!' : ''} ${val.CountryName ? `${val.CountryCode}-${val.CountryName}` : `${val.CountryCode}-${DataCountries.find(e => e.value === val.CountryCode)?.title}`} ${val.RegionName || val.Region || '-'} ${val.MetroCode || '-'}`}
                                                        </span>
                                                    ))}
                                                </div>
                                                :
                                                <span>-</span>}
                                        </div>
                                    }
                                </div>

                                {parseInt(Id) === 0 &&
                                    <div className=' py-3  '>
                                        <h5 className='my-3'>{t("Veuillez ajouter les informations de paiement pour continuer")}</h5>

                                        <form >
                                            <PaymentElement />
                                            <div className='col-12 mt-3'>
                                                <TextField
                                                    ref={(e) => refAttributs.current.amountRef = e}
                                                    label="Budget (USD)"
                                                    variant="filled"
                                                    value={amount}
                                                    onChange={e => {
                                                        setAmount(e.target.value)
                                                        setFlightFormData({
                                                            ...flightFormData,
                                                            'LifetimeCapAmount': e.target.value,
                                                            'LifetimeCapAmountDecimal': parseFloat(e.target.value).toFixed(2),
                                                        })
                                                    }}
                                                    style={{ width: '100%', backgroundColor: 'transparent', border: '1px solid lightgray', borderRadius: 4, borderBottom: 0 }}
                                                />
                                                {/* {errors.Amount &&  */}
                                                {errors?.Amount && <Form.Text className="text-danger">{errors?.Amount}</Form.Text>}
                                                {/* } */}
                                            </div>
                                            <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>

                                                {/* <span className='mt-3 w-100'>{isProcessing ? 'Traitement...' : 'Payer'}</span> */}
                                                {/* <Button className='mt-3 w-100' type="submit" disabled={isProcessing}>
          {isProcessing ? 'Traitement...' : 'Payer'}
        </Button> */}
                                            </div>
                                        </form>
                                    </div>
                                }

                            </div>



                            <div className='mt-3 d-flex gap-2' style={{ height: 50, display: 'flex', alignItems: 'center' }}>
                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        AddEditFlight()
                                        console.log('dataCreativeMaps FROM UPDATE AD FUNCTION : ', dataCreativeMaps);
                                    }} disabled={loadingAdd}>
                                    {loadingAdd ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            {t("Chargement")}...
                                        </>
                                        : <span>{t("Enregistrer")}</span>}
                                </Button>
                                <Button variant="secondary" onClick={handleCloseConfirmFlight} disabled={loadingAdd}>
                                    {t("Annuler")}
                                </Button>
                            </div>

                        </Modal.Body>
                        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddAdModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCloseAddAdModal}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
                    </Modal>


                    {/* Modal to Add new Ad */}

                    <Modal
                        size="lg"
                        show={addAdModal}
                        onHide={handleCloseAddAdModal}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Créer une annonce")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                            style={{
                                maxHeight: '80vh',
                                overflow: 'auto'
                            }}>

                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                <Accordion.Item className='mt-4' eventKey="0">
                                    <Accordion.Header>{t("Détails créatifs")}</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Format</Form.Label>
                                                <Form.Select value={format} onChange={e => setFormat(e.target.value)}>
                                                    <option value='Image'>Image (.gif, .jpg, .png, .svg, .webp)</option>
                                                    <option value='Video'>{t("Vidéo")}</option>
                                                </Form.Select>
                                            </Form.Group>

                                            {format === "Image" &&
                                                <>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>{t("Télécharger")}</Form.Label>
                                                        <Form.Control
                                                            type="file"
                                                            accept=".gif,.jpg,.jpeg,.png,.svg,.webp"
                                                            ref={(e) => refAttributsAd.current.FieldNameRef = e}
                                                            onChange={e => {
                                                                const file = e.target.files[0];
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    FieldName: file,
                                                                    ImageLink: ''
                                                                });
                                                                // setDataFiles([...dataFiles,{
                                                                //     FieldName:file
                                                                // }])
                                                            }}
                                                        />
                                                        {errorsAd?.FieldName && <Form.Text className="text-danger">{errorsAd?.FieldName}</Form.Text>}
                                                    </Form.Group>

                                                    {!creativeMapsFormData?.FieldName && <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{t("URL du fichier")}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={t("URL du fichier")}
                                                            value={creativeMapsFormData?.ImageLink}
                                                            ref={(e) => refAttributsAd.current.ImageLinkRef = e}
                                                            onChange={e => {
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    ImageLink: e.target.value
                                                                })
                                                            }} />
                                                        {errorsAd?.ImageLink && <Form.Text className="text-danger">{errorsAd?.ImageLink}</Form.Text>}
                                                    </Form.Group>}
                                                </>
                                            }

                                            {
                                                format === "Video" &&
                                                <>
                                                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Templates</Form.Label>
                                                        <Form.Select value={templateSelected} onChange={e => setTemplateSelected(e.target.value)}>
                                                            {dataTemplates.map((v, k) => {
                                                                return (
                                                                    <option key={k} value={v.Id}>{v.Name}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group> */}

                                                    <div style={{
                                                        display: 'flex',
                                                        gap: 4
                                                    }}>
                                                        <Form.Group className="mb-3 col-11" controlId="formBasicPassword">
                                                            <Form.Label>{t("Télécharger")}</Form.Label>
                                                            <Form.Control
                                                                ref={(e) => refAttributsAd.current.ctPlayback_URLRef = e}
                                                                type="file"
                                                                accept=".mp4,.webm,.ogg"
                                                                onChange={e => {
                                                                    const file = e.target.files[0];
                                                                    setAssetFormData({
                                                                        ...assetFormData,
                                                                        video: file
                                                                    })
                                                                }}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="col-2" style={{ marginTop: 28 }} controlId="formBasicPassword">

                                                            <Button onClick={addAsset} disabled={uploadVideoLoading}>
                                                                {uploadVideoLoading ?
                                                                    <>
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="grow"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                        {t("Chargement")}...
                                                                    </>
                                                                    : <span>{t('Ajouter')}</span>}
                                                            </Button>
                                                        </Form.Group>
                                                    </div>

                                                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Video Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Video name"
                                                            onChange={e => {
                                                                setAssetFormData({
                                                                    ...assetFormData,
                                                                    videoName: e.target.value
                                                                })
                                                            }} />
                                                    </Form.Group> */}



                                                    <Form.Group className="mb-3" controlId="Playback_URL">
                                                        <Form.Label>{t("URL de la vidéo")}</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            placeholder={t("URL de la vidéo")}
                                                            value={linkAsset}
                                                            disabled={true}
                                                        />
                                                        {errorsAd?.ctPlayback_URL && <Form.Text className="text-danger">{errorsAd?.ctPlayback_URL}</Form.Text>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="duration">
                                                        <Form.Label>{t("Durée")}</Form.Label>
                                                        <Form.Control
                                                            ref={(e) => refAttributsAd.current.ctdurationRef = e}
                                                            type="text"
                                                            placeholder={t("Durée")}
                                                            disabled={true}
                                                            value={dureeAsset}
                                                        // value={v.Name === "Playback_URL" ? linkAsset : null}
                                                        // disabled={v.Name === "Playback_URL" && true}
                                                        // onChange={e => {
                                                        //     // setCtDuration(e.target.value)
                                                        //     setCreativeMapsFormData({
                                                        //         ...creativeMapsFormData,
                                                        //         TemplateValues: {
                                                        //             ...creativeMapsFormData?.TemplateValues,
                                                        //             ctduration: e.target.value
                                                        //         },
                                                        //     });
                                                        // }}
                                                        />
                                                        {errorsAd?.ctduration && <Form.Text className="text-danger">{errorsAd?.ctduration}</Form.Text>}
                                                    </Form.Group>

                                                    {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>Playback URL</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Playback URL"
                                                            value={creativeMapsFormData?.TemplateValues?.ctPlayback_URL || ''}
                                                            onChange={e => {
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    TemplateValues: {
                                                                        ...creativeMapsFormData?.TemplateValues,
                                                                        'ctPlayback_URL': `${e.target.value}`
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        {errorsAd?.FieldName && <Form.Text className="text-danger">{errorsAd?.FieldName}</Form.Text>}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Duration</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Duration"
                                                            value={creativeMapsFormData?.TemplateValues?.ctduration || ''}
                                                            onChange={e => {
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    TemplateValues: {
                                                                        ...creativeMapsFormData?.TemplateValues,
                                                                        'ctduration': `${e.target.value}`
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        {errorsAd?.ImageLink && <Form.Text className="text-danger">{errorsAd?.ImageLink}</Form.Text>}
                                                    </Form.Group> */}
                                                </>

                                            }

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>{t("Titre")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t("Titre")}
                                                    value={creativeMapsFormData.Title}
                                                    ref={(e) => refAttributsAd.current.TitleRef = e}
                                                    onChange={e => {
                                                        setCreativeMapsFormData({
                                                            ...creativeMapsFormData,
                                                            Title: e.target.value
                                                        })
                                                    }} />
                                                {errorsAd?.Title && <Form.Text className="text-danger">{errorsAd?.Title}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>{t("Taille de l'annonce")}</Form.Label>
                                                <Form.Select
                                                    ref={(e) => refAttributsAd.current.AdTypeIdRef = e}
                                                    value={creativeMapsFormData?.AdTypeId}
                                                    onChange={e => {
                                                        setCreativeMapsFormData({
                                                            ...creativeMapsFormData,
                                                            AdTypeId: e.target.value
                                                        })

                                                    }
                                                    }>
                                                    <option value=''>-- {t("Choisir")} --</option>
                                                    {dataAdTypes.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.Id}>{val.Name} - {val.Width}x{val.Height}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                {errorsAd?.AdTypeId && <Form.Text className="text-danger">{errorsAd?.AdTypeId}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>{t("Cliquez sur URL - URL séparées par des sauts de ligne pour plusieurs pages de destination")}</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: '100px', resize: 'none' }}
                                                    ref={(e) => refAttributsAd.current.UrlRef = e}
                                                    value={creativeMapsFormData?.Url}
                                                    onChange={e => {
                                                        setCreativeMapsFormData({
                                                            ...creativeMapsFormData,
                                                            Url: e.target.value
                                                        })

                                                    }
                                                    }
                                                />
                                                {errorsAd?.Url && <Form.Text className="text-danger">{errorsAd?.Url}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>{t("Texte alternatif")}({t("facultative")})</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t("Texte alternatif")}
                                                    value={creativeMapsFormData?.Alt}
                                                    onChange={e => {
                                                        setCreativeMapsFormData({
                                                            ...creativeMapsFormData,
                                                            Alt: e.target.value
                                                        })
                                                    }} />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>{t("Métadonnées")}({t("facultative")})</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: '100px', resize: 'none' }}
                                                    value={creativeMapsFormData?.Metadata}
                                                    ref={(e) => refAttributsAd.current.MetadataRef = e}
                                                    onChange={e => {
                                                        setCreativeMapsFormData({
                                                            ...creativeMapsFormData,
                                                            Metadata: e.target.value
                                                        })
                                                    }}
                                                    placeholder={`${t("Nécessite le format JSON")} (http://json.org)`}
                                                />
                                                {errorsAd?.Metadata && <Form.Text className="text-danger">{errorsAd?.Metadata}</Form.Text>}
                                            </Form.Group>

                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* <Accordion.Item className='mt-4' eventKey="1">
                            <Accordion.Header>Delivery Settings</Accordion.Header>
                            <Accordion.Body>

                                <Form.Group className="mb-3" controlId="overrideFlight">
                                    <Form.Check type="checkbox" label="Override Flight Start/End Dates" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Distribution</Form.Label>
                                    <Form.Control type="text" placeholder="Distribution" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="frequencyCapping">
                                    <Form.Check type="checkbox" label="Frequency Capping" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="frequencyCap">
                                    <Form.Check type="checkbox" label="This ad should not affect the advertiser/campaign/flight frequency cap" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Bid Price(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Bid Price" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Site Specific(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Site Specific" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Zone Specific(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Zone Specific" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Custom Targeting(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Keyword Targeting(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Ad-Specific Metadata(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                            </Accordion.Body>
                        </Accordion.Item> */}
                            </Accordion>



                            <div className='mt-3 d-flex gap-2' style={{ height: 50, display: 'flex', alignItems: 'center' }}>
                                <Button variant='primary' onClick={() => {
                                    // setDataCreativeMaps([...dataCreativeMaps, { IsActive: true, Creative: creativeMapsFormData }])
                                    createNewAd()
                                }} disabled={manageAdLoading}>
                                    {manageAdLoading ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            {t("Chargement")}...
                                        </>
                                        : <span>{t("Enregistrer")}</span>}
                                </Button>
                                <Button variant="secondary" onClick={handleCloseAddAdModal} disabled={manageAdLoading}>
                                    {t("Annuler")}
                                </Button>
                            </div>

                        </Modal.Body>
                        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddAdModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCloseAddAdModal}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
                    </Modal>


                    {/* Modal to Update Ad */}

                    <Modal
                        size="lg"
                        show={updateAddModal}
                        onHide={handleCloseUpdateAddModal}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Mise à jour de l'annonce")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                            style={{
                                maxHeight: '80vh',
                                overflow: 'auto'
                            }}>

                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                <Accordion.Item className='mt-4' eventKey="0">
                                    <Accordion.Header>{t("Détails créatifs")}</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Format</Form.Label>
                                                <Form.Select value={format} onChange={e => setFormat(e.target.value)}>
                                                    <option value='Image'>Image (.gif, .jpg, .png, .svg, .webp)</option>
                                                    <option value='Video'>{t("Vidéo")}</option>
                                                </Form.Select>
                                            </Form.Group>

                                            {format === "Image" &&
                                                <>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>{t("Télécharger")}</Form.Label>
                                                        <Form.Control
                                                            type="file"
                                                            accept=".gif,.jpg,.jpeg,.png,.svg,.webp"
                                                            // value={fieldName}
                                                            ref={(e) => refAttributsAd.current.FieldNameRef = e}
                                                            onChange={e => {
                                                                setFieldName(e.target.files[0])
                                                                setImageLink('')
                                                            }}
                                                        />
                                                        {errorsAd?.FieldName && <Form.Text className="text-danger">{errorsAd?.FieldName}</Form.Text>}
                                                    </Form.Group>

                                                    {<Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{t("URL du fichier")}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={imageLink}
                                                            ref={(e) => refAttributsAd.current.ImageLinkRef = e}
                                                            onChange={e => {
                                                                setImageLink(e.target.value)
                                                            }} />
                                                        {errorsAd?.ImageLink && <Form.Text className="text-danger">{errorsAd?.ImageLink}</Form.Text>}
                                                    </Form.Group>}
                                                </>}


                                            {
                                                format === "Video" &&
                                                <>

                                                    <div style={{
                                                        display: 'flex',
                                                        gap: 4
                                                    }}>
                                                        <Form.Group className="mb-3 col-11" controlId="formBasicPassword">
                                                            <Form.Label>{t('Télécharger')}</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                // value={imageLink}
                                                                accept=".mp4,.webm,.ogg"
                                                                onChange={e => {
                                                                    const file = e.target.files[0];
                                                                    setAssetFormData({
                                                                        ...assetFormData,
                                                                        video: file
                                                                    })
                                                                }}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="col-2" style={{ marginTop: 28 }} controlId="formBasicPassword">

                                                            <Button onClick={addAsset} disabled={uploadVideoLoading}>
                                                                {uploadVideoLoading ?
                                                                    <>
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="grow"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                        {t("Chargement")}...
                                                                    </>
                                                                    : <span>{t("Ajouter")}</span>}
                                                            </Button>
                                                        </Form.Group>
                                                    </div>


                                                    <Form.Group className="mb-3" controlId="Playback_URL">
                                                        <Form.Label>{t("URL de la vidéo")}</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            placeholder={t("URL de la vidéo")}
                                                            value={linkAsset}
                                                            disabled={true}
                                                        />
                                                        {errorsAd?.ctPlayback_URL && <Form.Text className="text-danger">{errorsAd?.ctPlayback_URL}</Form.Text>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="duration">
                                                        <Form.Label>{t("Durée")}</Form.Label>
                                                        <Form.Control
                                                            ref={(e) => refAttributsAd.current.ctdurationRef = e}
                                                            type="text"
                                                            placeholder={t("Durée")}
                                                            value={dureeAsset}
                                                            disabled={true}
                                                        // disabled={v.Name === "Playback_URL" && true}
                                                        // onChange={e => {
                                                        //     // setCreativeMapsFormData({
                                                        //     //     ...creativeMapsFormData,
                                                        //     //     TemplateValues: {
                                                        //     //         ...creativeMapsFormData?.TemplateValues,
                                                        //     //         ctduration: e.target.value
                                                        //     //     },
                                                        //     // });
                                                        //     setCtDuration(e.target.value)
                                                        // }}
                                                        />
                                                        {errorsAd?.ctduration && <Form.Text className="text-danger">{errorsAd?.ctduration}</Form.Text>}
                                                    </Form.Group>

                                                    {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>Playback URL</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Playback URL"
                                                            value={creativeMapsFormData?.TemplateValues?.ctPlayback_URL || ''}
                                                            onChange={e => {
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    TemplateValues: {
                                                                        ...creativeMapsFormData?.TemplateValues,
                                                                        'ctPlayback_URL': `${e.target.value}`
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        {errorsAd?.FieldName && <Form.Text className="text-danger">{errorsAd?.FieldName}</Form.Text>}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Duration</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Duration"
                                                            value={creativeMapsFormData?.TemplateValues?.ctduration || ''}
                                                            onChange={e => {
                                                                setCreativeMapsFormData({
                                                                    ...creativeMapsFormData,
                                                                    TemplateValues: {
                                                                        ...creativeMapsFormData?.TemplateValues,
                                                                        'ctduration': `${e.target.value}`
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        {errorsAd?.ImageLink && <Form.Text className="text-danger">{errorsAd?.ImageLink}</Form.Text>}
                                                    </Form.Group> */}
                                                </>

                                            }

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>{t("Titre")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t("Titre")}
                                                    value={title}
                                                    ref={(e) => refAttributsAd.current.TitleRef = e}
                                                    onChange={e => {
                                                        setTitle(e.target.value)
                                                    }} />
                                                {errorsAd?.Title && <Form.Text className="text-danger">{errorsAd?.Title}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>{t("Taille de l'annonce")}</Form.Label>
                                                <Form.Select
                                                    ref={(e) => refAttributsAd.current.AdTypeIdRef = e}
                                                    value={adTypeId}
                                                    onChange={e => {
                                                        setAdTypeId(e.target.value)
                                                    }
                                                    }>
                                                    <option value=''>-- {t("Choisir")} --</option>
                                                    {dataAdTypes.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.Id}>{val.Width}x{val.Height} - {val.Name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                {errorsAd?.AdTypeId && <Form.Text className="text-danger">{errorsAd?.AdTypeId}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>{t("Cliquez sur URL - URL séparées par des sauts de ligne pour plusieurs pages de destination")}</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: '100px', resize: 'none' }}
                                                    ref={(e) => refAttributsAd.current.UrlRef = e}
                                                    value={url}
                                                    onChange={e => {
                                                        setUrl(e.target.value)
                                                    }
                                                    }
                                                />
                                                {errorsAd?.Url && <Form.Text className="text-danger">{errorsAd?.Url}</Form.Text>}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>{t("Texte alternatif")}({t("facultative")})</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={altText}
                                                    placeholder={t("Texte alternatif")}
                                                    onChange={e => setAltText(e.target.value)} />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>{t("Métadonnées")}({t("facultative")})</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: '100px', resize: 'none' }}
                                                    value={metadata}
                                                    ref={(e) => refAttributsAd.current.MetadataRef = e}
                                                    placeholder={`${t("Nécessite le format JSON")} (http://json.org)`}
                                                    onChange={e => setMetadata(e.target.value)}
                                                />
                                                {errorsAd?.Metadata && <Form.Text className="text-danger">{errorsAd?.Metadata}</Form.Text>}
                                            </Form.Group>

                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* 
                                <Accordion.Item className='mt-4' eventKey="1">
                            <Accordion.Header>Delivery Settings</Accordion.Header>
                            <Accordion.Body>

                                <Form.Group className="mb-3" controlId="overrideFlight">
                                    <Form.Check type="checkbox" label="Override Flight Start/End Dates" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Distribution</Form.Label>
                                    <Form.Control type="text" placeholder="Distribution" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="frequencyCapping">
                                    <Form.Check type="checkbox" label="Frequency Capping" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="frequencyCap">
                                    <Form.Check type="checkbox" label="This ad should not affect the advertiser/campaign/flight frequency cap" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Bid Price(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Bid Price" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Site Specific(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Site Specific" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Zone Specific(optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Zone Specific" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Custom Targeting(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Keyword Targeting(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Ad-Specific Metadata(optional)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px', resize: 'none' }}
                                    />
                                </Form.Group>

                            </Accordion.Body>
                        </Accordion.Item> 
                        */}
                            </Accordion>



                            <div className='mt-3 d-flex gap-2' style={{ height: 50, display: 'flex', alignItems: 'center' }}>
                                <Button variant='primary' onClick={() => {
                                    // setDataCreativeMaps([...dataCreativeMaps, { IsActive: true, Creative: creativeMapsFormData }])
                                    updateAd()
                                }} disabled={manageAdLoading}>
                                    {manageAdLoading ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            {t("Chargement")}...
                                        </>
                                        : <span>{t("Modifier")}</span>}
                                </Button>
                                <Button variant="secondary" onClick={handleCloseUpdateAddModal} disabled={manageAdLoading}>
                                    {t("Annuler")}
                                </Button>
                            </div>

                        </Modal.Body>
                        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddAdModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCloseAddAdModal}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
                    </Modal>

                    {/* Modal Delete Campaign */}

                    <Modal show={deleteAdModal} onHide={handleCloseDeleteAdModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Supprimer l'annonce")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>

                                <span>{t("Etes-vous sûr de vouloir supprimer cette annonce ?")}</span>

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseDeleteAdModal} disabled={loadingAds}>
                                {t("Annuler")}
                            </Button>
                            <Button
                                variant='danger'
                                onClick={deletedAd} disabled={loadingAds}>
                                {loadingAds ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {t("Chargement")}...
                                    </>
                                    : <span>{t("Supprimer")}</span>}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Upgrade Budget */}
                    <UpgradeBudget
                        user={user}
                        Id={Id}
                        data={{
                            "IsActive": flightFormData?.IsActive,
                            'LifetimeCapAmount': parseFloat(flightFormData?.LifetimeCapAmount),
                            "Name": flightFormData?.Name,
                            "CapType": 4,
                            "RateType": flightFormData?.RateType,
                            "GoalType": flightFormData?.GoalType,
                            "Impressions": flightFormData?.Impressions,
                            "CampaignId": flightFormData?.CampaignId,
                            "PriorityId": flightFormData?.PriorityId,
                            "StartDateISO": flightFormData?.StartDateISO,
                            "Price": flightFormData?.Price,
                            "Keywords": flightFormData?.Keywords,
                            "CustomTargeting": flightFormData?.CustomTargeting,
                        }
                        }
                        oldBudget={oldBudget}
                        show={upgradeBudgetFlightModal}
                        handleClose={handleCloseUpgradeBudgetFlightModal}
                    />



                </div >
                :
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10
                }}>
                    <span>{t("Chargement")}</span>
                    <Spinner animation="grow" size='sm' />
                </div>

            }
        </>
    )
}

export default AddFlight
