import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { ButtonGroup, Col, Offcanvas, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import axios from 'axios';
import axiosClient, { link, percentageDifferenceRevenueBudget } from '../../../../axios-client';
import { TablePagination, TextField, Tooltip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LuAlertCircle } from "react-icons/lu";


import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaFilter } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useStateContext } from '../../../../context/ContextProvider';
import UpgradeBudget from './UpgradeBudget';


const styles = `
.custom-modal-size {
  max-width: 650px; 
}
`


function Flight() {

    const { id } = useParams();
    const { t } = useTranslation()

    const stripe = useStripe()
    const elements = useElements()

    const { user } = useStateContext()

    const navigate = useNavigate()

    const [deleteFlightModal, setDeleteFlightModal] = useState(false);
    const [upgradeBudgetFlightModal, setUpgradeBudgetFlightModal] = useState(false);

    const [selectedFlightId, setSelectedFlightId] = useState()

    const handleCloseDeleteFlightModal = () => setDeleteFlightModal(false);
    const handleShowDeleteFlightModal = () => setDeleteFlightModal(true);

    const handleCloseUpgradeBudgetFlightModal = () => {
        setUpgradeBudgetFlightModal(false)
        setNewBudget()
    };
    const handleShowUpgradeBudgetFlightModal = () => setUpgradeBudgetFlightModal(true);

    const [showCanvas, setShowCanvas] = useState(false);
    const handleCloseCanvas = () => setShowCanvas(false);
    const toggleShowCanvas = () => setShowCanvas(true);

    //
    const [nameFilter, setNameFilter] = useState('')
    const [idFilter, setIDFilter] = useState('')
    const [statusFilter, setStatusFilter] = useState('')
    //

    const [anchorEl, setAnchorEl] = useState(null);

    const [dataToGenerateCode, setDataToGenerateCode] = useState({})

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setDataToGenerateCode({})
        setAnchorEl(null);
    };

    //Variables
    const [dataFlight, setDataFlight] = useState([])
    const [flightFormData, setFlightFormData] = useState({})
    const [oldBudget, setOldBudget] = useState()
    const [newBudget, setNewBudget] = useState()
    const [loading, setLoading] = useState()
    const [loadingFilter, setLoadingFilter] = useState()

    ////////////////////////////////the size of the table//////////////////////////////
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // Get Flights
    const fetchFlight = async () => {
        setLoading(true)
        try {
            const response = await axiosClient.get(`get-flights/${id}/?page=${page + 1}&page_size=${pageSize}&etat=${statusFilter}`)
            setDataFlight(response?.data)
            setTotalPages(response?.data[0]?.totalPages);
            setLoading(false)
            setLoadingFilter(false)
            console.log('RESPOOOONSE : ', response);
        } catch (error) {
            console.log('Error Fetching : ', error);
            setLoading(false)
            setLoadingFilter(false)
        }
    }

    // useEffect(() => {
    //     setLoading(true)
    //     fetchFlight()
    // }, [page, pageSize, statusFilter])

    useEffect(() => {
        fetchFlight()
    }, [page, pageSize, statusFilter])


    const updateIsActive = async (id) => {
        axiosClient.put(`update-isActive-flight/${id}/`)
            .then(res => {
                setDataFlight(dataFlight.map(item => {
                    if (item.Id === res.data.Id) {
                        return {
                            ...item,
                            IsActive: res.data.IsActive
                        }
                    }
                    return item
                }))
                if (res.data.IsActive) {
                    toast.success(t("Le groupe d'annonces est activé"), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.success(t("Le groupe d'annonces est désactivé"), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                }

            }).catch(err => {
                console.error('ERROOR : ', err)

            })
    }


    const [deleteLoading, setDeletedLoading] = useState()
    const deleteFlight = async (id) => {
        setDeletedLoading(true)
        await axiosClient.put(`delete-flight/${id}/`).then(() => {
            setDataFlight(dataFlight.filter(e => e.Id !== id))
            handleCloseDeleteFlightModal()
            setDeletedLoading(false)

            toast.success(t("The flight is deleted"), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }).catch(e => {
            setDeletedLoading(false)
            console.log('ERROR : ', e);

        })
    }

    // GET Flight by Id
    const [getInfosFlightLoading, setGetInfosFlightLoading] = useState()
    const getFlightById = async (id) => {
        setGetInfosFlightLoading(true)
        try {
            await axiosClient.get(`get-flight-with-id/${id}/`).then(res => {
                setFlightFormData(res.data)
                setOldBudget(res.data.LifetimeCapAmount)
                setGetInfosFlightLoading(false)
            }).catch(error => {
                console.log('ERROR: ', error);
                setGetInfosFlightLoading(false)
            })
        } catch (error) {
            console.log('ERROR: ', error);
            setGetInfosFlightLoading(false)
        }
    }

    useEffect(() => {
        selectedFlightId && getFlightById(selectedFlightId)
    }, [selectedFlightId])

    useEffect(() => {
        console.log('Updated FormData:', flightFormData);
    }, [flightFormData])

    //Update LifetimeCapAmount
    const [upgradeBudgetLoading, setUpgradeBudgetLoading] = useState(false)
    const updateLifetimeCapAmount = async () => {
        setUpgradeBudgetLoading(true)
        try {

            if (!stripe || !elements || !flightFormData?.LifetimeCapAmount) {
                setUpgradeBudgetLoading(false)
                return;
            }
            // Ensure PaymentElement is properly initialized
            const { error: submitError } = await elements.submit();

            if (submitError) {
                console.log('[submitError]', submitError);
                return;
            }

            console.log('flightFormData : ', flightFormData);

            const response = await axiosClient.put(`edit-budget-flight/${selectedFlightId}/`, flightFormData).catch(error => {
                console.log('ERROR: ', error);
                setUpgradeBudgetLoading(false)
            })

            if (response.data) {
                console.log('flightFormData RES.DATA : ', response.data, ' | newBudget :', newBudget);

                try {
                    const { data } = await axiosClient.post('/create-payment-intent', {
                        user: user?.id,
                        flight: response?.data?.Id,
                        amount: newBudget,
                        // oldAmount: oldBudget,
                        currency: 'usd',
                    })


                    const { clientSecret } = data;

                    // Confirm the payment with the fetched clientSecret
                    const { error } = await stripe.confirmPayment({
                        elements,
                        clientSecret,
                        confirmParams: {
                            return_url: `${window.location.origin}/flights/${id}`,
                        },
                    });
                    handleCloseUpgradeBudgetFlightModal()
                    setSelectedFlightId()
                    if (error) {
                        console.log('[error]', error);
                    } else {
                        console.log('Payment successful');
                    }

                } catch (error) {
                    setUpgradeBudgetLoading(false)
                    console.error('Error fetching client secret:', error);

                }
            }
        } catch (error) {
            console.log('ERROR: ', error);
            setUpgradeBudgetLoading(false)
        }
    }

    const [priorityName, setPriorityName] = useState('')
    const getPriorities = async (id) => {
        try {
            const res = await axiosClient.get(`get-priorities/`);
            // Rechercher l'élément avec l'ID et retourner le Name s'il existe
            setPriorityName(res.data.items.find(e => e.Id === id)?.Name || 'Not Found')
        } catch (error) {
            setLoading(false);
            console.error(error);
            return null; // ou une autre valeur par défaut en cas d'erreur
        }
    }

    // Exemple d'appel de la fonction avec async/await
    useEffect(() => {
        getPriorities(246248);
    }, [])



    return (
        !loading ?
            <div>
                <ToastContainer />
                <div style={{ display: 'flex', justifyContent: (dataFlight.length !== 0) ? 'end' : 'center', gap: 10 }}>
                    {/* {<Button style={{ padding: '5px 10px', borderRadius: 7, background: 'lightgray', border: 'none', boxShadow: '5px 5px 10px lightgray' }} onClick={toggleShowCanvas}>
                            <FaFilter />
                        </Button>} */}
                    <Button onClick={() => navigate(`/add-flight/0/?c=${id}`
                    // , {
                    //     state: {
                    //         idCampaign: id
                    //     }
                    // }
                )}
                        variant='primary'
                    >
                        {t("Nouveau groupe d'annonces")}
                    </Button>
                </div>


                {
                    dataFlight.length !== 0 ?
                        <Table className='mt-4' style={{ width: '100%', borderColor: 'lightgray' }} responsive>
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">{t("Actif")}</th>
                                    <th scope="col">{t("Groupe d'annonces")}</th>
                                    <th scope="col">ID</th>
                                    <th scope="col">{t("Campagne")}</th>
                                    <th scope="col">{t("Fuseau horaire")}</th>
                                    <th scope="col">{t("Début")}</th>
                                    <th scope="col">{t("Fin")}</th>
                                    <th scope="col">{t("Prix")}</th>
                                    {/* <th scope="col">Status</th> */}
                                    <th scope="col">Impressions</th>
                                    <th scope="col">{t("Clics")}</th>
                                    <th scope="col">{t("Clics uniques")}</th>
                                    <th scope="col">Conversions</th>
                                    <th scope="col">{t("Revenue")}</th>
                                    <th scope="col">Budget</th>
                                    <th scope="col">Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataFlight.map((val, key) => {

                                    const revenue = parseFloat(val.revenue) || 0;
                                    const lifetimeCapAmount = parseFloat(val.LifetimeCapAmountDecimal) || 0;

                                    // Calculer le pourcentage de différence
                                    const percentageDifference = revenue !== 0 && lifetimeCapAmount !== 0 ?
                                        ((revenue) / lifetimeCapAmount) * 100 :
                                        null;

                                    console.log('percentageDifferencerr : ', percentageDifference);


                                    return (
                                        <tr key={key}>
                                            <td>
                                                {percentageDifference && percentageDifference > 0 && percentageDifference >= percentageDifferenceRevenueBudget &&
                                                    <Tooltip title={t("Le budget est proche du revenu")+'. '+t("Pensez à l'augmenter en cliquant sur ce bouton pour de meilleures performances")+"."}>
                                                        <IconButton
                                                            onClick={() => {
                                                                setSelectedFlightId(val.Id)
                                                                handleShowUpgradeBudgetFlightModal()
                                                            }}
                                                        >
                                                            <LuAlertCircle size={22} color='#e15959' />
                                                        </IconButton>
                                                    </Tooltip>}
                                            </td>
                                            <td className='d-flex justify-content-center align-self-center'>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ cursor: 'pointer' }} checked={val.IsActive} onClick={() => updateIsActive(val.Id)} />
                                                </div>
                                            </td>
                                            <td>

                                                <p onClick={() => navigate(`/add-flight/${val.Id}/?r=${val.revenue}&c=${val.CampaignId}`, {
                                                    state: {
                                                        idCampaign: id
                                                    }
                                                })} style={{ cursor: 'pointer', color: '#7a6fbe' }} className=" waves-effect" onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                                    <span>{val.Name}</span>
                                                </p>
                                                {/* <a className='d-flex flex-column' onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                        <span>Second campaign</span>
                                    </a> */}
                                            </td>
                                            <td>{val.Id}</td>
                                            <td>
                                                <Link to={`/flights/${id}`} className=" waves-effect" onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                                    <span>{val.CampaignId}</span>
                                                </Link>
                                            </td>
                                            <td>{val.TimeZone}</td>
                                            <td>
                                                {val.StartDate ? new Date(val.StartDate).toLocaleTimeString('fr-FR', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                }) : null}
                                            </td>
                                            <td>
                                                {val.EndDate ? new Date(val.EndDate).toLocaleTimeString('fr-FR', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                }) : '-'}
                                            </td>
                                            <td>
                                                ${val.Price}
                                            </td>
                                            {/* <td>
                                                -
                                            </td> */}
                                            <td>{val.impressions}</td>
                                            <td>{val.clicks}</td>
                                            <td>{val.uniqueclicks ? val.uniqueclicks : '-'}</td>
                                            <td>{val.conversions}</td>
                                            <td>${val.revenue}</td>
                                            <td>
                                                ${val.LifetimeCapAmountDecimal}
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-between'>
                                                    <button onClick={() => navigate(`/add-flight/${val.Id}/?r=${val.revenue}&c=${val.CampaignId}`, {
                                                        state: {
                                                            idCampaign: id
                                                        }
                                                    })} style={{
                                                        background: 'none',
                                                        border: 'none'
                                                    }}>
                                                        <span class="mdi mdi-pencil" style={{ fontSize: 25, cursor: "pointer", color: '#5b626b' }}></span>
                                                    </button>
                                                    <span class="mdi mdi-close" style={{ fontSize: 25, cursor: 'pointer' }} onClick={() => {
                                                        setSelectedFlightId(val.Id)
                                                        handleShowDeleteFlightModal()
                                                    }}></span>

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        :
                        <span>{t("Aucun élément trouvé.")}</span>
                }

                {/* PAGINATION */}
                {
                    dataFlight.length !== 0 &&
                    <Row>
                        <Col lg={12} className="d-flex justify-content-end">

                            <TablePagination
                                component="div"
                                count={totalPages * pageSize} // Total number of items
                                page={page}
                                onPageChange={handlePageChange}
                                rowsPerPage={pageSize}
                                onRowsPerPageChange={handlePageSizeChange}

                                rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                                showFirstButton={true}
                                showLastButton={true}
                                sx={{
                                    ".MuiTablePagination-displayedRows": {
                                        margin: 0
                                    },
                                    ".MuiTablePagination-selectLabel": {
                                        display: 'none',
                                        margin: 0
                                    },
                                    ".MuiSelect-select": {
                                        backgroundColor: "#ededed"
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                }
                {/* PAGINATION */}

                {/* Modal Delete Flight */}

                <Modal show={deleteFlightModal} onHide={handleCloseDeleteFlightModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Supprimer le groupe d'annonces")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>

                            <span>{t("Êtes-vous sûr de vouloir supprimer ce groupe d'annonces")}? {t("Toutes les annonces actives cesseront d'être diffusées")}</span>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteFlightModal}>
                            {t("Annuler")}
                        </Button>
                        <Button
                            variant='danger'
                            onClick={() => { deleteFlight(selectedFlightId) }} disabled={deleteLoading}>
                            {deleteLoading ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {t("Chargement")}...
                                </>
                                : <span>{t("Supprimer")}</span>}
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Upgrade Budget */}
                <UpgradeBudget
                    user={user}
                    Id={selectedFlightId}
                    data={flightFormData}
                    oldBudget={oldBudget}
                    show={upgradeBudgetFlightModal}
                    handleClose={handleCloseUpgradeBudgetFlightModal}
                />
                

                { /* FILTER DRAWER */}
                <Offcanvas style={{ width: "250px", backgroundColor: '#232939' }} scroll={true} backdrop={false} show={showCanvas} onHide={handleCloseCanvas}>
                    <Offcanvas.Header style={{ color: 'white' }} closeButton>
                        <Offcanvas.Title style={{ fontSize: "20px" }} >{t("Filtres")}</Offcanvas.Title>
                        <style>
                            {`
.btn-close {
filter: invert(1);
}
`}
                        </style>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='py-3'>

                        <div className='d-flex' style={{ flexDirection: 'column', gap: 15 }}>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label style={{ color: 'white' }}>{t("Titre")}</Form.Label>
                                <Form.Control type="text" value={nameFilter} placeholder={t("Titre")} onChange={e => setNameFilter(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label style={{ color: 'white' }}>ID</Form.Label>
                                <Form.Control type="text" value={idFilter} placeholder="ID" onChange={e => setIDFilter(e.target.value)} />
                            </Form.Group>

                            <span style={{ color: 'white' }}>{t("Statut")} :</span>
                            <Form.Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} aria-label="Default select example">
                                <option value='' >{t("Tout")}</option>
                                <option value={true}>{t("Activée")}</option>
                                <option value={false}>{t("Désactivée")}</option>
                            </Form.Select>
                        </div>

                    </Offcanvas.Body>
                </Offcanvas>

            </div>
            :
            <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10
                }}>
                    <span>{t("Chargement")}</span>
                    <Spinner animation="grow" size='sm' />
                </div>
            </>
    )
}

export default Flight