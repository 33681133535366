import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { ButtonGroup, Col, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import axios from 'axios';
import axiosClient, { link } from '../../../../axios-client';
import { IconButton, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LuCode2 } from "react-icons/lu";


function Flight_Agancy() {

    const { id } = useParams();
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [deleteFlightModal, setDeleteFlightModal] = useState(false);

    const [selectedFlightId, setSelectedFlightId] = useState()

    const handleCloseDeleteFlightModal = () => setDeleteFlightModal(false);
    const handleShowDeleteFlightModal = () => setDeleteFlightModal(true);

    //Variables
    const [dataFlight, setDataFlight] = useState([])
    const [loading, setLoading] = useState()

    ////////////////////////////////the size of the table//////////////////////////////
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // Get Flights
    const fetchFlight = async () => {
        setLoading(true)
        try {
            const response = await axiosClient.get(`get-flights/${id}/?page=${page + 1}&page_size=${pageSize}`)
            setDataFlight(response?.data)
            setTotalPages(response?.data[0]?.totalPages);
            setLoading(false)
            console.log('RESPOOOONSE : ', response);
        } catch (error) {
            console.log('Error Fetching : ', error);
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchFlight()
    }, [page, pageSize])

    return (
        !loading ?
            <div>
                <div className="d-flex justify-content-between align-items-center mt-3 ">
                    <h3>{t("Liste des flight")}</h3>
                    {/* <div>
                    <Button variant="primary" size='md' style={{ fontSize: "16px" }} onClick={toggleShowCanvas} className="me-2">
                        <FaFilter />
                    </Button>

                </div> */}
                </div>

                {
                    dataFlight.length !== 0 ?
                        <Table className='mt-4' style={{ width: '100%', borderColor: 'lightgray' }} responsive>
                            <thead>
                                <tr>
                                    {/* <th scope="col">Active</th> */}
                                    <th scope="col">Flight</th>
                                    <th scope="col">ID</th>
                                    <th scope="col">Campaign</th>
                                    <th scope="col">{t("Fuseau horaire")}</th>
                                    <th scope="col">{t("Début")}</th>
                                    <th scope="col">{t("Fin")}</th>
                                    <th scope="col">{t("Prix")}</th>
                                    <th scope="col">{t("Status")}</th>
                                    <th scope="col">Impressions</th>
                                    <th scope="col">{t("Clics")}</th>
                                    <th scope="col">{t("Clics uniques")}</th>
                                    <th scope="col">Conversions</th>
                                    <th scope="col">{t("Revenue")}</th>
                                    {/* <th scope="col">CTR %</th> */}
                                    <th scope="col">Budget</th>
                                    {/* <th scope="col">{t("Priorité")}</th> */}
                                    <th scope="col">Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataFlight.map((val, key) => {
                                    return (
                                        <tr key={key}>

                                            <td>

                                                <p onClick={() => navigate(`/flight-ads/${val.Id}/`, {
                                                    state: {
                                                        idCampaign: id
                                                    }
                                                })} style={{ cursor: 'pointer', color: '#7a6fbe' }} className=" waves-effect" onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                                    <span>{val.Name}</span>
                                                </p>
                                                {/* <a className='d-flex flex-column' onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                        <span>Second campaign</span>
                                    </a> */}
                                            </td>
                                            <td>{val.Id}</td>
                                            <td>
                                                <Link to={`/advertiser-flights/${id}`} className=" waves-effect" onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                                    <span>{val.CampaignId}</span>
                                                </Link>
                                            </td>
                                            <td>{val.TimeZone}</td>
                                            <td>

                                                {val.StartDate ? new Date(val.StartDate).toLocaleTimeString(t("fr-FR"), {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                }) : null}
                                            </td>
                                            <td>
                                                {val.EndDate ? new Date(val.EndDate).toLocaleTimeString(t("fr-FR"), {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                }) : null}
                                            </td>
                                            <td>
                                                ${val.Price} Flat
                                            </td>
                                            <td>
                                                -
                                            </td>
                                            <td>{val.impressions}</td>
                                            <td>{val.clicks}</td>
                                            <td>{val.uniqueclicks ? val.uniqueclicks : '-'}</td>
                                            <td>{val.conversions}</td>
                                            <td>${val.revenue}</td>
                                            <td>
                                                ${val.LifetimeCapAmountDecimal}
                                            </td>
                                            {/* <td>
                                                {(parseInt(val.clicks) && parseInt(val.impressions) && parseInt(val.impressions) !== 0) ?
                                                    parseFloat((val.clicks / val.impressions) * 100).toFixed(2) + '%'
                                                    : '-'}
                                            </td> */}
                                            {/* <td>
                                                All Sites: House
                                            </td> */}
                                            {/* <td>
                                                <div className='d-flex justify-content-between'>
                                                    <button onClick={() => navigate(`/add-flight/${val.Id}/`, {
                                                        state: {
                                                            idCampaign: id
                                                        }
                                                    })} style={{
                                                        background: 'none',
                                                        border: 'none'
                                                    }}>
                                                        <span class="mdi mdi-pencil" style={{ fontSize: 25, cursor: "pointer", color: '#5b626b' }}></span>
                                                    </button>
                                                    <span class="mdi mdi-close" style={{ fontSize: 25, cursor: 'pointer' }} onClick={() => {
                                                        setSelectedFlightId(val.Id)
                                                        handleShowDeleteFlightModal()
                                                    }}></span>
                                                    <a>
                                                        <span class="mdi mdi-dots-vertical" style={{ fontSize: 25 }}></span>
                                                    </a>
                                                  
                                                </div>
                                            </td> */}
                                             <td>
                                                <div style={{ alignItems: "center", justifyContent: "center" }} className='d-flex '>



                                                    <IconButton
                                                        color="secondary"
                                                        aria-label="more"

                                                    onClick={(e) => {

                                                        navigate(`/generate-code/${val.Id}/`, {
                                                            state: {
                                                                Id:val.Id,
                                                                type:"groupe d'annonces",
                                                                setType: `setFlightId(${val.Id})`,
                                                                name: val.Name,
                                                                sizeAd: null
                                                            }
                                                        })
                                                    }}
                                                    >
                                                        <LuCode2 />
                                                    </IconButton>

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        :
                        <span>{t("Aucun élément trouvé.")}</span>
                }

                {/* PAGINATION */}
                {
                    dataFlight.length !== 0 &&
                    <Row>
                        <Col lg={12} className="d-flex justify-content-end">

                            <TablePagination
                                component="div"
                                count={totalPages * pageSize} // Total number of items
                                page={page}
                                onPageChange={handlePageChange}
                                rowsPerPage={pageSize}
                                onRowsPerPageChange={handlePageSizeChange}

                                rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                                showFirstButton={true}
                                showLastButton={true}
                                sx={{
                                    ".MuiTablePagination-displayedRows": {
                                        margin: 0
                                    },
                                    ".MuiTablePagination-selectLabel": {
                                        display: 'none',
                                        margin: 0
                                    },
                                    ".MuiSelect-select": {
                                        backgroundColor: "#ededed"
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                }
                {/* PAGINATION */}

             

            </div>
            :
            <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10
                }}>
                <span>{t("Chargement")}</span>
                    <Spinner animation="grow" size='sm' />
                </div>
            </>
    )
}

export default Flight_Agancy