import { createContext, useContext, useState, useEffect } from "react";
import axiosClient, { link } from "../axios-client";
import { Navigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
const StateContext = createContext({
  currentUser: null,
  token: null,
  notification: null,
  setUser: () => { },
  setToken: () => { },
  setNotification: () => { },
  setIsLoading: () => { },

});

export const ContextProvider = ({ children }) => {
  ///////////////////////Token Crypto///////////////////////
  const secretKeyEnv = process.env.REACT_APP_SECRET_KEY;

  const getEncryptionKey = () => {
    const deviceInfo = navigator.userAgent; 
    console.log("deviceInfo",deviceInfo);
    
    const secretKey = secretKeyEnv;
    console.log("secretKey",secretKeyEnv);
    return secretKey + deviceInfo;
  };
  const encryptToken = (token) => {
    const key = getEncryptionKey();
    return CryptoJS.AES.encrypt(token, key).toString();
  };
  // Utility function to encrypt the token
  // const encryptToken = (token) => {
  //   console.log("token normal",token);
  //   console.log("token crypted",CryptoJS.AES.encrypt(token, secretKey).toString());
    
  //   return CryptoJS.AES.encrypt(token, secretKey).toString();

  // };

  // Utility function to decrypt the token
  // const decryptToken = (encryptedToken) => {
  //   try {
  //     const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  //     return bytes.toString(CryptoJS.enc.Utf8);
  //   } catch (error) {
  //     console.error("Error decrypting token:", error);
  //     return null;
  //   }
  // };
  const decryptToken = (encryptedToken) => {
    try {
      const key = getEncryptionKey();
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Error decrypting token:", error);
      return null;
    }
  };
  const [user, setUser] = useState({});
  // const [token, _setToken] = useState(localStorage.getItem("U_T"));
  const [token, _setToken] = useState(() => {
    const encryptedToken = localStorage.getItem("U_T");
    return encryptedToken ? decryptToken(encryptedToken) : null; // Decrypt the token when loading
  });
  const [count, setCount] = useState(0)
  const [countAgancy, setCountAgancy] = useState(0)

  //const [notification, _setNotification] = useState('');
  const [isLoading, _setIsLoading] = useState(false);


  useEffect(() => {
    if (token) {
      axiosClient
        .get("auth/user/")
        .then(({ data }) => {
          setUser(data);
          // console.log(data);

        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  
  const setToken = (token) => {
    if (token) {
      const encryptedToken = encryptToken(token); // Encrypt the token
      localStorage.setItem("U_T", encryptedToken);
    } else {
      localStorage.removeItem("U_T");
    }
    _setToken(token);
  };

  function updateUser() {
    axiosClient
      .get("auth/user/")
      .then(({ data }) => {
        setUser(data);
        // console.log(data);

      })
      .catch((err) => {
        console.log(err);
      });
  }

  
  function updateCmdCountAdmin() {
    axiosClient.get(`count_cmd_admin/`)
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function updateCmdCountAgancy() {
    axiosClient
      .get("auth/user/")
      .then(({ data }) => {
        axiosClient.get(`count_cmd_agancy/${data.id}/`)
        .then((response) => {
          setCountAgancy(response.data.count);

        })
        .catch((error) => {
          console.log(error);
        });
      })
      .catch((err) => {
        console.log(err);
      });


  }

  const setIsLoading = (v) => {
    _setIsLoading(v);
  };





  return (
    <StateContext.Provider
      value={{

        user,
        setUser,
        token,
        setToken,
        setIsLoading,
        isLoading,
        updateUser,
        count,
        setCount,
        updateCmdCountAdmin,
        updateCmdCountAgancy,
        setCountAgancy,
        countAgancy
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);
