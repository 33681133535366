import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import { Col, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import axiosClient, { idTemplate, link } from '../../../../axios-client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Table } from 'reactstrap';
import DataCountries from '../../campaign/flights/DataCountries';
import DataTimeZone from '../../campaign/flights/DataTimeZone';
import { TablePagination, TextField } from '@mui/material';
import { useStateContext } from '../../../../context/ContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { LuCode2 } from "react-icons/lu";


const styles = `
.custom-modal-size {
  max-width: 650px; 
}
`


function Ads() {

    const { user } = useStateContext()
    const { id } = useParams();
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [loading, setLoading] = useState()
    const [loadingAds, setLoadingAds] = useState()

    // Variables
    const [flightFormData, setFlightFormData] = useState({
        CampaignId: location.state.idCampaign,
        // PriorityId:'',
        RateType: 2,
        StartDate: new Date().toISOString().slice(0, 16),
        EndDate: null,
        EndDateISO: null,
        IsTrackingConversions: false,
        GoalType: 2,
        Impressions: 100,
        CapType: null,
        Price: 10
    })

    const [dataPriorities, setDataPriorities] = useState([])

    const [errors, setErrors] = useState({})
    const refAttributs = useRef({
        NameRef: null,
        PriorityIdRef: null,
        ImpressionsRef: null,
        EndDateRef: null,
        DailyCapAmountRef: null,
        LifetimeCapAmountRef: null,
        SiteZoneTargetingRef: null,
        GeoTargetingRef: null,
    })

    const Id = id ? id : 0


    const [siteTargetingFormData, setSiteTargetingFormData] = useState({
        IsExclude: false,
    })
    const [dataSiteZoneTargeting, setDataSiteZoneTargeting] = useState([])

    const [geoTargetingFormData, setGeoTargetingFormData] = useState({
        IsExclude: false,
    })
    const [dataGeoTargeting, setDataGeoTargeting] = useState([])

    const [format, setFormat] = useState("Image")

    const [dataTemplates, setDataTemplates] = useState([])
    useEffect(() => {
        if (format === 'Video') {
            try {
                axiosClient.get(`get-templates/`).then(res => {
                    console.log('TEMPLATES : ', res.data);
                    setDataTemplates(res.data.items)
                })
            } catch (error) {
                console.log('ERROR : ', error);

            }
        }
    }, [format])


    const [creativeMapsFormData, setCreativeMapsFormData] = useState({
        IsActive: true,
        Body: '',
    })
    const [dataCreativeMaps, setDataCreativeMaps] = useState([])
    const [dataFiles, setDataFiles] = useState([])


    useEffect(() => {

        setDataFiles(dataCreativeMaps)
    }, [flightFormData, siteTargetingFormData, dataSiteZoneTargeting, creativeMapsFormData, dataCreativeMaps]); // This will log the updated state whenever it changes

    ////////////////////////////////the size of the table//////////////////////////////
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////
    // Fonction pour parser la chaîne
    function parseDataString(str) {
        const result = [];
        let currentOperator = '';
        let currentKeyword = '';

        // Parcours de chaque caractère de la chaîne
        for (let i = 0; i < str.length; i++) {
            const char = str[i];

            // Si le caractère est un opérateur (on considère que tout sauf une lettre est un opérateur)
            if (char === '!' || char === ',' || (char === '\n')) {
                // Si un mot-clé a été collecté avant, on le stocke
                if (currentKeyword) {
                    result.push({ operator: currentOperator, keyword: currentKeyword });
                    currentKeyword = ''; // Réinitialiser le mot-clé
                }

                // Gestion du caractère spécial '\n' (vérifier que c'est bien "\\n")
                if (char === "\n") {
                    currentOperator = '\n'; // Retour à la ligne dans l'opérateur
                    i++; // Sauter le 'n'
                } else {
                    currentOperator = char;
                }
            } else {
                // Ajout des caractères au mot-clé en cours
                currentKeyword += char;
            }
        }

        // Ajout du dernier mot-clé et opérateur
        if (currentKeyword) {
            result.push({ operator: currentOperator, keyword: currentKeyword });
        }

        return result;
    }

    //KEYWORDS
    const [dataKeywords, setDataKeywords] = useState([
        { operator: '', keyword: '' }
    ])

    useEffect(() => {
        console.log('dataKeywordsdataKeywords : ', dataKeywords);
        const result = dataKeywords.map((item, index) => {
            // Si c'est le premier élément et que l'opérateur est 'And', on ne met pas l'opérateur
            if (index === 0 && (item.operator === ',' || item.operator === '\n')) {
                return item.keyword;
            }
            // Sinon, on concatène l'opérateur et le mot-clé
            return item.operator + item.keyword;
        }).join(' ');
        console.log('dataKeywordsdataKeywords : RSL', result);

        // Mettre à jour les données du formulaire
        setFlightFormData({
            ...flightFormData,
            'Keywords': result
        });

        console.log(result);


    }, [dataKeywords])


    const [myDataSection, setMyDataSection] = useState([
        {
            operatorGlobal: '',
            section: [
                { logicOperator: '', label: '', operator: '', value: '' }
            ]
        }
    ])



    const formatData = (data) => {
        return data.map((sectionData, index) => {
            // Concaténer les sous-sections avec leurs opérateurs logiques
            const sectionString = sectionData.section
                .filter(subSection => subSection.value !== null && subSection.value !== '') // Vérifier si la valeur est présente
                .map((subSection, subIndex) => {
                    const value = isNaN(subSection.value) ? `"${subSection.value}"` : subSection.value; // Mettre des guillemets pour les chaînes
                    return `${subSection.logicOperator ? subSection.logicOperator + ' ' : ''}${subSection.label} ${subSection.operator} ${value}`;
                }).join(' ');

            // Si la section est vide après le filtre, ne rien retourner
            if (!sectionString) return '';

            // Si c'est le premier élément, ne pas ajouter d'opérateur global au début
            if (index === 0) {
                return `(${sectionString})`;
            } else {
                return `${sectionData.operatorGlobal ? sectionData.operatorGlobal + ' ' : ''}(${sectionString})`;
            }
        }).filter(section => section !== '').join(' '); // Filtrer les sections vides
    };


    const [formattedStringCustomTargeting, setFormattedStringCustomTargeting] = useState()
    useEffect(() => {
        console.log('myDataSectionmyDataSection : ', myDataSection);

        const formattedString = formatData(myDataSection);
        setFormattedStringCustomTargeting(formattedString)
        setFlightFormData({
            ...flightFormData,
            'CustomTargeting': formattedString
        })
        console.log('myDataSectionmyDataSection : RSLT', formattedString);

    }, [myDataSection])

    const parseStringToData = (input) => {
        // Nettoyer la chaîne pour éviter les erreurs de parsing
        const cleanInput = input.replace(/\s+/g, ' ').trim();

        // Regex pour extraire les sections et les opérateurs globaux
        const sectionRegex = /\(([^)]+)\)/g;
        const globalOperatorRegex = /\)\s*(\w+)\s*\(/g;

        let match;
        const result = [];
        let lastIndex = 0;

        // Extraire toutes les sections
        while ((match = sectionRegex.exec(cleanInput)) !== null) {
            const sectionString = match[1].trim();
            const sectionData = [];

            // Diviser les sous-sections
            const subSectionRegex = /(\w+)\s*(=|!=|>|<|>=|<=|contains|like)\s*(?:\"([^\"]*)\"|([^\s()]+))/g;
            let subMatch;
            while ((subMatch = subSectionRegex.exec(sectionString)) !== null) {
                const [, label, operator, stringValue, numberValue] = subMatch;
                sectionData.push({
                    logicOperator: subMatch.index > 0 && subSectionRegex.lastIndex > 0 ? 'and' : '',
                    label,
                    operator,
                    value: stringValue !== undefined ? stringValue : numberValue
                });
            }

            // Extraire l'opérateur global qui suit la section actuelle
            const globalOperatorMatch = globalOperatorRegex.exec(cleanInput.substring(sectionRegex.lastIndex));
            const globalOperator = globalOperatorMatch ? globalOperatorMatch[1] : '';

            result.push({
                operatorGlobal: globalOperator,
                section: sectionData
            });

            lastIndex = sectionRegex.lastIndex;
        }

        // Assurer que le premier élément n'a pas d'opérateur global
        if (result.length > 0) {
            result[0].operatorGlobal = '';
        }

        // Corriger les opérateurs globaux pour les sections suivantes
        for (let i = 1; i < result.length; i++) {
            const prevSectionEnd = sectionRegex.lastIndex - result[i].section.join(' ').length;
            const nextOperatorMatch = globalOperatorRegex.exec(cleanInput.substring(prevSectionEnd));
            result[i].operatorGlobal = nextOperatorMatch ? nextOperatorMatch[1] : '';
        }

        return result;
    };


    const getDataFlight = async () => {
        setLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axiosClient.get(`get-flight-with-id/${Id}/`).then(res => {
                    setFlightFormData(res.data)
                    console.log('res.data.keywordsres.data.keywords : ', res.data.Keywords);

                    if (res.data.Keywords.length !== 0) {
                        console.log('res.data.Keywordsres.data.Keywords : ', res.data.Keywords);

                        const rsl = parseDataString(res.data.Keywords);
                        setDataKeywords(rsl)
                    } else {
                        setDataKeywords([
                            { operator: '', keyword: '' }
                        ])
                    }

                    if (res.data.CustomTargeting.length !== 0) {
                        console.log('res.data.CustomTargetingres.data.CustomTargeting : ', res.data.CustomTargeting);

                        const parsedData = parseStringToData(res.data.CustomTargeting);
                        console.log('res.data.CustomTargetingres.data.CustomTargeting : RSL', parsedData);
                        setMyDataSection(parsedData)
                    } else {
                        setMyDataSection([
                            {
                                operatorGlobal: '',
                                section: [
                                    { logicOperator: '', label: '', operator: '', value: '' }
                                ]
                            }
                        ])
                    }

                })
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const getDataAds = async () => {
        setLoadingAds(true)
        try {
            await axiosClient.get(`get-Ads/${Id}/?page=${page + 1}&page_size=${pageSize}`).then(res => {
                console.log('get-Ads : ', res.data);
                setDataCreativeMaps(res?.data || [])
                // setTotalPages(Math.ceil(res.data.count / pageSize));
                setTotalPages(res?.data[0]?.totalPages);
                setLoadingAds(false)
            })
        } catch (error) {
            console.log('ERROR : ', error);
            setLoadingAds(false)
        }
    }

    const getDataSiteZoneFlight = async () => {
        // setLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axiosClient.get(`get-site-flight/${Id}/`).then(res => {
                    setDataSiteZoneTargeting(res.data || [])
                })
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const getDataGeoTargetingFlight = async () => {
        // setLoading(true)
        if (parseInt(Id) !== 0) {
            try {
                await axiosClient.get(`get-geoTargeting-flight/${Id}/`).then(res => {
                    setDataGeoTargeting(res.data || [])
                })
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const getPriorities = async () => {
        try {
            await axiosClient.get(`get-priorities/`).then(res => {
                console.log('DATA PRIORITIES : ', res.data.items);
                setDataPriorities(res.data.items)
            })
        } catch (error) {
            setLoading(false)

        }
    }
    useEffect(() => {
        if (parseInt(Id) !== 0) {
            getDataFlight()
            getDataAds()
            getDataSiteZoneFlight()
            getDataGeoTargetingFlight()
        }
        getPriorities()
        setLoading(false)
    }, [page, pageSize])



    const getDataSites = async () => {
        try {
            const res = await axiosClient.get(`get-sites/`);
            console.log('DATATATATA :', res.data.items);
            return res.data.items; // Return the data directly
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    };

    const getDataZones = async (id) => {
        try {
            const res = await axiosClient.get(`get-zones/`);
            console.log('DATATATATA :', res.data.items);
            return res.data; // Return the data directly

        } catch (error) {
            console.log('ERROR :', error);
            throw error;

        }
    }

    const [dataSites, setDataSites] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDataSites();
                setDataSites(data)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures this runs once when the component mounts


    const [dataZones, setDataZones] = useState([])
    const [idSiteSelected, setIdSiteSelected] = useState()
    useEffect(() => {
        const fetchDataZones = async () => {
            try {
                const data = await getDataZones(idSiteSelected)
                setDataZones(data)
            } catch (error) {
                console.log('Error fetching zones data :', error);
            }
        }
        fetchDataZones()
    }, [])


    const getDataRegions = async (country) => {
        try {
            const res = await axiosClient.get(`get-regions/${country}/`);
            console.log('DATATATATA Regions :', res.data.Regions);
            console.log('DATATATATA AllMetros :', res.data.AllMetros);
            return {
                regions: res.data.Regions,
                metros: res.data.AllMetros
            };
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    };

    const [dataRegions, setDataRegions] = useState([])
    const [dataMetros, setDataMetros] = useState([])
    const [loadingDataRegions, setLoadingDataRegion] = useState(false)
    useEffect(() => {
        if (geoTargetingFormData?.CountryCode) {
            setLoadingDataRegion(true)
            const fetchData = async () => {
                try {
                    const { regions, metros } = await getDataRegions(geoTargetingFormData?.CountryCode)
                    console.log('DATAAAAAAAAAAAAA REGION : ', regions);
                    console.log('DATAAAAAAAAAAAAA Metros : ', metros);

                    setDataRegions(regions || [])
                    setDataMetros(metros || [])
                    setLoadingDataRegion(false)
                } catch (error) {
                    console.log('Error fetching data:', error);
                    setLoadingDataRegion(false)
                }
            };

            fetchData();
        }
    }, [geoTargetingFormData?.CountryCode]);


    // AD Management
    const getDataAdTypes = async (format) => {
        try {
            const res = await axiosClient.get(`get-adTypes/`);
            console.log('DATATATATA :', res.data.items);
            if (format === 'Image') {
                const data = res.data.items.filter(e => !e.Name.includes('video'))
                console.log('DATATATATA_DATATATATA : ', data);
                return data

            } else {
                const data = res.data.items.filter(e => e.Name.includes('video'))
                console.log('DATATATATA_DATATATATA : ', data);
                return data
            }
            // return res.data.items; // Return the data directly
        } catch (error) {
            console.log('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    }


    const [dataAdTypes, setDataAdTypes] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDataAdTypes(format);
                console.log('DATA AD TYPEs : ', data);

                setDataAdTypes(data)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, [format]); // Empty dependency array ensures this runs once when the component mounts

    return (
        <>
            {!loading ?
                <div style={{ position: 'relative' }}>

                    <ToastContainer />

                    <Accordion
                        defaultActiveKey={[
                            '0',
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                        ]}
                        alwaysOpen>
                        <Accordion.Item  eventKey="0">
                            <Accordion.Header>{t("Paramètres généraux")}</Accordion.Header>
                            <Accordion.Body>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>{t("Titre")}</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        placeholder={t("Titre")}
                                        value={flightFormData?.Name}
                                    />
                                </Form.Group>
                                <div className='d-flex flex-column flex-md-row gap-2 mb-3'>
                                    <Form.Group className="w-100" controlId="formBasicTimeZone">
                                        <Form.Label>{t("Fuseau horaire")}</Form.Label>

                                        <Form.Select disabled value={flightFormData?.TimeZone}>
                                            {DataTimeZone.map((val, key) => {
                                                return (
                                                    <option key={key} value={val.value} title={val.label}>{val.label} | {val.value}</option>
                                                )
                                            })}
                                        </Form.Select>

                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicStartDate">
                                        <Form.Label>{t("Date de début")}</Form.Label>
                                        <Form.Control disabled type="datetime-local" value={flightFormData.StartDate} />
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicEndDate">
                                        <Form.Label>{t("End date")}</Form.Label>
                                        <Form.Control disabled ref={(e) => refAttributs.current.EndDateRef = e} type="datetime-local" value={flightFormData?.EndDate} />
                                        {errors?.EndDate && <Form.Text className="text-danger">{errors?.EndDate}</Form.Text>}
                                    </Form.Group>
                                </div>
                                <div className='d-flex flex-column flex-md-row gap-2 mb-3'>
                                    <Form.Group className="w-100" controlId="formBasicRate">
                                        <Form.Label>{t("Taux")}</Form.Label>

                                        <Form.Select disabled value={flightFormData?.RateType} >
                                            <option value={1}>
                                                <span>{t("Plat")}</span>
                                            </option>
                                            <option value={2}>
                                                <span>CPM</span>
                                            </option>
                                            <option value={3}>
                                                <span>CPC</span>
                                            </option>
                                            <option value={4}>
                                                <span>CPA ({t("Afficher")})</span>
                                            </option>
                                            <option value={5}>
                                                <span>CPA ({t("Cliquer")})</span>
                                            </option>
                                            <option value={6}>
                                                <span>CPA ({t("Afficher")} & {t("Cliquer")})</span>
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="w-100" controlId="formBasicPrice">
                                        <Form.Label>{t("Prix")}</Form.Label>
                                        <Form.Control disabled type="number" min={10} placeholder={t("Prix")} value={flightFormData?.Price} />
                                    </Form.Group>

                                </div>


                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item className='mt-4' eventKey="2">
                            <Accordion.Header>{t("Support")}</Accordion.Header>
                            <Accordion.Body style={{ padding: 0 }}>

                                <Form.Group className="px-4 my-3" controlId="formBasicKeywords">

                                    <Form.Select
                                        disabled
                                        ref={(e) => refAttributs.current.SiteZoneTargetingRef = e}
                                        value={flightFormData?.SiteZoneTargeting?.[0]?.ZoneId}

                                    >
                                        <option>----</option>
                                        {dataZones
                                            .filter((zone) => zone.SiteId === dataSites.find((site) => site.Title === 'Web')?.Id)
                                            .map((zone, key) => (
                                                <option key={key} value={zone.Id} title={zone.Name}>
                                                    {zone.Name}
                                                </option>
                                            ))}
                                    </Form.Select>

                                    {errors?.SiteZoneTargeting && <Form.Text className="text-danger">{errors?.SiteZoneTargeting}</Form.Text>}
                                </Form.Group>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item className='mt-4' eventKey="1">
                            <Accordion.Header>{t("Annonces")}</Accordion.Header>
                            {!loadingAds ?
                                <Accordion.Body className='p-0'>
                                    <div className='px-4 py-2' style={{ maxHeight: 400, overflow: 'auto' }}>
                                        {dataCreativeMaps.length !== 0 ?
                                            <>

                                                {/* PAGINATION */}
                                                <Row >
                                                    <Col lg={12} className="d-flex justify-content-start p-0 m-0">

                                                        <TablePagination
                                                            component="div"
                                                            count={totalPages * pageSize} // Total number of items
                                                            page={page}
                                                            onPageChange={handlePageChange}
                                                            rowsPerPage={pageSize}
                                                            onRowsPerPageChange={handlePageSizeChange}

                                                            rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                                                            showFirstButton={true}
                                                            showLastButton={true}
                                                            sx={{
                                                                ".MuiTablePagination-displayedRows": {
                                                                    margin: 0
                                                                },
                                                                ".MuiTablePagination-selectLabel": {
                                                                    display: 'none',
                                                                    margin: 0
                                                                },
                                                                ".MuiSelect-select": {
                                                                    backgroundColor: "#ededed"
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>

                                                {/* PAGINATION */}
                                                <Table className='mt-2' style={{ width: '100%', borderColor: 'lightgray' }} responsive>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">{t("Actif")}</th>
                                                            <th scope="col">{t("Tittre")}</th>
                                                            <th scope="col">{t("ID d'annonce")}</th>
                                                            {/* <th scope="col">Status</th> */}
                                                            <th scope="col">Impressions</th>
                                                            <th scope="col">{t("Clics")}</th>
                                                            <th scope="col">{t("Clics uniques")}</th>
                                                            <th scope="col">Conversions</th>
                                                            <th scope="col">{t("Revenue")}</th>
                                                            <th scope="col">CTR %</th>
                                                            {/* <th scope="col">GMV</th> */}
                                                            {/* <th scope="col">Roas</th> */}
                                                            <th scope="col">{t("Dernière mise à jour")}</th>
                                                            <th scope="col">Options</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataCreativeMaps.map((val, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <img src={val.ImageLink} style={{
                                                                            height: 'auto',
                                                                            maxWidth: 40,
                                                                            width: 'auto !important',
                                                                        }} />
                                                                    </td>
                                                                    <td className='d-flex justify-content-center align-self-center'>
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ cursor: 'pointer' }}
                                                                                checked={val.IsActive}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span>{val.Creative?.Title}</span>

                                                                        {/* <a className='d-flex flex-column' onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                    <span>Second campaign</span>
                                </a> */}
                                                                    </td>
                                                                    <td>{val.Id}</td>
                                                                    {/* <td>
                                                                        status
                                                                    </td> */}
                                                                    <td>{val.impressions}</td>
                                                                    <td>{val.clicks}</td>
                                                                    <td>{val.uniqueclicks ? val.uniqueclicks : '-'}</td>
                                                                    <td>{val.conversions}</td>
                                                                    <td>${val.revenue}</td>
                                                                    <td>
                                                                        {(parseInt(val.clicks) && parseInt(val.impressions) && parseInt(val.impressions) !== 0) ?
                                                                            parseFloat((val.clicks / val.impressions) * 100).toFixed(2) + '%'
                                                                            : '-'}
                                                                    </td>
                                                                    {/* :                                                                    <td>
                                                                        {(parseInt(val.gmv) && parseInt(val.revenue) && parseInt(val.revenue) !== 0) ?
                                                                            parseFloat((val.gmv / val.revenue) * 100).toFixed(2) + '%'
                                                                            : '-'}
                                                                    </td> */}
                                                                    <td>
                                                                        {new Date().toUTCString(val.LastModified)}
                                                                    </td>
                                                                    <td>
                                                                        <div style={{ alignItems: "center", justifyContent: "center" }} className='d-flex '>



                                                                            <IconButton
                                                                                color="secondary"
                                                                                aria-label="more"
                                                                                id="long-button"
                                                                                aria-controls={open ? 'long-menu' : undefined}
                                                                                aria-expanded={open ? 'true' : undefined}
                                                                                aria-haspopup="true"
                                                                                onClick={(e) => {

                                                                                    navigate(`/generate-code/${val.Id}/`, {
                                                                                        state: {
                                                                                            Id: val.Id,
                                                                                            type: 'annonce',
                                                                                            setType: `setFlightCreativeId(${val.Id})`,
                                                                                            name: val.Creative?.Title,
                                                                                            sizeAd: val.Creative?.AdTypeId
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <LuCode2 />
                                                                            </IconButton>

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </>
                                            :
                                            <span>{t("Aucun élément trouvé.")}</span>
                                        }
                                    </div>

                                </Accordion.Body>
                                :
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 10,
                                    padding: 10
                                }}>
                                    <span>{t("Chargement")}</span>
                                    <Spinner animation="grow" size='sm' />
                                </div>}
                        </Accordion.Item>

                        {flightFormData?.SiteZoneTargeting?.[0]?.Name === 'Radio Karaoke' &&
                            <Accordion.Item className='mt-4' eventKey="3">
                                <Accordion.Header>{t("Ciblage par mots-clés")}</Accordion.Header>
                                <Accordion.Body style={{ padding: 0 }}>
                                    <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                                        <strong>{t("Le ciblage par mots clés est un filtre que vous pouvez appliquer à un groupe d'annonces ou à une annonce afin que les annonces du groupe d'annonces soient diffusées uniquement aux emplacements où ces mots clés sont transmis dans le code d'annonce ou la demande d'API de décision.")}</strong><br />
                                        <span>{t("Si aucun mot clé n'est défini, ce groupe d'annonces sera éligible à la diffusion quels que soient les mots clés transmis avec la demande.")}</span>
                                    </Alert>

                                    {dataKeywords.map((val, key) => {
                                        return (
                                            <div key={key} className='d-flex col-md-12'>
                                                <Form.Group className="px-3 mb-3 col-md-6" controlId="formBasicKeywords">
                                                    <Form.Label>{t("Opérateur")}</Form.Label>
                                                    <Form.Select
                                                        disabled
                                                        name="operator"
                                                        value={val.operator}
                                                    >
                                                        <option value=''>{t("Sélectionner l'opérateur")}</option>
                                                        <option value={'!'}>Exclude</option>
                                                        <option value={','}>And</option>
                                                        {key !== 0 && <option value={'\n'}>{t("Ou")}</option>}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group className="px-3 mb-3 col-md-6" controlId="formBasicKeywords">
                                                    <Form.Label>{t("Mot-clé")}</Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        name="keyword"
                                                        value={val.keyword}
                                                    />
                                                </Form.Group>


                                            </div>
                                        )
                                    })}

                                </Accordion.Body>
                            </Accordion.Item>
                        }

                        <Accordion.Item className='mt-4' eventKey="4">
                            <Accordion.Header>{t("Ciblage personnalisé")}</Accordion.Header>
                            <Accordion.Body style={{ padding: 0 }}>
                                <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                                    <strong>{t("Le ciblage personnalisé vous permet de configurer des règles pour cibler certaines combinaisons de paires clé/valeur.")}</strong>
                                </Alert>


                                {formattedStringCustomTargeting ?
                                    <h6 className='mx-3 py-2'>
                                        {formattedStringCustomTargeting}
                                    </h6>
                                    // <Form.Control
                                    //     as="textarea"
                                    //     style={{ height: '100px', resize: 'none', paddingTop: 15 }}
                                    //     value={formattedStringCustomTargeting}
                                    //     disabled
                                    // />
                                    :
                                    <h6 style={{ color: "gray" }} className='mx-3 py-2'>
                                        Nothing
                                    </h6>
                                }

                            </Accordion.Body>
                        </Accordion.Item>

                        {flightFormData?.SiteZoneTargeting?.[0]?.Name === 'DAB+' &&
                            <Accordion.Item className='mt-4' eventKey="6">
                                <Accordion.Header>{t("Ciblage géographique")}</Accordion.Header>
                                <Accordion.Body style={{ padding: 0 }}>
                                    <Alert key='primary' variant='primary' style={{ borderRadius: 0, border: 'none' }}>
                                        <strong>{t("Ciblez les utilisateurs en fonction de leur emplacement actuel.")}</strong><br />
                                    </Alert>


                                    {dataGeoTargeting?.length !== 0 && <Table className='container' responsive>
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>{t("Pays")}</th>
                                                <th>{t("Région")}</th>
                                                <th>{t("Métro")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataGeoTargeting?.map((val, key) => {

                                                return (
                                                    <tr key={key}>
                                                        <td>{val.IsExclude ? t("Exclure") : t("Inclure")}</td>
                                                        <td>{val.CountryName ? `${val.CountryCode}-${val.CountryName}` : `${val.CountryCode}-${DataCountries.find(e => e.value === val.CountryCode)?.title}`}</td>
                                                        <td>{val.RegionName ? `${val.Region}` : val.Region ? `${val.Region}` : '-'}</td>
                                                        <td>{val.MetroCode ? `${val.MetroCode}` : val.MetroCode ? `${val.MetroCode}` : '-'}</td>

                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>}

                                </Accordion.Body>
                            </Accordion.Item>
                        }

                    </Accordion>


                </div>
                :
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10
                }}>
                    <span>{t("Chargement")}</span>
                    <Spinner animation="grow" size='sm' />
                </div>

            }
        </>
    )
}

export default Ads