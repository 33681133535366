import { TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../../axios-client';

function UpgradeBudget({ user, Id, data, oldBudget, show, handleClose }) { // Ajout des props show et handleClose
    const { t } = useTranslation()

    const stripe = useStripe()
    const elements = useElements()

    const [newBudget, setNewBudget] = useState()

    // Mise à jour du budget
    const [upgradeBudgetLoading, setUpgradeBudgetLoading] = useState(false)
    const [formDataUpdateBudget, setFormDataUpdateBudget] = useState(data)
    const [errors, setErrors] = useState({})
    const refAttributs = useRef({
        newBudgetRef: null,
    })
    const updateLifetimeCapAmount = async () => {

        let newErrors = { ...errors };
        let firstErrorRef = null;

        if (!newBudget || newBudget === '') {
            newErrors.newBudget = t("Ce champ est obligatoire *")
            console.log('USE REF amountRef ! ', refAttributs.current.newBudgetRef);
            if (!firstErrorRef) firstErrorRef = refAttributs.current.newBudgetRef;
        } else {
            const { newBudget, ...updatedErrors } = newErrors;
            newErrors = updatedErrors;
        }

        setErrors(newErrors);
        if (firstErrorRef) {
            // firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        if (!firstErrorRef) {
            setUpgradeBudgetLoading(true)
            try {
                if (!stripe || !elements || !newBudget) {
                    setUpgradeBudgetLoading(false)
                    return;
                }

                // Soumettre PaymentElement
                const { error: submitError } = await elements.submit();
                if (submitError) {
                    console.log('[submitError]', submitError);
                    return;
                }

                const response = await axiosClient.put(`edit-budget-flight/${Id}/`, formDataUpdateBudget)
                    .catch(error => {
                        console.log('ERROR: ', error);
                        setUpgradeBudgetLoading(false)
                    });

                if (response.data) {
                    try {
                        const { data } = await axiosClient.post('/create-payment-intent', {
                            user: user?.id,
                            flight: response?.data?.CampaignId,
                            amount: newBudget,
                            currency: 'usd',
                        });

                        const { clientSecret } = data;

                        // Confirmer le paiement
                        const { error } = await stripe.confirmPayment({
                            elements,
                            clientSecret,
                            confirmParams: {
                                return_url: window.location.href,
                            },
                        });

                        handleClose(); // Fermer le modal après succès
                        setFormDataUpdateBudget({});

                        if (error) {
                            console.log('[error]', error);
                        } else {
                            console.log('Payment successful');
                        }
                    } catch (error) {
                        setUpgradeBudgetLoading(false)
                        console.error('Error fetching client secret:', error);
                    }
                }
            } catch (error) {
                console.log('ERROR: ', error);
                setUpgradeBudgetLoading(false)
            }
        }

    };

    useEffect(() => {
        if (!show) {
            setNewBudget('');
        }
    }, [show]);

    return (
        <Modal
            dialogClassName='custom-modal-size'
            show={show} // Utilisation de la prop show
            onHide={handleClose} // Utilisation de la prop handleClose
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("Augmenter le budget")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className=' py-3'>
                    <form>
                        <PaymentElement />
                        <div className='col-12 mt-3'>
                            <TextField
                                inputRef={(e) => refAttributs.current.newBudgetRef = e}
                                label={`Budget (USD)`}
                                variant="filled"
                                value={newBudget}
                                onChange={e => {
                                    setNewBudget(parseInt(e.target.value) || '')
                                    setFormDataUpdateBudget({
                                        "IsActive": data?.IsActive,
                                        'LifetimeCapAmount': e.target.value ? parseFloat(oldBudget) + parseFloat(e.target.value) : parseFloat(oldBudget),
                                        "Name": data?.Name,
                                        "CapType": 4,
                                        "RateType": data?.RateType,
                                        "GoalType": data?.GoalType,
                                        "Impressions": data?.Impressions,
                                        "CampaignId": data?.CampaignId,
                                        "PriorityId": data?.PriorityId,
                                        "StartDateISO": data?.StartDateISO,
                                        "Price": data?.Price,
                                        "Keywords": data?.Keywords,
                                        "CustomTargeting": data?.CustomTargeting,
                                    })
                                }}
                                style={{ width: '100%', backgroundColor: 'transparent', border: '1px solid lightgray', borderRadius: 4, borderBottom: 0 }}
                            />
                            {errors?.newBudget && (
                                <Form.Text className="text-danger">{errors.newBudget}</Form.Text>
                            )}
                        </div>

                    </form>
                </div>
                <div className='mt-3 d-flex gap-2' style={{ height: 50, display: 'flex', alignItems: 'center' }}>
                    <Button onClick={updateLifetimeCapAmount} disabled={upgradeBudgetLoading}>
                        {upgradeBudgetLoading ?
                            <>
                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                {t("Chargement")}...
                            </>
                            : <span>{t("Enregistrer")}</span>}
                    </Button>
                    <Button variant="outline-warning" onClick={handleClose} disabled={upgradeBudgetLoading}>
                        {t("Annuler")}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UpgradeBudget;
